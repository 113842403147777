import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ISupplier, ISuppliersResponse } from '@app/core/interface/suppliers.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { CreateSupplierComponent } from '../../supplier/create-supplier/create-supplier.component';
import { IProduct } from '@app/core/interface/products.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';

@Component({
  selector: 'app-product-suppliers',
  templateUrl: './product-suppliers.component.html',
  styleUrls: ['./product-suppliers.component.scss'],
  providers: [SuppliersFacade],
})
export class ProductSuppliersComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() refresh = new EventEmitter<boolean>();
  @Output() toggleAdd = new EventEmitter<boolean>();
  @Output() existingSupplierList = new EventEmitter<ISupplier[]>();

  @Input() product!: IProduct;
  @Input() isAddSupplier!: boolean;
  @Input() allSuppliers: Array<ISupplier> = [];
  selectedSupplier!: string;
  dataSource: MatTableDataSource<ISupplier> = new MatTableDataSource<ISupplier>();
  displayedColumns: string[] = ['name', 'description', 'actions'];
  supplierList: Array<ISupplier> = [];
  suppliersIDs: Array<string> = [];
  constructor(
    private _productsFacade: ProductsFacade,
    private _snackbarService: SnackbarService,
    private _facade: SuppliersFacade,
    public dialog: MatDialog
  ) {}
  /**
   * Angular Life Cycle Hooks
   */
  ngOnInit(): void {
    this.dataSource.data = this.product?.suppliers.reverse() ?? [];

    this.product?.suppliers?.forEach(supplier => {
      this.suppliersIDs = [];
      this.suppliersIDs.push(supplier.uuid);
    });
    // this.getSuppliers();
  }
  /**
   * in OnChanges lifecycle
   * @param changes if changes from parrent are from product we are setting product suppliers again
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['product']) {
      this.dataSource.data = this.product?.suppliers.reverse() ?? [];
      this.dataSource.sort = this.sort;
      this.setSuppliersDropdown();
    }
    if (changes['allSuppliers']) {
      this.setSuppliersDropdown();
    }
  }
  /**
   * in OnChanges lifecycle
   * @param changes if changes from parrent are from product we are setting product suppliers again
   */
  ngAfterViewInit() {
    this.dataSource.data = this.product?.suppliers.reverse() ?? [];
    this.dataSource.sort = this.sort;
    this.paginator.pageSize = 6;
    this.dataSource.paginator = this.paginator;
  }
  /**
   * Sends API to get all suppliers
   */
  getSuppliers() {
    this._facade.getSupplierList$().subscribe({
      next: this._getSupplierListSuccess.bind(this),
      error: this._error.bind(this),
    });
  }
  /**
   * Popup for removing supplier from product
   */
  removeProductSupplier(uuid: string, name: string) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to remove ${name} from ${this.product.name} ?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this._productsFacade.removeSupplier$(this.product.uuid, uuid).subscribe({
          next: this._refreshData.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }
  /**
   * Cancels add mode with emmiting false value
   */
  cancel() {
    this.toggleAdd.emit(false);
  }
  /**
   * Sends patch api to add supplier to product
   */
  save() {
    this._productsFacade.addSupplierToProduct$(this.product.uuid, this.selectedSupplier).subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
  }
  /**
   * Sets all suppliers and then invoke method to set dropdown suppliers
   * @param data all supplier data from backend
   */
  private _getSupplierListSuccess(data: ISuppliersResponse): void {
    if (data.results) this.allSuppliers = data.results;
    this.setSuppliersDropdown();
  }
  /**
   * Sets dropdown suppliers options , from all suppliers we are setting suppliers that are not connected to the product so user can add them as well
   */
  setSuppliersDropdown() {
    this.suppliersIDs = [];
    this.product?.suppliers.forEach(supplier => {
      this.suppliersIDs.push(supplier.uuid);
    });
    const suppList: Array<ISupplier> = [];
    this.allSuppliers.forEach(supplier => {
      !this.suppliersIDs.includes(supplier.uuid) && suppList.push(supplier);
    });
    this.supplierList = suppList;
    this.existingSupplierList.emit(this.supplierList);
  }
  /**
   * On create supplier we are oppening CreateSupplier Dialog
   */
  createSupplierDialog(): void {
    const dialogRef = this.dialog.open(CreateSupplierComponent, {});
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getSuppliers();
      }
    });
  }
  /**
   * Refreshes data in parent component
   */
  private _success() {
    this.refresh.emit(true);
    this.toggleAdd.emit(false);
    this.selectedSupplier = '';
  }
  /**
   * Refreshes data in parent component
   */
  private _refreshData() {
    this.refresh.emit(true);
    this.selectedSupplier = '';
  }
  /**
   *
   * @param error hadles HTTP error desplaying message from backend
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
