import { Component, OnInit, ViewChild } from '@angular/core';
import { IProduct, IProductsListResponse } from '@app/core/interface/products.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-manage-products',
  templateUrl: './manage-products.component.html',
  styleUrls: ['./manage-products.component.scss'],
  providers: [ProductsFacade],
})
export class ManageProductsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  displayedColumns: string[] = ['name', 'description', 'chains', 'actions'];
  cardDataSource = new MatTableDataSource<IProduct>();
  tableDataSource = new MatTableDataSource<IProduct>();
  productsList: IProduct[] = [];
  show_info = true;
  is_archived = true;
  constructor(
    private _productsFacade: ProductsFacade,
    private _dialog: MatDialog,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getProductList();
  }

  ngAfterViewInit() {
    this.cardDataSource.paginator = this.paginator;
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;
  }

  getProductList(): void {
    this._productsFacade.getProducts$().subscribe({
      next: this._getProductsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _getProductsSuccess(data: IProductsListResponse): void {
    if (data && data.results.length) {
      this.productsList = data.results;
      this.cardDataSource.data = data.results;
      this.tableDataSource.data = data.results;
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  deleteProduct() {
    this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `You are about to delete this product. Note that deleting will remove the product & all related elements & data completely, and you won’t be able to restore it.`,
        confirmationText: 'Do you wish to continue?',
        btnText: 'Deactivate Product',
        type: 'warning',
        text: 'warning-text',
      },
    });
    // dialogRef.afterClosed().subscribe((result: boolean) => {
    //   // if (result) {
    //   // }
    // });
  }
  archiveProduct() {
    this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `You are about to archive the selected product. Note that after archiving the product all the related projects, certificates & labels will be archived too. 
        The product could be restored from the list.`,
        confirmationText: 'Do you wish to continue?',
        btnText: 'Archive Product',
        type: 'info-actions',
        text: 'info-text',
        reactivate: true,
      },
    });
    // dialogRef.afterClosed().subscribe((result: boolean) => {
    //   // if (result) {
    //   // }
    // });
  }

  restoreProduct() {
    this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `You are about to restore this archived product. Note that after restoring the product all the related projects, certificates & labels will be restored too.`,
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Restore',
        type: 'info-actions',
        text: 'info-text',
        reactivate: true,
      },
    });
    // dialogRef.afterClosed().subscribe((result: boolean) => {
    //   // if (result) {
    //   // }
    // });
  }
}
