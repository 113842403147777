import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICertification, ICertificationObjectSelected } from '@app/core/interface/certificates.interface';
import { NotificationType, Storage, USER } from '@app/core/constants';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { DataStorageService } from '@service/data-localstorage.service';
import { IBrandListResult } from '@app/core/interface/brands.interface';
import { IUser } from '@interface/login.interface';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-certification-brands-list',
  templateUrl: './certification-brands-list.component.html',
  styleUrls: ['./certification-brands-list.component.scss'],
})
export class CertificationBrandsListComponent implements OnInit {
  @Input() showTabSelector = false;
  @Input() data!: ICertification;
  @Output() objectSelected = new EventEmitter<ICertificationObjectSelected>();
  brandsList: IBrandListResult[] = [];
  brandsDataSource = new MatTableDataSource<IBrandListResult>();
  brandsObservable$!: Observable<IBrandListResult[]>;
  selectedObject: ICertificationObjectSelected | null = null;
  storedUser: IUser;

  constructor(
    private _brandsFacade: BrandsFacade,
    private _snackbarService: SnackbarService,
    private _dataStorageService: DataStorageService
  ) {
    this.storedUser = this._dataStorageService.get(USER, Storage.local)
      ? JSON.parse(this._dataStorageService.get(USER, Storage.local))
      : JSON.parse(this._dataStorageService.get(USER, Storage.session));
  }

  ngOnInit(): void {
    this.storedUser.brand &&
      this._brandsFacade.getBrand$(this.storedUser.brand).subscribe({
        next: this._getBrandSuccess.bind(this),
        error: this._error.bind(this),
      });
  }

  onSelectObject(object: IBrandListResult) {
    this.selectedObject = {
      name: object.name,
      uuid: object.uuid,
      image: object.logo,
    };

    this.objectSelected.emit(this.selectedObject);
  }

  private _getBrandSuccess(data: IBrandListResult): void {
    if (data) {
      if (!this.data) {
        this.onSelectObject(data);
      }
      this.brandsList = [data];
      this.brandsDataSource.data = [data];
      this.brandsObservable$ = this.brandsDataSource.connect();
    }
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
