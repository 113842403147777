import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ICertificate,
  ICertificateCategoriesResponse,
  ICertificateDomain,
  ICertificatesResponse,
} from '@app/core/interface/certificates.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { AfterViewInit } from '@angular/core';
import { CertificateDashboardComponent } from '../dashboard/certificates/certificate-dashboard/certificate-dashboard.component';
import { CertificatesFacade } from '@app/core/facade/certificates.facade';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-certificate-management',
  templateUrl: './certificate-management.component.html',
  styleUrls: ['./certificate-management.component.scss'],
  providers: [CertificatesFacade],
})
export class CertificateManagementComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  domains: Array<ICertificateDomain> = [];
  categories$!: Observable<ICertificateCategoriesResponse>;
  certificates: Array<ICertificate> = [];
  certificatesFullList: Array<ICertificate> = [];
  certificatesObservable$!: Observable<ICertificate[]>;
  certificationObservable$!: Observable<ICertificatesResponse[]>;
  removeUuid: string;
  isActive!: boolean;
  dataSource = new MatTableDataSource<ICertificate>();
  cardDataSource = new MatTableDataSource<ICertificate>();
  displayedColumns = ['name', 'category', 'summary', 'actions'];
  selectedCategory!: string;
  certificate!: ICertificate;
  show_inactive!: boolean;
  constructor(
    private _certificatesFacade: CertificatesFacade,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.removeUuid = '';
  }
  /**
   * On OnInit lifecycle hook we are getting the certificates
   */
  ngOnInit(): void {
    this._certificatesFacade.getCertificates$('name', 999999).subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
    this.categories$ = this._certificatesFacade.getCertificatesCategories$();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.cardDataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  viewChange(value: string): void {
    if (value === 'tableView') {
      this._changeDetectorRef.detectChanges();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.cardDataSource.paginator = this.paginator;
    }
  }
  /**
   *
   * @param id is used for deliting certificate
   * @param name is used to show warnning message before deleting certificate
   */
  deleteCertificate(id: string) {
    const deleteUuid = id;
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to remove this Certificate Type. Please note that you won`t be able to use this Type in future unless you restore it.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._certificatesFacade.deleteCertificate$(deleteUuid).subscribe({
          next: this.ngOnInit.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }
  /**
   *
   * @param data after successful getting the data we are setting certificates and set them as Table dataSource data
   */
  private _success(data: ICertificatesResponse) {
    this.paginator.pageSize = 12;
    this.certificates = data.results;
    this.certificatesFullList = [...this.certificates];
    this.updateTableData();
  }
  /**
   *
   * @param error hadles HTTP error desplaying message from backend
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
  /**
   * Filtering data when user types
   * @param value entered by user
   */
  onQuickFilterChanged(event: Event): void {
    applyFilter(event, this.cardDataSource, this.dataSource);
  }
  /**
   * Filtering data on category(dropdown) change
   * @param event dropdown change value
   */
  categoryChange(event: MatSelectChange) {
    this.selectedCategory = event.value;
    this.certificates = [];
    event.value
      ? this.certificatesFullList.forEach(cert => cert.category.name === event.value && this.certificates.push(cert))
      : (this.certificates = this.certificatesFullList);
    this.dataSource.data = this.certificates;
    this.cardDataSource.data = this.certificates;
  }

  ngOnDestroy(): void {
    if (this.cardDataSource) {
      this.cardDataSource.disconnect();
    }
  }

  createEditCertificateType(uuid: string | null): void {
    const dialogRef = this._dialog.open(CertificateDashboardComponent, {
      width: '1160px',
      height: '768px',
      autoFocus: false,
      data: uuid,
      panelClass: 'top-padding-0',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  showInactiveCertificates(event: MatCheckboxChange): void {
    this.show_inactive = event.checked;
    this.updateTableData();
  }

  updateCertificateStatus(isDeactivate: boolean, certificate: ICertificate): void {
    certificate.is_active = isDeactivate;
    this._certificatesFacade.updateCertificate$(certificate.uuid, { is_active: !isDeactivate }).subscribe(() => {
      this.ngOnInit();
      this.updateTableData();
    });
  }

  removeCertificateType(isDeactivate: boolean, certificate: ICertificate): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to remove this Certificate Type. Please note that you won`t be able to use this Type in the future unless you restore it.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        certificate.is_active = isDeactivate;
        this._certificatesFacade.updateCertificate$(certificate.uuid, { is_active: !isDeactivate }).subscribe(() => {
          this.ngOnInit();
          this.updateTableData();
        });
      }
    });
  }

  restoreCertificateType(isDeactivate: boolean, certificate: ICertificate): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to restore this Certificate Type, to the list. By restoring this type the users will be able to use it next time they create a certificate.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Restore',
        type: 'info-actions',
        text: 'info-text',
        reactivate: true,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        certificate.is_active = isDeactivate;
        this._certificatesFacade.updateCertificate$(certificate.uuid, { is_active: !isDeactivate }).subscribe(() => {
          this.ngOnInit();
          this.updateTableData();
        });
      }
    });
  }

  updateTableData(): void {
    let filteredCertificates: ICertificate[];

    if (this.show_inactive) {
      filteredCertificates = this.certificatesFullList?.sort((a, b) => {
        if (a.is_active && !b.is_active) {
          return 1;
        } else if (!a.is_active && b.is_active) {
          return -1;
        } else {
          return 0; // no change in order
        }
      });
    } else {
      filteredCertificates = this.certificatesFullList?.filter(certificate => certificate?.is_active);
    }

    this.dataSource.data = filteredCertificates;
  }
}
