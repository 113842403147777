import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  INameID,
  IProject,
  IProjectProducts,
  IProjectResponse,
  ISubproject,
} from '@app/core/interface/projects.interface';
import { IProduct, IProductDetails } from '@app/core/interface/products.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { CreateProductDialogComponent } from '@app/shared/components/create-product-dialog/create-product-dialog.component';
import { FormGroup } from '@angular/forms';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { ICreateProductResponse } from '@app/core/interface/register.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductDashboardGroup } from '../../product/product-dashboard.group';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { ProjectComponent } from '@app/module/projects/project/project.component';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-project-products-traced',
  templateUrl: './project-products-traced.component.html',
  styleUrls: ['./project-products-traced.component.scss'],
  providers: [ProductsFacade, ProductDashboardGroup],
})
export class ProjectProductsTracedComponent implements OnChanges {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @Input() dataSource = new MatTableDataSource<IProjectProducts>();
  @Input() project!: IProjectResponse;
  @Output() onUpdateSuccess = new EventEmitter<void>();
  @Output() onUpdateProductSuccess = new EventEmitter<void>();
  @ViewChild('inputFilter', { static: false }) inputFilter!: ElementRef;
  displayedColumns = ['name', 'product_description', 'assigned', 'chains_created', 'actions'];
  products!: IProduct[];
  @Input() allProducts!: IProduct[];
  subprojectsMenuList!: ISubproject[];
  is_filtered!: boolean;
  selectedSubproject!: string;
  form!: FormGroup;
  emptySubprojects!: boolean;

  constructor(
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _productsFacade: ProductsFacade,
    private _productDashboardGroup: ProductDashboardGroup,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.form = this._productDashboardGroup.productDetails;
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.length = this.dataSource.data.length;
      this.paginator.pageSize = 6;
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['project']) this.dataSource.data = changes['project'].currentValue;
    {
      if (this.paginator) {
        this.paginator.length = this.dataSource.data.length;
        this.paginator.pageSize = 6;
      }
      this.setTableData();
    }
    if (changes['allProducts']) {
      this.setProducts(changes['allProducts'].currentValue);
    }
  }

  setProducts(allProducts: IProduct[]) {
    this.products = allProducts;
  }

  openCreateDialog(productForCopy?: IProduct) {
    const config = productForCopy
      ? {
          data: { mode: 'duplicate', productId: productForCopy.uuid },
        }
      : {};
    const dialogRef = this._dialog.open(CreateProductDialogComponent, { ...config, width: '1160px' });

    dialogRef.afterClosed().subscribe((result: ICreateProductResponse) => {
      if (result) {
        this.updateProduct(this.project.uuid, true, result.uuid);
        this.onUpdateSuccess.emit();
        this.onUpdateProductSuccess.emit();
      }
    });
  }

  setTableData() {
    this.emptySubprojects = false;
    this.dataSource.data = this.project?.project_products.filter(prod => prod.is_active);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.subprojectsMenuList = this.project?.subprojects?.filter(
      sub => sub.is_active && sub.subproject_products && sub.subproject_products.length >= 1
    );
    if (this.subprojectsMenuList?.length <= 0) {
      this.emptySubprojects = true;
    }
  }

  /**
   * Focus on inputFilter in dropdown menu while typing
   */
  onBlur() {
    this.inputFilter.nativeElement.focus();
  }

  /**
   * Filtering existing products
   */
  filterItem(event: Event | KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (value) {
      this.products = this.products.filter(el => el.name.toLowerCase().includes(value.toLowerCase()));
    } else {
      this.products = this.allProducts;
    }
  }

  /**
   * Clearing filter/ Clearing input value
   */
  clearFilter() {
    this.products = this.allProducts;
    this.inputFilter.nativeElement.value = '';
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  filterBySubproject(subproject: INameID) {
    this.dataSource.data = this.dataSource.data.filter(el => el.subproject?.uuid == subproject.uuid);
    this.is_filtered = true;
    this.selectedSubproject = subproject.name;
  }

  unassignSubroject(uuid: string, is_project: boolean) {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: is_project
          ? `Note that once you unassign
        this product from the selected project it won’t be lost, and you can reassign it later to the selected project or to another one.`
          : `Note that once you unassign this sub-product from the selected project it won’t be lost, and you can reassign it later to the selected project or to another one.`,
        confirmationText: is_project
          ? 'Are you sure you want to unassign this product from the selected project?'
          : 'Are you sure you want to unassign this product from the selected sub-project?',
        btnText: 'Yes, Unassign',
        type: 'warning',
        text: 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateProduct('', is_project, uuid);
        if (this.is_filtered) {
          this.is_filtered = !this.is_filtered;
        }
      }
    });
  }

  updateProduct(uuid: string, is_project: boolean, productUUid: string): void {
    const changedFormValues: Partial<IProductDetails> = GetDirtyValues(this.form);
    if (is_project) {
      changedFormValues['project'] = uuid;
      changedFormValues['subproject'] = '';
    } else {
      changedFormValues['project'] = this.project?.uuid;
      changedFormValues['subproject'] = uuid;
    }

    this._productsFacade.updateProduct$({ ...changedFormValues }, productUUid).subscribe({
      next: () => this._successUpdateProduct(is_project),
      error: this._error.bind(this),
    });
  }

  private _successUpdateProduct(is_project: boolean): void {
    this.onUpdateSuccess.emit();
    if (is_project) {
      this.onUpdateProductSuccess.emit();
    }
  }

  createSubprojectDialog(uuid: string, is_editing: boolean, productUUid: string) {
    const dialogRef = this._dialog.open(ProjectComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        is_editing: is_editing,
        projectId: uuid,
        is_project: false,
      },
    });

    dialogRef.afterClosed().subscribe((result: IProject | ISubproject) => {
      if (result) {
        if (!is_editing) {
          this.updateProduct(result.uuid, false, productUUid);
        }
      }
    });
  }
}
