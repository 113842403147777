import { ActionList, ActionListOperations } from '@interface/action-list.interface';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { ActionListHelper } from '@app/core/utils/action-list-helper';
import { CreateBatchComponent } from '@app/module/dashboard/product/create-batch/create-batch.component';
import { IProductChainResult } from '@app/core/interface/productchain.interface';
import { InfoDialogComponent } from '../../info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductChainFacade } from '@app/core/facade/productchain.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SupplyChainViewListComponent } from '../supply-chain-view-list/supply-chain-view-list.component';

@Component({
  selector: 'app-chains-list',
  templateUrl: './chains-list.component.html',
  styleUrls: ['./chains-list.component.scss'],
  providers: [ProductChainFacade],
})
export class ChainsListComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @Input() enabledCo2 = false;
  @Input() dataSource = new MatTableDataSource<IProductChainResult>();
  @Input() chainsPaginator!: number[];
  @Input() pageSize!: number;
  @Output() refreshDataSource = new EventEmitter<boolean>();
  displayedColumns = ['name', 'reference', 'productName', 'tags', 'status', 'batches', 'actions'];
  actionLists: ActionList[] = ActionListHelper.chainListActionList;

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _facade: ProductChainFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngAfterViewInit() {
    this.paginator.pageSize = this.pageSize;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'productName':
          return item['product'].name;
        case 'name':
          return item[property].toUpperCase();
        default:
          return item[property as keyof IProductChainResult] as string;
      }
    };
  }

  viewProductChain(uuid: string): void {
    this._router.navigate([`/chain-setup/chain-management/${uuid}`, { currentUrl: this._router.url, tab: 'chains' }]);
  }

  openChainList(productChain: IProductChainResult): void {
    this._dialog.open(SupplyChainViewListComponent, {
      data: {
        productChain: productChain,
        product: productChain.product.uuid,
      },
      autoFocus: false,
      width: '1160px',
      height: '768px',
    });
  }

  createBatch(productChain: IProductChainResult): void {
    this._dialog.open(CreateBatchComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'overflow-hidden-dialog',
      data: {
        product_chain: productChain,
        suppliers: [],
      },
    });
  }

  duplicateProductChain(productChain: IProductChainResult): void {
    const navigationExtras: NavigationExtras = { state: { productChain: productChain } };
    this._router.navigate(['chain-setup'], navigationExtras);
  }

  updateChainStatus(isDeactivate: boolean, chain: IProductChainResult): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: isDeactivate
          ? 'Note that once you deactivate this Supply Chain you can reactivate it from any of the Supply Chains lists.'
          : `Are you sure you want to reactivate the supply chain ${chain.name}?`,
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Continue',
        type: isDeactivate ? 'warning' : 'info-actions',
        text: isDeactivate ? 'warning-text' : 'info-text',
        reactivate: !isDeactivate,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._facade.updateProductChainActivation$({ is_active: !isDeactivate }, chain.uuid).subscribe({
          next: this._onUpdateChain.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  private _onUpdateChain() {
    this.refreshDataSource.emit(true);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  operationClicked($event: ActionListOperations, element: IProductChainResult) {
    switch ($event) {
      case ActionListOperations.OpenChainList:
        this.openChainList(element);
        break;
      case ActionListOperations.DeactivateChain:
        this.updateChainStatus(true, element);
        break;
      case ActionListOperations.PublishChain:
        break;
      case ActionListOperations.CreateBatch:
        this.createBatch(element);
        break;
      case ActionListOperations.DuplicateChain:
        this.duplicateProductChain(element);
        break;
      case ActionListOperations.ViewProductChain:
        this.viewProductChain(element.uuid);
        break;
    }
  }
}
