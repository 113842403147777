import { ActionList, ActionListOperations } from '@app/core/interface/action-list.interface';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IAnchorDirection, INode } from '@app/core/interface/productchain.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';
import { StepSupplierIdentified, SupplierStatus } from '@app/core/interface/steps.interface';

import { ActionListHelper } from '@app/core/utils/action-list-helper';
import { ISupplier } from '@app/core/interface/suppliers.interface';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NodeService } from '@app/core/service/node.service';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { StepsFacade } from '@app/core/facade/steps.facade';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { TooltipPosition } from '../tooltip/tooltip.constants';

@Component({
  selector: 'app-chain-node',
  templateUrl: './chain-node.component.html',
  styleUrls: ['./chain-node.component.scss'],
  providers: [StepsFacade, SuppliersFacade],
})
export class ChainNodeComponent implements AfterViewInit, OnInit {
  @ViewChild('nodeEl') nodeEl!: ElementRef;
  @Input() node!: INode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() jsPlumbInstance!: any;
  @Input() direction!: string;
  supplierList!: ISupplier[];
  batchSetup = true;
  isDraggable = true;
  currentPositionX?: number;
  currentPositionY?: number;
  SupplierStatus: typeof SupplierStatus = SupplierStatus;
  supplierStatusIcon = '';
  verifySupplier = false;
  verifySupplierText = '';
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  actionList: ActionList[] = ActionListHelper.deactivateBatchNodeActionList;
  actionListSupplyChain: ActionList[] = ActionListHelper.supplyChainNodeAcitionList;
  actionListReactiveStep: ActionList[] = ActionListHelper.reactiveBatchNodeActionList;

  constructor(
    private nodeService: NodeService,
    private _stepFacade: StepsFacade,
    private _suppliersFacade: SuppliersFacade,
    public dialog: MatDialog,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog
  ) {
    this.batchSetup = window.location.href.includes('/batch-management');
  }

  ngOnInit(): void {
    if (this.node) {
      this.isDraggable = !this.node.isChainComplete;
      this.supplierStatusIcon =
        this.node.supplier_status === SupplierStatus.to_update
          ? 'more-circle'
          : this.node.supplier_status === SupplierStatus.updated
            ? 'tick-circle-green'
            : 'circle-slash';

      this.verifySupplier =
        this.node.sip_supplier_identified !== null &&
        this.node.sip_supplier_identified_approved !== StepSupplierIdentified.approved;

      this.verifySupplierText = !this.batchSetup
        ? "This step's current supplier matches another supplier. Please open the step to verify this matching."
        : "This step's supplier has disapproved the delivered quantity & proofs information. Please open the step for more information.";
    }
  }

  ngAfterViewInit() {
    const exampleDropOptions = {
      tolerance: 'touch',
      hoverClass: 'dropHover',
      activeClass: 'dragActive',
    };
    const Endpoint1 = {
      endpoint: ['Dot', { radius: 12, cssClass: 'start-endpoint' }],
      paintStyle: { fill: 'white' },
      isSource: true,
      scope: 'jsPlumb_DefaultScope',
      connectorStyle: { stroke: '#2C428E', strokeWidth: 3 },
      connector: ['Flowchart', { curviness: 63, cornerRadius: 5, hoverClass: 'conn-hover' }],
      maxConnections: 30,
      isTarget: false,
      connectorOverlays: [
        ['Arrow', { location: 1 }],
        ['Label', { cssClass: 'custom-overlay' }],
      ],
      dropOptions: exampleDropOptions,
      enabled: !this.batchSetup && this.isDraggable,
    };
    const Endpoint2 = {
      endpoint: ['Dot', { radius: 12, cssClass: 'end-endpoint' }],
      paintStyle: { fill: '#FFCA10' },
      isSource: false,
      scope: 'jsPlumb_DefaultScope',
      maxConnections: 30,
      isTarget: true,
      dropOptions: exampleDropOptions,
      enabled: !this.batchSetup && this.isDraggable,
    };
    const { uuid } = this.node;

    const anchorDirection: IAnchorDirection = {
      anchor1: 'Bottom',
      anchor2: 'Top',
    };

    if (this.direction === 'bottomTop') {
      anchorDirection.anchor1 = 'Top';
      anchorDirection.anchor2 = 'Bottom';
    } else if (this.direction === 'leftRight') {
      anchorDirection.anchor1 = 'Right';
      anchorDirection.anchor2 = 'Left';
    } else if (this.direction === 'rightLeft') {
      anchorDirection.anchor1 = 'Left';
      anchorDirection.anchor2 = 'Right';
    }

    this.jsPlumbInstance.addEndpoint(uuid, { anchor: anchorDirection.anchor1, uuid: uuid + '_bottom' }, Endpoint1);
    this.jsPlumbInstance.addEndpoint(uuid, { anchor: anchorDirection.anchor2, uuid: uuid + '_top' }, Endpoint2);

    if (!this.batchSetup && this.isDraggable) {
      this.jsPlumbInstance.draggable(uuid);
    }

    this.currentPositionX = this.node.position_x;
    this.currentPositionY = this.node.position_y;
  }

  mouseLeave(): void {
    const position_x = this.nodeEl.nativeElement.offsetTop;
    const position_y = this.nodeEl.nativeElement.offsetLeft;
    if (position_x !== this.currentPositionX || position_y !== this.currentPositionY) {
      this.nodeService.stepMoved.next(true);
      this.currentPositionX = position_x;
      this.currentPositionY = position_y;
    }
  }

  removeNode(node: INode) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete step: ${node.name} and related links?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result && node.uuid) {
        this._stepFacade.deleteStep$(node.uuid).subscribe(() => {
          this.jsPlumbInstance.removeAllEndpoints(node.uuid);
          this.jsPlumbInstance.remove(node.uuid);
          this.nodeService.stepDeleted.next(true);
        });
      }
    });
  }

  stepClick(node: INode, isComplete: boolean) {
    const nodeData = {
      ...node,
      position_x: this.nodeEl.nativeElement.offsetTop,
      position_y: this.nodeEl.nativeElement.offsetLeft,
    };
    if (isComplete) {
      this.nodeService.completeStepData.next(nodeData);
    } else {
      this.nodeService.selectedStepData.next(nodeData);
    }
  }

  stepSingleClick(e: MouseEvent, id: string | undefined) {
    if (e.ctrlKey) {
      document.getElementById(`${id}`)?.classList.add('selected');
      this.jsPlumbInstance.addToPosse(document.getElementById(`${id}`), 'posse');
    } else {
      const nodeList = document.querySelectorAll('.node');
      const selectedNodes = document.querySelectorAll('.selected');
      if (selectedNodes.length) {
        if (selectedNodes.length === 1) {
          nodeList.forEach((node: Element) => {
            node.classList.remove('selected');
            this.jsPlumbInstance.removeFromPosse(document.getElementById(`${node.id}`), 'posse');
          });

          document.getElementById(`${id}`)?.classList.add('selected');
          this.jsPlumbInstance.addToPosse(document.getElementById(`${id}`), 'posse');
        } else if (selectedNodes.length > 1 && !document.getElementById(`${id}`)?.classList.contains('selected')) {
          nodeList.forEach((node: Element) => {
            node.classList.remove('selected');
            this.jsPlumbInstance.removeFromPosse(document.getElementById(`${node.id}`), 'posse');
          });

          document.getElementById(`${id}`)?.classList.add('selected');
          this.jsPlumbInstance.addToPosse(document.getElementById(`${id}`), 'posse');
        }
      } else {
        document.getElementById(`${id}`)?.classList.add('selected');
        this.jsPlumbInstance.addToPosse(document.getElementById(`${id}`), 'posse');
      }
    }
  }

  getSuppliers(): void {
    this._suppliersFacade.getSupplierList$().subscribe(data => {
      this.supplierList = data.results.filter(supplier => supplier.is_active);
      this.node = {
        ...this.node,
        position_x: this.nodeEl.nativeElement.offsetTop,
        position_y: this.nodeEl.nativeElement.offsetLeft,
      };
    });
  }

  reactivateBatchStep(node: INode, isActive: boolean) {
    const is_active = isActive;
    if (node.uuid) {
      this._stepFacade.activateDeactivateBatchStep(node.uuid, is_active).subscribe({
        next: this.activateDeactivateBatchStepSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  activateDeactivateBatchStepSuccess(node: INode): void {
    const is_active = node.is_active;
    this.nodeService.completeStepData.next(null);
    this._snackbarService.openTypeSnackbar(
      `This step was successfully ${!is_active ? 'deactivated' : 'reactivated'}`,
      NotificationType.success
    );
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  deactivateBatchStep(node: INode, isActive: boolean) {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'Please note that you are about to deactivate the step, and all related steps will be deactivated also.',
        confirmationText: 'Are you sure you <br> want to continue?',
        btnText: 'Yes, Continue',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.reactivateBatchStep(node, isActive);
      }
    });
  }

  operationClicked($event: ActionListOperations) {
    switch ($event) {
      case ActionListOperations.EditChainNode:
        this.stepClick(this.node, this.batchSetup);
        break;
      case ActionListOperations.DeactivateStep:
        this.deactivateBatchStep(this.node, false);
        break;
    }
  }
  operationClickedSupplyChain($event: ActionListOperations) {
    switch ($event) {
      case ActionListOperations.EditChainNode:
        this.stepClick(this.node, false);
        break;
      case ActionListOperations.DeleteStep:
        this.removeNode(this.node);
        break;
    }
  }

  operationClickedReactiveStep($event: ActionListOperations) {
    switch ($event) {
      case ActionListOperations.EditChainNode:
        this.stepClick(this.node, this.batchSetup);
        break;
      case ActionListOperations.ReactivateStep:
        this.reactivateBatchStep(this.node, true);
        break;
      case ActionListOperations.ViewProductChain:
        this.nodeService.completeStepData.next(this.node);
        break;
    }
  }
}
