import { Component, Input } from '@angular/core';
import {
  ILanguageManagement,
  ILanguageManagementProduct,
  ILanguageManagementResponse,
} from '@app/core/interface/language-management.interface';

import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { ProductTranslationDialogComponent } from '../../product-translation-dialog/product-translation-dialog.component';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-product-translation-card',
  templateUrl: './product-translation-card.component.html',
  styleUrl: './product-translation-card.component.scss',
})
export class ProductTranslationCardComponent {
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';
  @Input() product!: ILanguageManagementProduct;
  modelName!: string;
  productDataFromBackend!: ILanguageManagementResponse;
  productDataForTranslate: ILanguageManagement[] = [];

  constructor(
    readonly _languageManagementFacade: LanguageManagementFacade,
    readonly _snackbarService: SnackbarService,
    readonly _dialog: MatDialog
  ) {}

  isTranslationComplete(product: ILanguageManagementProduct): boolean {
    return product.translations_progress === product.translations_total;
  }

  manageTranslations(product: ILanguageManagementProduct): void {
    this._getProductForTranslate(product);
  }

  ////////////////////////////////////////Private Methods////////////////////////////////////////

  private _openDialog(product: ILanguageManagementProduct): void {
    this._dialog.open(ProductTranslationDialogComponent, {
      width: '1160px',
      height: '800px',
      panelClass: 'top-padding-0',
      data: {
        product: product,
        productDataForTranslate: this.productDataForTranslate,
        modelName: this.modelName,
      },
    });
  }

  private _getProductForTranslate(product: ILanguageManagementProduct): void {
    this._languageManagementFacade.getProductForTranslation$(product.uuid).subscribe({
      next: data => this._getProductForTranslateSuccess(data, product),
      error: this._error.bind(this),
    });
  }

  private _getProductForTranslateSuccess(data: ILanguageManagementResponse, product: ILanguageManagementProduct): void {
    this.productDataFromBackend = data;

    this.productDataForTranslate = [
      {
        ...this.productDataFromBackend.default_translation,
        uuid: 'default',
        count_translated_fields: 0,
      },
      ...this.productDataFromBackend.translations,
    ];
    this.modelName = 'Product data';

    this._openDialog(product);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
