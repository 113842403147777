<div class="holder d-flex flex-column justify-content-between">
  <div class="content-holder">
    <p class="m-0 title">{{ 'Here you can add different language translations to your certifications' | translate }}</p>
    <div class="header d-flex justify-content-between">
      <div></div>
      <!-- TODO when BE will be ready -->
      <!-- <mat-button-toggle-group
        #group="matButtonToggleGroup"
        class="translation-toggle"
        (change)="onToggleChange($event)"
      >
        <div class="toggle">
          <mat-button-toggle value="missing" aria-label="Product Chains List" [checked]="isMissing">
            <span>{{ 'Missing translations (4)' | translate }}</span>
          </mat-button-toggle>
        </div>
        <div class="toggle">
          <mat-button-toggle value="fully" aria-label="Batches List" [checked]="!isMissing">
            <span>{{ 'Fully translated (0)' | translate }}</span>
          </mat-button-toggle>
        </div>
      </mat-button-toggle-group> -->
      <div class="filter">
        <mat-form-field class="input-primary mat-form-field-10-px-brd-rd custom-filter-header" appearance="outline">
          <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
          <input (keyup)="applyFilter($event)" matInput placeholder="Quick search..." #input />
          <app-custom-icon
            class="search-filter"
            [icon_category]="'search'"
            [icon_name]="'search-normal-2'"
          ></app-custom-icon>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="isMissing" class="card-wrapper d-flex">
      <div class="card-holder" *ngFor="let certtificate of certificatesObservable$ | async">
        <app-certificate-translation-card [certtificate]="certtificate"></app-certificate-translation-card>
      </div>
    </div>
    <!-- <div *ngIf="!isMissing" class="card-wrapper d-flex">
      <div class="card-holder" *ngFor="let cert of fullyCert">
        <app-certificate-translation-card [cert]="cert"></app-certificate-translation-card>
      </div>
    </div> -->
  </div>
  <div class="footer">
    <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of products"></mat-paginator>
  </div>
</div>
