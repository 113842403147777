import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Country, ISupplierIngredient } from '@app/core/interface/suppliers.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { CreateComponentDialogComponent } from '@app/shared/components/create-component-dialog/create-component-dialog.component';
import { FlagComponentAsChemicalComponent } from '@app/shared/components/flag-component-as-chemical/flag-component-as-chemical.component';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { IngredientFacade } from '@app/core/facade/ingredient.facade';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-supplier-ingredients',
  templateUrl: './supplier-ingredients.component.html',
  styleUrls: ['./supplier-ingredients.component.scss'],
})
export class SuppliersIngredientsComponent implements AfterViewInit {
  @Input() add!: boolean;
  @Input() supplierUuid!: string;
  @Input() ingredients!: ISupplierIngredient[];
  @Output() refresh = new EventEmitter<boolean>(false);
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() dataSource: MatTableDataSource<ISupplierIngredient> = new MatTableDataSource<ISupplierIngredient>();
  selectedIngredient = {};
  displayedColumns: string[] = ['name', 'reference', 'description', 'site_location', 'actions'];

  component!: ISupplierIngredient;
  @Input() component_btn!: boolean;
  @Output() show_buttons = new EventEmitter<boolean>();
  constructor(
    private _dialog: MatDialog,
    private _facade: IngredientFacade,
    private _snackbarService: SnackbarService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.pageSize = 6;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'site_location':
          return item['manufactory'] ? (item['manufactory'].country as unknown as Country).name : '';
        default:
          return item[property as keyof ISupplierIngredient] as string;
      }
    };
    this._changeDetectorRef.detectChanges();
  }

  removeComponent(isDeactivate: boolean, component: ISupplierIngredient): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          "You are about to deactivate the component from the supplier site. Please note that you won't be able to use this component in the future unless you reactivate it. The component added in the previous steps will remain active.",
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, continue',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        component.is_active = isDeactivate;
        this._facade.updateIngredient$(component.uuid, { is_active: !isDeactivate }).subscribe({
          next: this._refreshParentData.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  restoreComponent(isDeactivate: boolean, component: ISupplierIngredient): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to restore this component. By restoring this component the users will be able to use it.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, continue',
        type: 'info-actions',
        text: 'info-text',
        reactivate: true,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        component.is_active = isDeactivate;
        this._facade.updateIngredient$(component.uuid, { is_active: !isDeactivate }).subscribe({
          next: this._refreshParentData.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  editIngredientDialog(component: ISupplierIngredient) {
    const dialogRef = this._dialog.open(CreateComponentDialogComponent, {
      width: '800px',
      height: '735px',
      data: {
        mode: 'edit',
        ingredient: component,
        supplierUUID: this.supplierUuid,
        component_btn: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.refresh.emit(true);
      }
    });
  }

  onFlagAsChemical(element: ISupplierIngredient) {
    const dialogRef = this._dialog.open(FlagComponentAsChemicalComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: element,
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this._refreshParentData();
      }
    });
  }

  private _refreshParentData() {
    this.refresh.emit(true);
  }
  /**
   *
   * @param error Hadles HTTP error desplaying message from backend
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
