import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IFactory, ISupplier, ISupplierResponse } from '@app/core/interface/suppliers.interface';

import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { MatTabGroup } from '@angular/material/tabs';
import { NotificationType } from '@app/core/constants';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SupplierDashboardGroup } from '@app/module/dashboard/supplier/supplier-dashboard.group';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-supplier-and-site',
  templateUrl: './create-supplier-and-site.component.html',
  styleUrls: ['./create-supplier-and-site.component.scss'],
  providers: [SupplierDashboardGroup],
})
export class CreateSupplierAndSiteComponent {
  @Input() productUuid!: string;
  @Input() supplierUuid!: string;
  @Input() isCreateSupplier!: boolean;
  @Input() supplierSites!: IFactory[];
  @Output() addedSupplier = new EventEmitter<ISupplier>();
  @Output() createdSite = new EventEmitter<IManufactory | IManufactory[] | null>();
  @ViewChild('tabs') tabs!: MatTabGroup;
  form!: UntypedFormGroup;
  isShowNote = true;
  isShowSitesList = true;

  constructor(
    private _group: SupplierDashboardGroup,
    private _suppliersFacade: SuppliersFacade,
    private _productsFacade: ProductsFacade,
    private _snackbarService: SnackbarService
  ) {
    this.form = this._group.newSupplierForm;
  }

  /**
   * Gets supplier using this.uuid
   */
  getSupplier(): void {
    this._suppliersFacade.getSupplier$(this.supplierUuid).subscribe({
      next: this._setSupplierResponse.bind(this),
      error: this._error.bind(this),
    });
  }

  saveImageData(file: File): void {
    this.form.get('logo')?.setValue(file ? file : '');
    this.form.get('logo')?.markAsDirty();
  }

  onCreateSupplier() {
    const changedFormValues: Partial<ISupplier> = GetDirtyValues(this.form);
    if (this.form.valid) {
      this._suppliersFacade.createSupplier$({ ...changedFormValues }).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  addSupplier() {
    this._productsFacade.addSupplierToProduct$(this.productUuid, this.supplierUuid).subscribe({
      next: this._addSupplierSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  onSiteCreated(site: IManufactory | null) {
    if (site) {
      if (!this.isCreateSupplier) {
        this.createdSite.emit(site);
      } else {
        this.supplierSites.push(site as IFactory);
        this.isShowSitesList = true;
      }
    } else {
      this.createdSite.emit(site);
    }
  }

  onDone() {
    if (this.supplierSites) {
      this.createdSite.emit(this.supplierSites);
    } else {
      this.createdSite.emit(null);
    }
  }

  private _success(data: ISupplier): void {
    this.supplierUuid = data.uuid;
    this.addSupplier();
  }

  private _addSupplierSuccess(data: ISupplier): void {
    this.addedSupplier.emit(data);
  }

  /**
   * sets supplier after success get supplier response
   * @param response success response from get supplier
   */
  private _setSupplierResponse(response: ISupplierResponse) {
    this.supplierSites = response.manufactories as IFactory[];
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
