import { requestDialogTooltip, requestTypes } from '../data-privacy.constants';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { Component } from '@angular/core';
import { DataPrivacyGroup } from '../data-privacy.group';
import { ISubjectRight } from '@app/core/interface/brands.interface';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-request-creation',
  templateUrl: './request-creation.component.html',
  styleUrl: './request-creation.component.scss',
})
export class RequestCreationComponent {
  form: UntypedFormGroup;
  requestTypes = requestTypes;
  requestDialogTooltip = requestDialogTooltip;

  constructor(
    private _formGroup: DataPrivacyGroup,
    private _brandsFacade: BrandsFacade,
    public dialogRef: MatDialogRef<RequestCreationComponent>,
    private _snackbarService: SnackbarService
  ) {
    this.form = this._formGroup.requestCreation;
    this.form.reset();
  }

  createRequest(): void {
    if (this.form.valid) {
      this._brandsFacade.createSubjectRightsRequest$({ ...this.form.value }).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _success(): void {
    this.dialogRef.close(true);
  }

  private _error(error: ISubjectRight): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
