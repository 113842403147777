import { Component, OnInit } from '@angular/core';
import {
  ICertificationSupplier,
  ISupplierIngredient,
  ISupplierResponse,
} from '@app/core/interface/suppliers.interface';
import { ILanguage, ILanguagesResponse } from '@app/core/interface/languages.interface';

import { ActivatedRoute } from '@angular/router';
import { AddContactComponent } from './supplier-contacts/add-contact/add-contact.component';
import { AddFactoryComponent } from './add-factory/add-factory.component';
import { CertificationDashboardComponent } from '../dashboard/certificates/certification-dashboard/certification-dashboard.component';
import { CreateComponentDialogComponent } from '@app/shared/components/create-component-dialog/create-component-dialog.component';
import { LanguagesFacade } from '@app/core/facade/languages.facade';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { SuppliersService } from '@app/core/service/suppliers.service';
import { applyFilter } from '@app/core/utils/apply-filter';
import { environment } from '@env/environment';
import { take } from 'rxjs';

@Component({
  selector: 'app-supplier-management',
  templateUrl: './supplier-management.component.html',
  styleUrls: ['./supplier-management.component.scss'],
})
export class SupplierManagementComponent implements OnInit {
  supplier!: ISupplierResponse;
  uuid!: string;
  name = '';
  isEditDetails = false;
  selectedIndex = 0;
  chainUuidList: string[] = [];
  dataSource = new MatTableDataSource<ISupplierIngredient>();
  componentList!: ISupplierIngredient[];
  components: Array<ISupplierIngredient> = [];
  show_inactive!: boolean;
  show_buttons!: boolean;
  component_btn!: boolean;
  show_deactivated!: boolean;
  certificatesList: Array<ICertificationSupplier> = [];
  manufactoriesList: Array<ICertificationSupplier> = [];
  languages: ILanguage[] = [];
  selectedView = 'tableView';
  environment = environment;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _suppliersFacade: SuppliersFacade,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _suppliersService: SuppliersService,
    private languagesFacade: LanguagesFacade
  ) {}

  /**
   * On OnInit we are setting this.uuid and getting supplier after that
   */
  ngOnInit() {
    this._activatedRoute.params.pipe(take(1)).subscribe(params => {
      this.uuid = params['uuid'];
    });
    this.getSupplier();

    this.getLanguages();
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedIndex = event.index;
  }

  getLanguages(): void {
    this.languagesFacade.getLanguages$().subscribe({
      next: this._setLanguagesResponse.bind(this),
      error: this._error.bind(this),
    });
  }

  _setLanguagesResponse(languageResponse: ILanguagesResponse) {
    this.languages = languageResponse.results;
  }
  /**
   * Gets supplier using this.uuid
   */
  getSupplier(): void {
    this._suppliersFacade.getSupplier$(this.uuid).subscribe({
      next: this._setSupplierResponse.bind(this),
      error: this._error.bind(this),
    });
  }
  /**
   * Opens add supplier contact popup and if there is data after close we are trigering getSupplier again to refresh data
   */
  addSupplierContact() {
    const dialogRef = this._dialog.open(AddContactComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: { supplierUuid: this.uuid, languages: this.languages },
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.getSupplier();
      }
    });
  }
  /**
   * Opens add factory popup and if there is data after close we are trigering getSupplier again to refresh data
   */
  addFactory(): void {
    const dialogRef = this._dialog.open(AddFactoryComponent, {
      data: { supplier: this.uuid, supplierManufactories: this.supplier.manufactories },
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.getSupplier();
      }
    });
  }
  /**
   * After successful changing the name on backend we are trigering newName event and with that invoking this method witch sets the name to the supplier
   * @param newName Updated name of the supplier
   */
  updateName(newName: string) {
    this.supplier = { ...this.supplier, name: newName };
    this.name = this.supplier.name;
  }
  /**
   * sets supplier after success get supplier response
   * @param response success response from get supplier
   */
  private _setSupplierResponse(response: ISupplierResponse) {
    this.supplier = response;
    this.name = this.supplier.name;
    this.componentList = this.supplier.ingredients;
    this.certificatesList = this.supplier.certification_supplier;
    response.product_chain.map(chain => this.chainUuidList.push(chain.uuid));

    this._suppliersService.supplierDetailsName.next({
      label: response.name,
      url: response.uuid,
    });
    this.showInactiveComponents();
  }
  /**
   *
   * @param error Hadles HTTP error desplaying message from backend
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
  /**
   * On Creating Certification we are opening Certification Dialog passing Project ID
   */
  createCertificationDialog(): void {
    const dialogRef = this._dialog.open(CertificationDashboardComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      autoFocus: false,
      data: {
        content_type: 'Supplier',
        uuid: this.supplier.uuid,
        manufactories: this.supplier.manufactories,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getSupplier();
      }
    });
  }

  createIngredientDialog() {
    const dialogRef = this._dialog.open(CreateComponentDialogComponent, {
      width: '800px',
      height: '735px',
      panelClass: 'top-padding-0',
      data: {
        mode: 'create',
        supplierUUID: this.supplier.uuid,
        logo: this.supplier.logo,
        show_buttons: true,
        component_btn: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getSupplier();
      }
    });
  }

  showInactiveComponents(event?: MatCheckboxChange): void {
    this.show_inactive = event?.checked ?? this.show_inactive;
    this.componentList = this.supplier.ingredients;
    if (this.show_inactive) {
      // Display inactive ingredients first, then active ones
      this.componentList.sort((a, b) => {
        return a.is_active === b.is_active ? 0 : a.is_active ? 1 : -1;
      });
    } else {
      // Display only active ingredients
      this.componentList = this.supplier.ingredients.filter(ingredient => ingredient.is_active);
    }

    this.dataSource.data = this.componentList;
  }

  // openEmailDialog() {
  //   const ref = this._dialog.open(InviteUsersDialogComponent, {
  //     // data: element,
  //     width: '900px',
  //     height: 'calc(100% - 100px)',
  //     autoFocus: false,
  //     panelClass: 'email-dialog-container',
  //     data: { uuid: this.uuid, languages: this.languages, supplier: this.supplier },
  //   });

  //   ref.afterClosed().subscribe((res: string) => {
  //     if (res) this.getSupplier();
  //   });
  // }

  applyFilter(event: Event) {
    applyFilter(event, this.dataSource);
  }

  ////certificates filter
  _applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.supplier.certification_supplier = this.filterCertificates(filterValue);
  }

  filterCertificates(filterValue: string) {
    return this.certificatesList.filter(certificate => {
      // Filtering by name, category name, domain name, certificator...
      const nameMatch = certificate.certificate.name.toLowerCase().includes(filterValue);
      const categoryNameMatch = certificate.certificate.category.name.toLowerCase().includes(filterValue);
      const domainNameMatch =
        certificate.certificate.domain && certificate.certificate.domain.name.toLowerCase().includes(filterValue);
      const certificatorMatch = certificate.certificator.toLowerCase().includes(filterValue);
      const supplierNameMatch = certificate.supplier_name.toLowerCase().includes(filterValue);

      // Return true if any of the filter criteria match.
      return nameMatch || categoryNameMatch || domainNameMatch || certificatorMatch || supplierNameMatch;
    });
  }

  onToggleChange(event: MatButtonToggleChange) {
    this.selectedView = event.value;
  }
}
