import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IProductChainStep, ITypeStepErrorResponse } from '@app/core/interface/steps.interface';
import { SupplierSiteMappingDialogComponent } from '../supplier-site-mapping-dialog/supplier-site-mapping-dialog.component';
import { StepsFacade } from '@app/core/facade/steps.facade';
import { StepsService } from '@app/core/service/steps.service';
import { UntypedFormGroup } from '@angular/forms';
import { ProductChainGroup } from '../product-chain.group';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-supplier-identification-dialog',
  templateUrl: './supplier-identification-dialog.component.html',
  styleUrls: ['./supplier-identification-dialog.component.scss'],
  providers: [StepsFacade, ProductChainGroup],
})
export class SupplierIdentificationDialogComponent {
  mainSiteAddress?: string;
  mainSiteAddressIdentified?: string;
  form: UntypedFormGroup;

  constructor(
    private _stepFacade: StepsFacade,
    private _stepService: StepsService,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _group: ProductChainGroup,
    public dialogRef: MatDialogRef<SupplierIdentificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public step: IProductChainStep
  ) {
    this.form = this._group.selectSiteForm;

    this.mainSiteAddress = this._concatenateFields(
      step.supplier?.address,
      step.supplier?.city,
      step.supplier?.region ?? step.manufactories.region,
      step.supplier?.country?.name
    );
    this.mainSiteAddressIdentified = this._concatenateFields(
      step.sip_supplier_identified?.address,
      step.sip_supplier_identified?.city,
      step.sip_supplier_identified?.region,
      step.sip_supplier_identified?.country?.name
    );
  }

  close(): void {
    this._stepFacade.notMatchingSupplier(this.step.uuid).subscribe({
      next: this._onSuccesNotMatch.bind(this),
      error: this._onError.bind(this),
    });
  }

  _onSuccesNotMatch() {
    this.dialogRef.close();
    this._stepService.notMatchingSupplierSubject?.next(true);
  }

  private _onError(error: ITypeStepErrorResponse) {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }

  onValidateSupplier() {
    if (!this.step.manufactories) {
      // Call the API for matching supplier with empty manufactory id.
      this.form.patchValue({
        manufactory: '',
      });
      this._stepFacade.matchingSupplier(this.step.uuid, this.form.value).subscribe({
        next: this._onSuccessValidation.bind(this),
        error: this._onError.bind(this),
      });
    } else {
      this._dialog.open(SupplierSiteMappingDialogComponent, {
        // data: element,
        width: '1156px',
        height: 'calc(100% - 100px)',
        autoFocus: false,
        panelClass: 'step-detail-dialog-container',
        data: this.step,
      });

      this.dialogRef.close();
    }
  }

  _onSuccessValidation() {
    this._stepService.matchingSupplierVerification?.next(true);
    this.dialogRef.close();
  }

  _concatenateFields(field1?: string, field2?: string, field3?: string, field4?: string) {
    // Create an array to store non-null and non-empty fields
    const nonEmptyFields = [];

    // Check each field and add it to the array if it's not null or empty
    if (field1 !== null && field1 !== '') {
      nonEmptyFields.push(field1);
    }

    if (field2 !== null && field2 !== '') {
      nonEmptyFields.push(field2);
    }

    if (field3 !== null && field3 !== '') {
      nonEmptyFields.push(field3);
    }

    if (field4 !== null && field4 !== '') {
      nonEmptyFields.push(field4);
    }

    // Use the join method to concatenate the non-empty fields with commas
    const resultString = nonEmptyFields.join(', ');

    return resultString;
  }
}
