import {
  ILanguageManagementBrandsResponse,
  ILanguageManagementCertificatesResponse,
  ILanguageManagementProductsResponse,
  ILanguageManagementPutRequest,
  ILanguageManagementResponse,
  ILanguageManagementSuppliersResponse,
  ILanguageManagementSupplyChainListResponse,
  ILanguageManagementSupplyChainResponse,
} from '../interface/language-management.interface';
import { Injectable, signal } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageManagementService {
  private apiUrl = environment.api;
  constructor(private _http: HttpClient) {}

  getLanguageManagementCertificates$(): Observable<ILanguageManagementCertificatesResponse> {
    return this._http.get<ILanguageManagementCertificatesResponse>(
      `${this.apiUrl}/language-management/certificate-translations/${this.supplyChainParamsFilter()}${this.supplyChainUuidFilter()}`
    );
  }

  getLanguageManagementBrands$(): Observable<ILanguageManagementBrandsResponse> {
    return this._http.get<ILanguageManagementBrandsResponse>(`${this.apiUrl}/language-management/brand-translations/`);
  }

  getLanguageManagementProducts$(): Observable<ILanguageManagementProductsResponse> {
    return this._http.get<ILanguageManagementProductsResponse>(
      `${this.apiUrl}/language-management/product-translations/${this.supplyChainParamsFilter()}${this.supplyChainUuidFilter()}`
    );
  }

  getLanguageManagementSupplyChains$(): Observable<ILanguageManagementSupplyChainResponse> {
    return this._http.get<ILanguageManagementSupplyChainResponse>(
      `${this.apiUrl}/language-management/chain-translations/${this.supplyChainParamsFilter()}${this.supplyChainUuidFilter()}`
    );
  }

  getBrandForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._http.get<ILanguageManagementResponse>(
      `${this.apiUrl}/language-management/brand-translations/${uuid}/`
    );
  }

  putBrandForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._http.put<ILanguageManagementPutRequest>(
      `${this.apiUrl}/language-management/brand-translations/${uuid}/translations/`,
      data
    );
  }

  getCertificatedForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._http.get<ILanguageManagementResponse>(
      `${this.apiUrl}/language-management/certificate-translations/${uuid}/`
    );
  }

  putCertificateForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._http.put<ILanguageManagementPutRequest>(
      `${this.apiUrl}/language-management/certificate-translations/${uuid}/translations/`,
      data
    );
  }

  getProductForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._http.get<ILanguageManagementResponse>(
      `${this.apiUrl}/language-management/product-translations/${uuid}/`
    );
  }

  putProductForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._http.put<ILanguageManagementPutRequest>(
      `${this.apiUrl}/language-management/product-translations/${uuid}/translations/`,
      data
    );
  }

  getLanguageManagementSuppliers$(): Observable<ILanguageManagementSuppliersResponse> {
    return this._http.get<ILanguageManagementSuppliersResponse>(
      `${this.apiUrl}/language-management/supplier-translations/${this.supplyChainParamsFilter()}${this.supplyChainUuidFilter()}`
    );
  }
  getSupplierForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._http.get<ILanguageManagementResponse>(
      `${this.apiUrl}/language-management/supplier-translations/${uuid}/`
    );
  }

  putSupplierForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._http.put<ILanguageManagementPutRequest>(
      `${this.apiUrl}/language-management/supplier-translations/${uuid}/translations/`,
      data
    );
  }

  getListOfSupplyCains$(): Observable<ILanguageManagementSupplyChainListResponse> {
    return this._http.get<ILanguageManagementSupplyChainListResponse>(
      `${this.apiUrl}/language-management/supplychain_filter/`
    );
  }

  supplyChainUuidFilter = signal<string>('');
  supplyChainParamsFilter = signal<string>('');
}
