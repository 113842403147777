import {
  ILanguageManagementBrandsResponse,
  ILanguageManagementCertificatesResponse,
  ILanguageManagementProductsResponse,
  ILanguageManagementPutRequest,
  ILanguageManagementResponse,
  ILanguageManagementSuppliersResponse,
  ILanguageManagementSupplyChainListResponse,
  ILanguageManagementSupplyChainResponse,
} from '../interface/language-management.interface';
import { Observable, ObservableInput, catchError, take, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { LanguageManagementService } from '../service/language-management.service';

@Injectable()
export class LanguageManagementFacade {
  constructor(private _languageManagementService: LanguageManagementService) {}

  getLanguageManagementCertificates$(): Observable<ILanguageManagementCertificatesResponse> {
    return this._languageManagementService.getLanguageManagementCertificates$().pipe(
      take(1),
      catchError(
        ({
          error,
        }: ILanguageManagementCertificatesResponse): ObservableInput<ILanguageManagementCertificatesResponse> =>
          throwError(() => error)
      )
    );
  }
  getLanguageManagementBrands$(): Observable<ILanguageManagementBrandsResponse> {
    return this._languageManagementService.getLanguageManagementBrands$().pipe(
      take(1),
      catchError(
        ({ error }: ILanguageManagementBrandsResponse): ObservableInput<ILanguageManagementBrandsResponse> =>
          throwError(() => error)
      )
    );
  }

  getLanguageManagementProducts$(): Observable<ILanguageManagementProductsResponse> {
    return this._languageManagementService.getLanguageManagementProducts$().pipe(
      take(1),
      catchError(
        ({ error }: ILanguageManagementProductsResponse): ObservableInput<ILanguageManagementProductsResponse> =>
          throwError(() => error)
      )
    );
  }

  getLanguageManagementSupplyChains$(): Observable<ILanguageManagementSupplyChainResponse> {
    return this._languageManagementService.getLanguageManagementSupplyChains$().pipe(
      take(1),
      catchError(
        ({ error }: ILanguageManagementSupplyChainResponse): ObservableInput<ILanguageManagementSupplyChainResponse> =>
          throwError(() => error)
      )
    );
  }

  getBrandForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._languageManagementService.getBrandForTranslation$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: ILanguageManagementResponse): ObservableInput<ILanguageManagementResponse> =>
          throwError(() => error)
      )
    );
  }

  putBrandForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._languageManagementService.putBrandForTranslation$(uuid, data).pipe(
      take(1),
      catchError(
        ({ error }: ILanguageManagementPutRequest): ObservableInput<ILanguageManagementPutRequest> =>
          throwError(() => error)
      )
    );
  }

  getCertificateForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._languageManagementService.getCertificatedForTranslation$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: ILanguageManagementResponse): ObservableInput<ILanguageManagementResponse> =>
          throwError(() => error)
      )
    );
  }
  getProductForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._languageManagementService.getProductForTranslation$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: ILanguageManagementResponse): ObservableInput<ILanguageManagementResponse> =>
          throwError(() => error)
      )
    );
  }

  putCertificateForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._languageManagementService.putCertificateForTranslation$(uuid, data).pipe(
      take(1),
      catchError(
        ({ error }: ILanguageManagementPutRequest): ObservableInput<ILanguageManagementPutRequest> =>
          throwError(() => error)
      )
    );
  }
  putProductForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._languageManagementService.putProductForTranslation$(uuid, data).pipe(
      take(1),
      catchError(
        ({ error }: ILanguageManagementPutRequest): ObservableInput<ILanguageManagementPutRequest> =>
          throwError(() => error)
      )
    );
  }

  getLanguageManagementSuppliers$(): Observable<ILanguageManagementSuppliersResponse> {
    return this._languageManagementService.getLanguageManagementSuppliers$().pipe(
      take(1),
      catchError(
        ({ error }: ILanguageManagementSuppliersResponse): ObservableInput<ILanguageManagementSuppliersResponse> =>
          throwError(() => error)
      )
    );
  }

  getSupplierForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._languageManagementService.getSupplierForTranslation$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: ILanguageManagementResponse): ObservableInput<ILanguageManagementResponse> =>
          throwError(() => error)
      )
    );
  }

  putSupplierForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._languageManagementService.putSupplierForTranslation$(uuid, data).pipe(
      take(1),
      catchError(
        ({ error }: ILanguageManagementPutRequest): ObservableInput<ILanguageManagementPutRequest> =>
          throwError(() => error)
      )
    );
  }

  getListOfSupplyChains$(): Observable<ILanguageManagementSupplyChainListResponse> {
    return this._languageManagementService.getListOfSupplyCains$().pipe(
      take(1),
      catchError(
        ({
          error,
        }: ILanguageManagementSupplyChainListResponse): ObservableInput<ILanguageManagementSupplyChainListResponse> =>
          throwError(() => error)
      )
    );
  }
}
