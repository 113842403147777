import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ISuppCompany, ISuppCompanyResponse, ISuppManufactory } from '@app/core/interface/supp.interface';

import { DatePipe } from '@angular/common';
import { EditInfoComponent } from './edit-info/edit-info.component';
import { ImageEditDialogCloseData } from '@app/core/interface/confirmation-dialog.interface';
import { ImageEditPreviewComponent } from '@app/shared/components/image-edit-preview/image-edit-preview.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { SidenavService } from '@app/core/service/sidenav.service';
import { SiteDialogComponent } from './site-dialog/site-dialog.component';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { SupplierSitesTableComponent } from './supplier-sites-table/supplier-sites-table.component';
import { applyFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
  providers: [DatePipe],
})
export class CompanyInfoComponent implements OnInit {
  @ViewChild(SupplierSitesTableComponent)
  supplierSitesTableComponent!: SupplierSitesTableComponent;
  company: ISuppCompany = {
    name: '',
    manufactories: [],
    description: '',
    address: '',
    uuid: '',
    logo: '',
    supplier_unique_identifier: '',
    unique_identifier_type: '',
    info_validated: false,
    info_validated_on: 'initial',
  };
  mainAddress: ISuppManufactory | undefined;
  isDescriptionExpanded = false;
  hideDetails = true;
  showSeeMoreButton = false;

  selectedFile?: File;
  imageChangedEvent!: Event;
  previews: string[] = [];
  docURL = '';

  constructor(
    private _suppFacade: SuppSuppliersFacade,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private elementRef: ElementRef,
    public datepipe: DatePipe,
    private _sidenavService: SidenavService,
    private _facade: SuppSuppliersFacade
  ) {}
  ngOnInit(): void {
    this.getInfo();
  }
  getInfo(): void {
    this._suppFacade.getCompany().subscribe({
      next: this._setInfo.bind(this),
      error: this._error.bind(this),
    });
  }

  onEdit(fieldName: string, fieldValue: string, required: boolean, max?: number): void {
    const data = this.getPayload(fieldName, fieldValue, required, max);
    const dialogRef = this._dialog.open(EditInfoComponent, {
      data,

      width: '1160px',
      height: '768px',
      panelClass: ['padding-0', 'overflow-hidden-dialog'],
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(
      (
        updatedFieldValue: Array<{
          fieldName:
            | 'name'
            | 'description'
            | 'address'
            | 'supplier_unique_identifier'
            | 'unique_identifier_type'
            | 'logo';
          fieldValue: string;
        }>
      ) => {
        if (updatedFieldValue && updatedFieldValue.length) {
          updatedFieldValue.forEach(record => {
            if (record.fieldName === 'logo' || record.fieldName === 'address') return this.getInfo();
            this.company[record.fieldName] = record.fieldValue;
            setTimeout(() => {
              this.checkOverflow();
            }, 0);
          });
        }
      }
    );
  }
  getPayload(fieldName: string, fieldValue: string, required: boolean, max?: number): object {
    if (fieldName === 'address')
      return {
        fieldName: 'address',
        site: this.mainAddress,
      };
    if (fieldName === 'supplier_unique_identifier')
      return {
        fieldName,
        fieldValue,
        required,
        max,
        supplier_unique_identifier: this.company.supplier_unique_identifier,
        unique_identifier_type: this.company.unique_identifier_type,
      };
    return { fieldName, fieldValue, required, max };
  }
  addSite(): void {
    const dialogRef = this._dialog.open(SiteDialogComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
    });

    dialogRef.afterClosed().subscribe((result: ISuppManufactory | null) => {
      if (result) {
        this.getInfo();
      }
    });
  }
  toggleDescriptionExpansion(): void {
    this.isDescriptionExpanded = !this.isDescriptionExpanded;
  }

  applyFilter(event: Event): void {
    applyFilter(event, undefined, this.supplierSitesTableComponent.tableDataSource);
  }
  checkOverflow(): void {
    const element = this.elementRef.nativeElement.querySelector('.val-desc');
    if (!element) return;
    // Calculate the actual content height including padding and borders
    const contentHeight = element.scrollHeight;
    // lineheight 24 * 2(lines) 48
    const maxHeight = 48;
    this.showSeeMoreButton = contentHeight > maxHeight;
  }

  validate(): void {
    const currentDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this._suppFacade.updateCompanyInfo$({ info_validated: true, info_validated_on: currentDate }).subscribe({
      next: this.getInfo.bind(this),
      error: this._error.bind(this),
    });
  }

  private _setInfo(response: ISuppCompanyResponse): void {
    this.mainAddress = response.manufactories?.find(site => site.is_main_location);
    const sites = response.manufactories?.filter(site => !site.is_main_location);
    this.company = {
      ...this.company,
      ...response,
      address: this.mainAddress?.address ?? '',
      manufactories: sites,
      info_validated_on: response.info_validated_on,
    };
    setTimeout(() => {
      this._sidenavService.toggleSuppSidnavAvalability(response.info_validated);
      this.checkOverflow();
    }, 100);
  }
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  openImageEditDialg(): void {
    const event = this.imageChangedEvent;
    const dialogRef = this._dialog.open(ImageEditPreviewComponent, {
      data: {
        event,
        name: this.company.name,
        title: this.company.name,
        docUrl: this.company.logo,
      },
      width: '1160px',
      height: '786px',
      panelClass: 'position-relative',
    });

    dialogRef.afterClosed().subscribe((result: ImageEditDialogCloseData) => {
      if (result) {
        this.previews = [];
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target && e.target.result) {
            this.company['logo'] = e.target.result as string;
            this.previews.push(this.company['logo']);
          }
        };
        if (result && result.file) {
          this.selectedFile = result.file;
          reader.readAsDataURL(result.file);
        } else {
          this.selectedFile = undefined;
        }
        this.saveImage();
      }
    });
  }

  saveImage(): void {
    const payload = {
      uuid: this.company.uuid,
      logo: this.selectedFile ? this.selectedFile : '',
    } as ISuppCompany;

    this._facade.updateCompanyInfo$(payload).subscribe({
      next: this._setInfo.bind(this),
      error: this._error.bind(this),
    });
  }
}
