import { Component, OnInit } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-steps-overview',
  templateUrl: './steps-overview.component.html',
  styleUrls: ['./steps-overview.component.scss'],
})
export class StepsOverviewComponent implements OnInit {
  hideMenu = false;
  chartTitle = '';

  toUpdateChainStepsPercentage = 0;
  updatedChainStepsPercentage = 0;

  allSelectedChainSteps = 0;
  allSelectedUpdatedChainSteps = 0;
  allSelectedToUpdateChainSteps = 0;

  allSummChainSteps = 0;
  allSummUpdatedChainSteps = 0;
  allSummToUpdateChainSteps = 0;

  allUpdatedChainSteps = 0;
  allToUpdateChainSteps = 0;
  sumStepsOfUpdatedToUpdate = 0;

  allUpdatedBatchSteps = 0;
  allToUpdateBatchSteps = 0;
  sumBatchesOfUpdatedToUpdate = 0;

  stepTypes = [
    { value: this.translate.instant('supplier-dashboard.All steps') },
    { value: this.translate.instant('supplier-dashboard.Supply chain steps') },
    { value: this.translate.instant('supplier-dashboard.Batch chain steps') },
  ];
  selectedStepType: string = this.stepTypes[0].value;

  private _unsubscribe$: Subject<void> = new Subject();
  public updateSteps$: Subject<void> = new Subject();

  constructor(
    private _facade: SuppSuppliersFacade,
    private _snackBar: MatSnackBar,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this._getSuppDasboardStepCount();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  updateStepType(type: string) {
    this.selectedStepType = type;
    switch (type) {
      case this.stepTypes[0].value:
        this._setSelectedAsAllSteps();
        break;
      case this.stepTypes[1].value:
        this._setSelectedAsSupplyChainSteps();
        break;
      case this.stepTypes[2].value:
        this._setSelectedAsBatchChainSteps();
        break;
      default:
        this._setSelectedAsAllSteps();
        break;
    }
    this._calculateToPercent();
    this.updateSteps$.next();
  }

  ///////////////////////////////////// PRIVATE METHODS //////////////////////////////////////////

  private _setDefaultStepType(): void {
    if (this.sumStepsOfUpdatedToUpdate > 0 && this.sumBatchesOfUpdatedToUpdate === 0) {
      this.hideMenu = true;
      this.chartTitle = 'Supply chain steps';
      this._setSelectedAsSupplyChainSteps();
    }
    if (this.sumStepsOfUpdatedToUpdate === 0 && this.sumBatchesOfUpdatedToUpdate > 0) {
      this.hideMenu = true;
      this.chartTitle = 'Batch chain steps';
      this._setSelectedAsBatchChainSteps();
    }
    if (this.sumStepsOfUpdatedToUpdate > 0 && this.sumBatchesOfUpdatedToUpdate > 0) {
      this.hideMenu = false;
      this._setSelectedAsAllSteps();
    }
    if (this.sumStepsOfUpdatedToUpdate == 0 && this.sumBatchesOfUpdatedToUpdate == 0) {
      this.hideMenu = true;
      this.chartTitle = 'No steps available';
      this._setSelectedAsAllSteps();
    }
    this._calculateToPercent();
  }

  private _setSelectedAsAllSteps(): void {
    this.allSelectedChainSteps = this.allSummChainSteps;
    this.allSelectedUpdatedChainSteps = this.allSummUpdatedChainSteps;
    this.allSelectedToUpdateChainSteps = this.allSummToUpdateChainSteps;
  }

  private _setSelectedAsSupplyChainSteps(): void {
    this.allSelectedChainSteps = this.sumStepsOfUpdatedToUpdate;
    this.allSelectedUpdatedChainSteps = this.allUpdatedChainSteps;
    this.allSelectedToUpdateChainSteps = this.allToUpdateChainSteps;
  }

  private _setSelectedAsBatchChainSteps(): void {
    this.allSelectedChainSteps = this.sumBatchesOfUpdatedToUpdate;
    this.allSelectedUpdatedChainSteps = this.allUpdatedBatchSteps;
    this.allSelectedToUpdateChainSteps = this.allToUpdateBatchSteps;
  }

  private _getSuppDasboardStepCount() {
    this._facade.getSuppDashboardStepsCount$().subscribe({
      next: data => {
        this.allUpdatedBatchSteps = data.updated_batch_steps;
        this.allToUpdateBatchSteps = data.to_update_batch_steps;
        this.sumBatchesOfUpdatedToUpdate = data.updated_batch_steps + data.to_update_batch_steps;

        this.allUpdatedChainSteps = data.updated_chain_steps;
        this.allToUpdateChainSteps = data.to_update_chain_steps;
        this.sumStepsOfUpdatedToUpdate = data.updated_chain_steps + data.to_update_chain_steps;

        this.allSummUpdatedChainSteps = data.all_updated_steps;
        this.allSummToUpdateChainSteps = data.all_to_update_steps;
        this.allSummChainSteps = this.sumBatchesOfUpdatedToUpdate + this.sumStepsOfUpdatedToUpdate;

        this._setDefaultStepType();
      },
      error: this._error.bind(this),
    });
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackBar.open(err, 'OK');
    });
  }

  private _calculateToPercent(): void {
    if (this.allSelectedChainSteps === 0) {
      this.toUpdateChainStepsPercentage = this.updatedChainStepsPercentage = 0;
    } else {
      this.toUpdateChainStepsPercentage = (this.allSelectedToUpdateChainSteps / this.allSelectedChainSteps) * 100;
      this.updatedChainStepsPercentage = (this.allSelectedUpdatedChainSteps / this.allSelectedChainSteps) * 100;
    }
  }
}
