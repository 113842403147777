import { Component, Input, inject } from '@angular/core';
import {
  ILanguageManagement,
  ILanguageManagementCertificate,
  ILanguageManagementResponse,
} from '@app/core/interface/language-management.interface';

import { CertificatesTranslationDialogComponent } from '../certificates-translation-dialog/certificates-translation-dialog.component';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-certificate-translation-card',
  templateUrl: './certificate-translation-card.component.html',
  styleUrl: './certificate-translation-card.component.scss',
})
export class CertificateTranslationCardComponent {
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';
  @Input() certtificate!: ILanguageManagementCertificate;
  isMissing = true;

  readonly _dialog = inject(MatDialog);
  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _snackbarService = inject(SnackbarService);

  certificateDataFromBackend!: ILanguageManagementResponse;
  certificateDataForTranslate: ILanguageManagement[] = [];
  modelName!: string;

  constructor() {}

  manageTranslations(certificate: ILanguageManagementCertificate): void {
    this.getCertificateForTranslation(certificate);
  }

  getCertificateForTranslation(certificate: ILanguageManagementCertificate): void {
    this._languageManagementFacade.getCertificateForTranslation$(certificate.uuid).subscribe({
      next: data => this._getCertificateTranslateSuccess(data, certificate),
      error: this._error.bind(this),
    });
  }

  ////////////////////////////////////////Private Methods////////////////////////////////////////

  private _getCertificateTranslateSuccess(
    data: ILanguageManagementResponse,
    certificate: ILanguageManagementCertificate
  ): void {
    this.certificateDataFromBackend = data;

    this.certificateDataForTranslate = [
      {
        ...this.certificateDataFromBackend.default_translation,
        uuid: 'default',
        count_translated_fields: 0,
      },
      ...this.certificateDataFromBackend.translations,
    ];
    this.modelName = 'Certificate data';

    this._openDialog(certificate);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  private _openDialog(certificate: ILanguageManagementCertificate): void {
    this._dialog.open(CertificatesTranslationDialogComponent, {
      width: '1160px',
      height: '660px',
      panelClass: 'padding-0',
      data: {
        certificate: certificate,
        certificateDataForTranslate: this.certificateDataForTranslate,
        modelName: this.modelName,
      },
    });
  }
}
