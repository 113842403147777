import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  IProjectLookUp,
  IProjectLookUpResponse,
  IProjectResponse,
  ISubproject,
  ISubprojectResponse,
} from '@app/core/interface/projects.interface';
import { Observable, Subscription, map, startWith, takeWhile } from 'rxjs';

import { CountryList } from '@app/core/utils/country-list';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { ICountry } from '@app/core/interface/suppliers.interface';
import { IProduct } from '@app/core/interface/products.interface';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { ProjectsFacade } from '@app/core/facade/projects.facade';
import { ProjectsGroup } from '../projects.group';
import { SetCountry } from '@app/core/utils/country';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
  providers: [ProjectsGroup],
})
export class ProjectFormComponent implements OnInit, OnDestroy {
  @Input() isSubproject!: boolean;
  @Input() projectId!: string;
  @Input() subprojectId!: string;
  @Input() is_editing!: boolean | undefined;
  @Input() from_project!: boolean | undefined;
  @Output() saveSuccess: EventEmitter<IProjectResponse | ISubprojectResponse> = new EventEmitter<
    IProjectResponse | ISubprojectResponse
  >();
  @Output() setProducts: EventEmitter<Array<Partial<IProduct>>> = new EventEmitter<Array<Partial<IProduct>>>();

  @Input() form!: UntypedFormGroup;
  countryList = CountryList.isoCountries;
  filteredCountries!: Observable<Array<ICountry>>;
  minEnd!: Date;
  subscription!: Subscription;
  create = true;
  viewMode: boolean | null = null;
  statuses = ['NOT STARTED', 'IN PROGRESS', 'COMPLETED'];
  projectResponse!: IProjectResponse | ISubprojectResponse;
  projectTitle: 'Project' | 'Sub-project' = 'Project';
  subprojects: ISubproject[] = [];
  projectStatusList!: Array<IProjectLookUp>;
  products: Partial<IProduct>[] = [];
  alive = true;

  constructor(
    private _snackbarService: SnackbarService,
    private _projectsFacade: ProjectsFacade,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private dateAdapter: DateAdapter<Date>
  ) {
    dateAdapter.setLocale('en-in'); // DD/MM/YYYY
  }
  /**
   * On OnInit we are setting filtered countries as observable and subscribing to estimated_start_date
   *  in orger to enable / disable estimated_end_date and set minDate
   */
  ngOnInit(): void {
    this.getProjectStatus();
    if (this.isSubproject) this.projectTitle = 'Sub-project';
    if ((this.isSubproject && this.subprojectId) || (!this.isSubproject && this.projectId)) {
      this.create = false;
      // this.setViewMode();
      // this.getProject();
    }
    this.filteredCountries = this.form.controls['country'].valueChanges.pipe(
      takeWhile(() => this.alive),
      startWith(''),
      map(value => this._filter(value || ''))
    );

    this.form.controls['estimated_start_date'].valueChanges.pipe(takeWhile(() => this.alive)).subscribe(date => {
      this.minEnd = date;
      date ? this.form.controls['estimated_end_date'].enable() : this.form.controls['estimated_end_date'].disable();
      if (!date && this.form.controls['estimated_end_date'].value) {
        this.form.controls['estimated_end_date'].patchValue(null);
      }
    });
    this.form.controls['estimated_start_date'].value
      ? this.form.controls['estimated_end_date'].enable()
      : this.form.controls['estimated_end_date'].disable();
  }
  /**
   * On Destroy we are setting alive to false to cut runing subscriptions from this component
   */
  ngOnDestroy(): void {
    this.alive = false;
  }
  /**
   * Filters countries on user typing or selecting dropdown
   * @param value value entered by user
   * @returns filtered list of countries
   */
  private _filter(value: string | ICountry): ICountry[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
    const filteredList = this.countryList.filter(country => country.name.toLowerCase().includes(filterValue));
    return filteredList;
  }

  private _getStatusList(data: IProjectLookUpResponse): void {
    if (data.results) this.projectStatusList = data.results;
  }

  /**
   * Hadles HTTP error desplaying message from backend
   * @param error
   */
  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  getProjectStatus() {
    this._projectsFacade.getProjectsStatus$().subscribe({
      next: this._getStatusList.bind(this),
      error: this._error.bind(this),
    });
  }

  /**
   * Shows only name as display value and keeps hole obj as actual value (code is used for requiest and name for display)
   * @param subject ICountry from country dropdown
   * @returns country name for display
   */
  displayFn(subject: ICountry | null): string {
    return subject?.name ?? '';
  }

  /**
   * If it's not create project sets viewMode on and  if it's createnavigates back
   */
  // cancel() {
  //   this.setViewMode();
  //   this.resetForm();
  // }

  /**
   * Sets View Mode on and disable location
   */
  setViewMode() {
    queueMicrotask(() => {
      this.viewMode = true;
    });
    this.form.controls['is_location_specific'].disable();
  }

  /**
   * On fileData trigered we are putting the file in the image formcontrol
   * @param file image that needs to be put in form
   */
  saveImageData(file: File): void {
    if (this.isSubproject) {
      this.form.get('logo')?.setValue(file);
    } else {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = evt => {
        const base64 = evt.target?.result;
        this.form.get('logo')?.setValue(base64);
      };
    }
    this.form.get('logo')?.markAsDirty();
  }

  /**
   * Sets project on successfull getting the project
   * @param response from get project
   */
  setProject(response: IProjectResponse | ISubprojectResponse) {
    if (this.isSubproject) {
      this.products =
        response.products.filter(prod => prod.is_active) ?? response.products.filter(prod => prod.is_active);
    } else {
      this.products =
        response.project_products.filter(prod => prod.is_active) ?? response.products.filter(prod => prod.is_active);
    }
    this.setProducts.emit(this.products);
    this.projectResponse = response;
    if (response.subprojects) this.subprojects = [...response.subprojects];
    this.form.patchValue({
      address: response.address,
      name: response.name,
      estimated_start_date: response.estimated_start_date,
      estimated_end_date: response.estimated_end_date,
      city: response.city,
      status: response.status?.uuid,
      description: response.description,
      statezipcode: response.statezipcode,
      logo: response.logo,
      country: SetCountry(this.countryList, response.country),
      project: this.projectId,
      is_location_specific: !!response.address || !!response.country || !!response.city || !!response.statezipcode,
    });
    response.country && SetCountry(this.countryList, response.country);
  }
}
