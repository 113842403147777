import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFactory, ISupplier } from '@app/core/interface/suppliers.interface';
import {
  IIngredient,
  IIngredientCategory,
  IIngredientCategoryResponse,
} from '@app/core/interface/ingredient.interface';

import { CreateComponentGroup } from './create-component.group';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IngredientFacade } from '@app/core/facade/ingredient.facade';
import { NotificationType } from '@app/core/constants';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-add-component',
  templateUrl: './create-add-component.component.html',
  styleUrls: ['./create-add-component.component.scss'],
  providers: [CreateComponentGroup],
})
export class CreateAddComponentComponent implements OnInit {
  @Input() supplier!: ISupplier;
  @Input() supplierSite!: IFactory;
  @Input() productUuid!: string;
  @Output() closeDialog = new EventEmitter<void>();
  form: UntypedFormGroup;
  categories!: IIngredientCategory[];

  constructor(
    private _group: CreateComponentGroup,
    private _ingredientFacade: IngredientFacade,
    private _productsFacade: ProductsFacade,
    private _snackbarService: SnackbarService
  ) {
    this.form = this._group.createComponentForm;
  }

  ngOnInit(): void {
    this._ingredientFacade.getIngredientCategories$('True').subscribe({
      next: this._getCategoriesSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  onCreateComponent() {
    const changedFormValues: Partial<IIngredient> = GetDirtyValues(this.form);

    this._ingredientFacade
      .createIngredient$({
        ...changedFormValues,
        supplier: this.supplier.uuid ?? '',
        manufactory: this.supplierSite.uuid ?? '',
        manufactoring_location: this.supplierSite.uuid
          ? `${this.supplierSite.name ?? ''} ${this.supplierSite.region ?? ''} ${this.supplierSite.city ?? ''} ${
              this.supplierSite.country ?? ''
            }`
          : '',
      })
      .subscribe({
        next: this._createSupplierComponentSuccess.bind(this),
        error: this._error.bind(this),
      });
  }

  private _createSupplierComponentSuccess(data: IIngredient): void {
    this._productsFacade.addComponentToProduct$(this.productUuid, data.uuid).subscribe({
      next: this._addComponentSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _addComponentSuccess() {
    this._snackbarService.openTypeSnackbar(
      `Component successfuly created and added to the product.`,
      NotificationType.success
    );
    this.closeDialog.emit();
  }

  private _getCategoriesSuccess(response: IIngredientCategoryResponse) {
    if (response && response.results.length) {
      this.categories = response.results;
    }
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
