import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { Observable } from 'rxjs';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import {
  ILanguageManagementCertificate,
  ILanguageManagementCertificatesResponse,
} from '@app/core/interface/language-management.interface';
import { LanguageManagementService } from '@app/core/service/language-management.service';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-certificate-translations',
  templateUrl: './certificate-translations.component.html',
  styleUrl: './certificate-translations.component.scss',
  providers: [LanguageManagementFacade],
})
export class CertificateTranslationsComponent implements OnInit {
  isMissing: boolean = true;
  certificates!: ILanguageManagementCertificate[];
  cardDataSource = new MatTableDataSource<ILanguageManagementCertificate>();
  certificatesObservable$!: Observable<ILanguageManagementCertificate[]>;

  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _languageManagementService = inject(LanguageManagementService);
  readonly _snackbarService = inject(SnackbarService);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnInit(): void {
    this._getLanguageManagementCertificates();
  }

  ngAfterViewInit() {
    this.cardDataSource.paginator = this.paginator;
    this.paginator.pageSize = 8;
  }
  onToggleChange(event: MatButtonToggleChange) {
    this.isMissing = event.value === 'missing';
  }

  applyFilter(event: Event): void {
    applyFilter(event, this.cardDataSource);
  }

  //////////////////////////////////////////////////Private methods///////////////////////////////////////////////

  private _getLanguageManagementCertificates(): void {
    this._languageManagementFacade.getLanguageManagementCertificates$().subscribe({
      next: this._getLanguageManagementCertificatesSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _getLanguageManagementCertificatesSuccess(data: ILanguageManagementCertificatesResponse): void {
    if (data && data.results.length) {
      this.certificates = data.results;
      this.cardDataSource.data = data.results;
      this.certificatesObservable$ = this.cardDataSource.connect();
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}
