import { Component, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import {
  ILanguageManagement,
  ILanguageManagementCertificate,
  ILanguageManagementPutRequest,
} from '@app/core/interface/language-management.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguageManagementTableComponent } from '@app/shared/components/language-management-table/language-management-table.component';
import { NotificationType } from '@app/core/constants';

@Component({
  selector: 'app-certificates-translation-dialog',
  templateUrl: './certificates-translation-dialog.component.html',
  styleUrl: './certificates-translation-dialog.component.scss',
  providers: [LanguageManagementFacade],
})
export class CertificatesTranslationDialogComponent implements OnInit {
  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _snackbarService = inject(SnackbarService);
  readonly _dialogRef = inject(MatDialogRef<CertificatesTranslationDialogComponent>);
  @ViewChild('childComponent') childComponent!: LanguageManagementTableComponent;
  public certificateDataForTranslate: ILanguageManagement[] = [];
  certificate!: ILanguageManagementCertificate;
  public modelName: string = '';
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: {
      certificate: ILanguageManagementCertificate;
      certificateDataForTranslate: ILanguageManagement[];
      modelName: string;
    }
  ) {}
  ngOnInit(): void {
    this.modelName = this._data.modelName;
    this.certificate = this._data.certificate;
    this.certificateDataForTranslate = this._data.certificateDataForTranslate;
  }
  handleLanguageManagementArrayChange(arrayForPut: ILanguageManagementPutRequest[]) {
    this._languageManagementFacade.putCertificateForTranslation$(this.certificate.uuid, arrayForPut).subscribe({
      next: () => {
        this._snackbarService.openTypeSnackbar('Translations saved successfully ', NotificationType.success);
      },
      error: error => {
        this._snackbarService.openTypeSnackbar(error.error.detail[0], NotificationType.error);
      },
    });
  }

  onCancel(): void {
    this._dialogRef.close();
  }

  onSaveTranslations(): void {
    this.childComponent.sendToParent();
    this._dialogRef.close();
  }
}
