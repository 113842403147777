import { Component, OnInit } from '@angular/core';
import { ISuppCompany, ISuppCompanyResponse } from '@app/core/interface/supp.interface';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company-info-overview',
  templateUrl: './company-info-overview.component.html',
  styleUrls: ['./company-info-overview.component.scss'],
})
export class CompanyInfoOverviewComponent implements OnInit {
  company: ISuppCompany = {
    name: '',
    description: '',
    uuid: '',
    manufactories: [],
    supplier_unique_identifier: '',
    logo: '',
    unique_identifier_type: '',
    address: '',
    info_validated: false,
    info_validated_on: null,
  };
  infoProgress = 0;
  isCompleted = false;
  company_address: string[] = [];
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _suppFacade: SuppSuppliersFacade,
    private _snackBar: MatSnackBar,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this._getInfo();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ///////////////////////////////////// PRIVATE METHODS //////////////////////////////////////////

  private _getInfo() {
    this._suppFacade.getCompany().subscribe({
      next: this._setInfo.bind(this),
      error: this._error.bind(this),
    });
  }

  private _setInfo(response: ISuppCompanyResponse): void {
    this.company = { ...this.company, ...response };
    if (this.company.manufactories?.length) {
      const mainManufactory = this.company.manufactories.find(x => x.is_main_location);

      if (mainManufactory) {
        if (mainManufactory.country) this.company_address.push(mainManufactory.country);
        if (mainManufactory.region) this.company_address.push(mainManufactory.region);
        if (mainManufactory.city) this.company_address.push(mainManufactory.city);
        if (mainManufactory.address) this.company_address.push(mainManufactory.address);
      }
    }
    if (this.company.logo) {
      this.infoProgress += 50;
    }
    if (this.company.description) {
      this.infoProgress += 25;
    }
    if (this.company_address.length) {
      this.infoProgress += 25;
    }
    this.isCompleted = this.infoProgress < 100 ? false : true;
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackBar.open(err[0], 'OK'));
  }
}
