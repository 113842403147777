import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StepsFacade } from '@app/core/facade/steps.facade';
import { IEmailDialogData } from '@app/core/interface/email.interface';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss'],
  providers: [StepsFacade],
})
export class EmailDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IEmailDialogData,
    public dialogRef: MatDialogRef<EmailDialogComponent>,
    private _facade: StepsFacade,
    private _snackbarService: SnackbarService
  ) {}

  send(): void {
    this._facade.sendEmailToSupplier$(this.data.stepUuid).subscribe({
      next: () => this.dialogRef.close(this.data.supplier.name),
      error: this._error.bind(this),
    });
  }

  /**
   *
   * @param error hadles HTTP error desplaying message from backend
   */

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
