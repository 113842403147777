<div class="dialog-wrapper d-flex flex-column">
  <!-- Breadcrumb -->
  <div class="breadcrump d-flex align-items-center">
    <div class="brand-management-crumb d-flex align-items-center cursor-pointer" mat-dialog-close>
      <app-custom-icon
        class="icon-arrow-l-circle"
        icon_category="arrows"
        icon_name="arrow-circle-left-short"
      ></app-custom-icon>
      <h6>
        <strong>{{ 'Brand Management' | translate }}</strong>
      </h6>
    </div>
    <div class="update-brand-crumb d-flex align-items-center">
      <app-custom-icon icon_category="arrows" icon_name="arrow-right-2"></app-custom-icon>
      <h4>
        <strong>{{ 'Update Brand Details' | translate }}</strong>
      </h4>
    </div>
  </div>
  <div class="horizontal-line"></div>
  <!-- Content -->
  <mat-dialog-content class="m-0 flex-grow-1">
    <!-- <h6>
      <strong>{{ 'Update Brand Details' | translate }}</strong>
    </h6> -->
    <form [formGroup]="form">
      <div class="banner-section">
        <div *ngIf="!form.get('banner')?.value" class="banner-placeholder d-flex justify-content-end align-items-start">
          <img src="assets/images/banner-placeholder.png" alt="" />
          <div class="banner-placeholder-text">
            <h5>
              <strong>{{ 'Please upload a banner' | translate }}</strong>
            </h5>
            <p>
              {{ 'For best viewing experience we suggest uploading \n the photo in 1920x400px format.' | translate }}
            </p>
          </div>
        </div>
        <input #uploadBanner style="display: none" type="file" accept="image/*" (change)="saveBannerData($event)" />
        <div *ngIf="bannerPreview" class="banner-image" [style.background-image]="'url(' + bannerPreview + ')'"></div>
        <app-rounded-button
          color="primary"
          button_text="{{ bannerPreview ? 'Replace Photo' : ('Upload Photo' | translate) }}"
          size="md"
          icon_category="{{ bannerPreview ? 'grid' : 'essential' }}"
          icon_name="{{ bannerPreview ? 'convertshape' : 'upload' }}"
          [icon_right]="true"
          (click)="uploadBanner.click()"
        >
        </app-rounded-button>
      </div>
      <div class="below-banner d-flex">
        <app-file-upload
          [placeholder]="selectedBrand.logo ? selectedBrand.logo : ('brand-management.Logo' | translate)"
          [iconType]="'add_photo_alternate'"
          (fileData)="saveLogoData($event)"
          [imageUrl]="selectedBrand.logo"
          [status]="form.controls['logo'].status"
          [uploadImageText]="'Upload logo' | translate"
        >
        </app-file-upload>
        <div class="form-fields col d-flex flex-wrap align-content-start">
          <mat-form-field
            class="input-primary col-6"
            appearance="outline"
            matTooltip="{{
              'The brand name cannot be edited directly. Please contact support for assistance with any brand name changes.'
                | translate
            }}"
            matTooltipClass="custom-tooltip-center"
            matTooltipPosition="above"
          >
            <mat-label>{{ 'Brand Name' | translate }}</mat-label>
            <input matInput formControlName="name" placeholder="{{ 'Brand Name*' | translate }}" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="dropdown-primary col-6">
            <mat-select
              panelClass="dropdown-primary"
              formControlName="domain_activity"
              placeholder="{{ 'Industry' | translate }}"
            >
              <mat-option *ngFor="let domain of domains" [value]="domain.uuid">
                {{ domain.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="input-primary textarea text-area col-6" appearance="outline">
            <mat-label>{{ 'Brand Description' | translate }}</mat-label>
            <!-- <textarea
              matInput
              formControlName="summary"
              placeholder="{{ 'Enter summary' | translate }}"
              rows="3"
              appTextareaAutoResize
            ></textarea> -->
            <div class="wrp">
              <app-rs-rte
                formControlName="description"
                [c]="$any(form.controls['description'])"
                [placeholder]="'Brand Description' | translate"
              ></app-rs-rte>
            </div>
          </div>
          <div class="input-primary textarea rs-rte-wrap text-area col-6" appearance="outline">
            <mat-label>{{ 'Traceability Commitment' | translate }}*</mat-label>
            <div class="wrp">
              <app-rs-rte
                formControlName="traceability_commitment"
                [c]="$any(form.controls['traceability_commitment'])"
                [placeholder]="'Traceability Commitment' | translate"
              ></app-rs-rte>
            </div>
          </div>
          <div class="url-note col-6 d-flex">
            <img src="assets/logos/symbol-logo-dark.svg" alt="" />
            <p class="m-0">{{ 'Please add you Brand Website URL or Social Links to your Brand' | translate }}</p>
          </div>
          <mat-form-field class="input-primary col-6 field-ext-url" appearance="outline">
            <mat-label>{{ 'External URL' | translate }}</mat-label>
            <input matInput formControlName="external_url" placeholder="{{ 'External URL' | translate }}" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <!-- Action buttons -->
  <mat-dialog-actions align="end">
    <app-rounded-button
      class="btn-w-180px"
      color="tetriary-link underlined"
      button_text="{{ 'Cancel' | translate }}"
      size="md"
      mat-dialog-close
    >
    </app-rounded-button>
    <app-rounded-button
      class="btn-w-180px"
      color="turquoise"
      button_text="{{ 'Update Brand' | translate }}"
      size="md"
      [icon_right]="true"
      icon_category="buildings"
      icon_name="buildings"
      [disabled]="form.invalid || form.pristine"
      (click)="updateBrand()"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
