import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ICertificateCategoriesResponse,
  ICertificateDomain,
  ICertificateSubject,
  ICertification,
  ICertificationResponse,
} from '@app/core/interface/certificates.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { AfterViewInit } from '@angular/core';
import { CertificateViewDetailsComponent } from '@app/shared/components/certificate-view-details/certificate-view-details.component';
import { CertificatesFacade } from '@app/core/facade/certificates.facade';
import { CreateCertificateDialogComponent } from './create-certificate-dialog/create-certificate-dialog.component';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-certificates-dashboard',
  templateUrl: './certificates-dashboard.component.html',
  styleUrls: ['./certificates-dashboard.component.scss'],
  providers: [CertificatesFacade],
})
export class CertificatesDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  domains: Array<ICertificateDomain> = [];
  categories$!: Observable<ICertificateCategoriesResponse>;
  certificates: Array<ICertification> = [];
  certificatesFullList: Array<ICertification> = [];
  certificatesObservable$!: Observable<ICertification[]>;
  certificationObservable$!: Observable<ICertificationResponse[]>;
  dataSource: MatTableDataSource<ICertification> = new MatTableDataSource<ICertification>();

  cardDataSource = new MatTableDataSource<ICertification>();
  displayedColumns = [
    'name',
    'certificated_subject',
    'area',
    'validity_start',
    'validity_end',
    'is_expired',
    'actions',
  ];
  selectedCategory!: string;

  constructor(
    private _certificatesFacade: CertificatesFacade,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}
  /**
   * On OnInit lifecycle hook we are getting the certificates
   */
  ngOnInit(): void {
    this.getCertification();
    this.categories$ = this._certificatesFacade.getCertificatesCategories$();
  }

  getCertification(): void {
    this._certificatesFacade.getCertifications$('name', 999999).subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.cardDataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item['certificate'].name.toUpperCase();
        case 'certificated_subject':
          return item['certificate_subject'].name.toUpperCase();
        case 'area': {
          if (item['certificate'].domain) {
            return item['certificate'].domain.name.toUpperCase();
          }
          return 0;
        }

        default:
          return item[property as keyof ICertification] as string;
      }
    };
  }

  viewChange(value: string): void {
    if (value === 'tableView') {
      this._changeDetectorRef.detectChanges();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'name':
            return item['certificate'].name.toUpperCase();
          case 'certificated_subject':
            return item['certificate_subject'].name.toUpperCase();
          case 'area': {
            if (item['certificate'].domain) {
              return item['certificate'].domain.name.toUpperCase();
            }
            return 0;
          }

          default:
            return item[property as keyof ICertification] as string;
        }
      };
    } else {
      this.cardDataSource.paginator = this.paginator;
    }
  }

  /**
   *
   * @param id is used for deliting certificate
   * @param name is used to show warnning message before deleting certificate
   */
  deleteCertificate(id: string, name: string) {
    const deleteUuid = id;

    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete certificate ${name}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._certificatesFacade.deleteCertifications$(deleteUuid).subscribe({
          next: this.getCertification.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }
  /**
   *
   * @param data after successful getting the data we are setting certificates and set them as Table dataSource data
   */
  private _success(data: ICertificationResponse) {
    this.certificates = data.results;
    this.certificatesFullList = [...this.certificates];
    this.dataSource.data = this.certificates;
    this.cardDataSource.data = this.certificates;
    this._changeDetectorRef.detectChanges();
    this.paginator.pageSize = 12;
    this.dataSource.sort = this.sort;
    this.certificatesObservable$ = this.cardDataSource.connect();
    this.dataSource.paginator = this.paginator;
    this.cardDataSource.paginator = this.paginator;
  }
  /**
   *
   * @param error hadles HTTP error desplaying message from backend
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  /**
   * Filtering data on category(dropdown) change
   * @param event dropdown change value
   */
  categoryChange(event: MatSelectChange) {
    this.selectedCategory = event.value;
    this.certificates = [];
    event.value
      ? this.certificatesFullList.forEach(
          cert => cert.certificate.category.name === event.value && this.certificates.push(cert)
        )
      : (this.certificates = this.certificatesFullList);
    // this.handleSortData(this.certificates);
    this.dataSource.data = this.certificates;
    this.cardDataSource.data = this.certificates;
  }

  onCreateEditCertificate(element: ICertification | null) {
    const dialogRef = this._dialog.open(CreateCertificateDialogComponent, {
      width: '1160px',
      height: '90vh',
      panelClass: 'padding-0',
      data: element,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getCertification();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.cardDataSource) {
      this.cardDataSource.disconnect();
    }
  }

  /**
   * In order to view file we are oppening common dialog passing the URL and name as params
   * @param url file url
   * @param name file name
   */
  viewCertificate(certificate_subject: ICertificateSubject, certificate: ICertification, url: string, name?: string) {
    this._dialog.open(CertificateViewDetailsComponent, {
      width: '90%',
      maxHeight: '90%',
      data: {
        url,
        name,
        mimetype: name,
        certificate,
        productName: certificate_subject?.name,
        logo: certificate_subject?.image,
      },
    });
  }

  onQuickFilterChanged(event: Event): void {
    const filterValue: string = (event.target as HTMLInputElement).value.toLowerCase().trim();

    // Filter certificates based on multiple parameters for table view and card view
    this.dataSource.data = this.certificatesFullList.filter(element => this.applyFilter(element, filterValue));
    this.cardDataSource.data = this.certificatesFullList.filter(element => this.applyFilter(element, filterValue));
  }

  applyFilter(element: ICertification, filterValue: string): boolean {
    const certificateType = element.certificate.name.toLowerCase();
    const certificatedSubjectName = element.certificate_subject?.name?.toLowerCase();
    const areaName = element.certificate.domain?.name?.toLowerCase();
    const certificator = element.certificator.toLowerCase();

    // Check if any of the properties contain the filter value
    return (
      certificateType.includes(filterValue) ||
      (certificatedSubjectName && certificatedSubjectName.includes(filterValue)) ||
      (areaName && areaName.includes(filterValue)) ||
      certificator.includes(filterValue) ||
      false
    );
  }
}
