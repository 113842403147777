import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AddDirectSupplierGroup } from './add-direct-supplier.group';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguagesFacade } from '@app/core/facade/languages.facade';
import { ILanguage, ILanguagesResponse } from '@app/core/interface/languages.interface';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { ISuppDirectSupplier, ISuppStepDirectSupplier } from '@app/core/interface/supp.interface';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-add-direct-supplier',
  templateUrl: './add-direct-supplier.component.html',
  styleUrls: ['./add-direct-supplier.component.scss'],
  providers: [AddDirectSupplierGroup],
})
export class AddDirectSupplierComponent implements OnInit {
  @ViewChild('supplierRoleInfoIcon') supplierRoleInfoIconEl!: ElementRef<HTMLElement>;
  form!: UntypedFormGroup;
  showSupplierRoleTooltip = false;
  supplierRoleTooltipXY: { x: string; y: string } = { x: '0px', y: '0px' };
  languages: ILanguage[] = [];
  lang?: ILanguage;
  contactLanguageTooltip =
    "supply-chain-step.Please choose the default language of the selected contact in which the tool can communicate with him in order to continue build the product's supply chain";
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { step_uuid: string; dir_supplier: ISuppDirectSupplier | ISuppStepDirectSupplier },
    public dialogRef: MatDialogRef<AddDirectSupplierComponent>,
    private _group: AddDirectSupplierGroup,
    private _languagesFacade: LanguagesFacade,
    private _snackbarService: SnackbarService,
    private _suppSupplierFacade: SuppSuppliersFacade
  ) {
    this.dialogRef.disableClose = true;
    this.form = this._group.addDirectSupplier;
  }

  ngOnInit(): void {
    this._languagesFacade.getLanguages$().subscribe({
      next: this._setLanguagesList.bind(this),
      error: this._error.bind(this),
    });

    if (this.data.dir_supplier) {
      const dirSupplier: ISuppDirectSupplier | ISuppStepDirectSupplier = this.data.dir_supplier;
      this.form.controls['name'].disable();
      const is_step_supplier = !!dirSupplier?.step_supplier;
      this.lang = <ILanguage>dirSupplier.step_supplier?.language_prefered ?? <ILanguage>dirSupplier.language_prefered;
      this.form.patchValue({
        name: dirSupplier.step_supplier?.name ?? dirSupplier.name,
        role: is_step_supplier ? dirSupplier.step_supplier?.role : dirSupplier.role,
        email: dirSupplier.email,
        language_prefered: this.lang?.uuid,
      });
    }
  }

  onAddDirectSupplier() {
    const changedFormValues: Partial<ISuppDirectSupplier> = GetDirtyValues(this.form);

    if (this.data.dir_supplier) {
      this._suppSupplierFacade
        .editDirectSupplier$(
          changedFormValues,
          this.data.step_uuid,
          this.data.dir_supplier.step_supplier ? this.data.dir_supplier.step_supplier.uuid : this.data.dir_supplier.uuid
        )
        .subscribe({
          next: this._addDirectSupplierSuccess.bind(this),
          error: this._error.bind(this),
        });
    } else {
      this._suppSupplierFacade.addDirectSupplier$(changedFormValues, this.data.step_uuid).subscribe({
        next: this._addDirectSupplierSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  onHoverSupplierRoleInfo(show: boolean) {
    if (show) {
      this.supplierRoleTooltipXY.x = (this.supplierRoleInfoIconEl.nativeElement.offsetLeft - 118).toString() + 'px';
      this.supplierRoleTooltipXY.y = (this.supplierRoleInfoIconEl.nativeElement.offsetTop - 130).toString() + 'px';
      this.showSupplierRoleTooltip = true;
    } else {
      this.showSupplierRoleTooltip = false;
    }
  }

  private _setLanguagesList(data: ILanguagesResponse): void {
    this.languages = data.results;
    this.lang &&
      this.form.patchValue({
        language_prefered: this.languages.find(lng => lng.uuid === (<ILanguage>this.lang).uuid)?.uuid,
      });
  }

  private _addDirectSupplierSuccess(response: ISuppDirectSupplier): void {
    this._snackbarService.openTypeSnackbar(
      ` Direct supplier ${this.data.dir_supplier ? 'saved.' : 'added.'}`,
      NotificationType.success
    );
    this.dialogRef.close(response);
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }
}
