import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IDomain, IDomainsResponse } from '@app/core/interface/register.interface';
import { BrandManagementGroup } from '../brand-management.group';
import { RegisterFacade } from '@app/core/facade/register.facade';
import { IBrandListResult, IBrandsListResponse } from '@app/core/interface/brands.interface';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-update-brand-details-dialog',
  templateUrl: './update-brand-details-dialog.component.html',
  styleUrls: ['./update-brand-details-dialog.component.scss'],
  providers: [BrandManagementGroup, RegisterFacade, BrandsFacade],
})
export class UpdateBrandDetailsDialogComponent implements OnInit {
  form: UntypedFormGroup;
  domains: IDomain[] = [];
  bannerPreview = '';

  constructor(
    private _group: BrandManagementGroup,
    private _registerFacade: RegisterFacade,
    private _brandsFacade: BrandsFacade,
    private _snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public selectedBrand: IBrandListResult,
    public dialogRef: MatDialogRef<UpdateBrandDetailsDialogComponent>
  ) {
    this.form = this._group.updateBrandForm;
    this.form.get('name')?.disable();
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this._registerFacade.getDomains$().subscribe({
      next: this._setdomains.bind(this),
      error: this._error.bind(this),
    });

    this._setFormForSelectedBrand();
  }

  saveLogoData(file: File): void {
    this.form.get('logo')?.setValue(file);
    this.form.get('logo')?.markAsDirty();
  }

  saveBannerData(event: Event) {
    const fileList = (<HTMLInputElement>event.target).files;
    if (fileList) {
      const file = fileList[0];
      this.form.get('banner')?.setValue(file);
      this.form.get('banner')?.markAsDirty();

      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && e.target.result) {
          this.bannerPreview = e.target.result as string;
        }
      };

      reader.readAsDataURL(file);
    }
  }

  updateBrand(): void {
    const changedFormValues: Partial<IBrandListResult> = GetDirtyValues(this.form);
    if (this.form.valid && Object.keys(changedFormValues).length) {
      this._brandsFacade.updateBrand$(changedFormValues, this.selectedBrand.uuid).subscribe({
        next: this._getUpdateSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _setFormForSelectedBrand(): void {
    this.form.patchValue({
      banner: this.selectedBrand.banner,
      logo: this.selectedBrand.logo,
      name: this.selectedBrand.name,
      domain_activity: this.selectedBrand.domain_activity?.uuid,
      traceability_commitment: this.selectedBrand.traceability_commitment,
      description: this.selectedBrand.description,
      external_url: this.selectedBrand.external_url,
    });

    if (this.selectedBrand.banner) this.bannerPreview = this.selectedBrand.banner;
  }

  private _setdomains(data: IDomainsResponse): void {
    this.domains = data.results;
  }

  private _getUpdateSuccess(result: IBrandsListResponse): void {
    this._snackbarService.openTypeSnackbar(`Brand successfully updated.`, NotificationType.success);
    this.dialogRef.close(result.results.find(x => x.uuid === this.selectedBrand.uuid));
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }
}
