import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input } from '@angular/core';
import { ICreateProductErrorResponse, ICreateProductForm } from '@app/core/interface/register.interface';

import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IProduct } from '@app/core/interface/products.interface';
import { MatStepper } from '@angular/material/stepper';
import { NotificationType } from '@app/core/constants';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { RegisterFacade } from '@app/core/facade/register.facade';
import { RegisterGroup } from '../../register.group';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-product-new',
  templateUrl: './create-product-new.component.html',
  styleUrls: ['./create-product-new.component.scss'],
  providers: [RegisterFacade, ProductsFacade, RegisterGroup],
})
export class CreateProductNewComponent {
  @Input() stepper!: MatStepper;
  @Input() cloneProduct!: IProduct;
  @Input() mode: 'duplicate' | 'edit' | undefined;
  product!: IProduct;
  productId: string | null;
  form!: UntypedFormGroup;
  isRegisterPage!: boolean;

  constructor(
    private _regGrup: RegisterGroup,
    private _facade: RegisterFacade,
    private _productsFacade: ProductsFacade,
    private _snackbarService: SnackbarService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.form = this._regGrup.createProductForm;
    this.productId = null;
    this.isRegisterPage = this._router.url === '/register/complete-register';
    if (this._route.snapshot.paramMap.get('id')) {
      this.productId = this._route.snapshot.paramMap.get('id');
    }
  }
  /**
   * In OnInit if there is a productId or product to clone we are using id to get the product
   */
  ngOnInit(): void {
    this.productId && this._getProduct(this.productId);
    this.cloneProduct && this._getProduct(this.cloneProduct.uuid);
  }
  /**
   * OnDestroy we are reseting the form
   */
  ngOnDestroy() {
    this.form.reset();
  }

  /**
   * On fileData trigered we are putting the file in the image formcontrol
   * @param file image that needs to be put in form
   */
  saveImageData(file: File): void {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = evt => {
      const base64 = evt.target?.result;
      this.form.get('image')?.setValue(base64);
    };
    this.form.get('image')?.markAsDirty();
  }

  /**
   * Sends API with form to create product
   */
  createProduct(): void {
    const changedFormValues: Partial<ICreateProductForm> = GetDirtyValues(this.form);
    if (this.form.valid) {
      this._facade
        .createProduct$({
          ...changedFormValues,
        })
        .subscribe({
          next: this._success.bind(this),
          error: this._error.bind(this),
        });
    }
  }

  /**
   * Sends API with form and product uuid to clone product
   */
  cloneExistingProduct(): void {
    const changedFormValues: Partial<ICreateProductForm> = GetDirtyValues(this.form);
    if (this.form.valid) {
      this._productsFacade.cloneAndUpdateProduct$({ ...changedFormValues }, this.product.uuid).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  /**
   * Send API to update existing product
   */
  updateExistingProduct(): void {
    const changedFormValues: Partial<ICreateProductForm> = GetDirtyValues(this.form);
    if (this.form.valid) {
      this._productsFacade.updateProduct$({ ...changedFormValues }, this.product.uuid).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }
  /**
   * Sends get API go get the product
   * @param uuid product id
   */
  private _getProduct(uuid: string | null): void {
    this._productsFacade.getProduct$(uuid).subscribe({
      next: this._getProductSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _success(): void {
    if (this.isRegisterPage) {
      // this.stepper.next();
      this._router.navigate(['/register/profile-completed']);
    } else {
      this._router.navigate(['/products']);
    }
  }

  /**
   * Set's the form after successfull getting the products
   * @param data from get product
   */
  private _getProductSuccess(data: IProduct): void {
    this.product = data;
    // const name = this.mode === 'duplicate' ? `${data.name} COPY` : data.name
    this.form.setValue({
      name: this.mode === 'duplicate' ? `${data.name} COPY` : data.name,
      description: data.description,
      summary: data.summary,
      reference: data.reference,
      image: data.image,
    });
    this.mode === 'duplicate' && this.form.get('name')?.markAsDirty();
  }

  /**
   *
   * @param error hadles HTTP error desplaying message from backend
   */
  private _error(error: ICreateProductErrorResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
