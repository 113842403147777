import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  IQuestionnaire,
  IQuestionnaireDetailsCreateUpdate,
  IQuestionnaireSectionQuestion,
} from '@interface/questionnaire.interface';
import { Subscription, debounceTime, fromEvent } from 'rxjs';

import { BreadcrumbHeaderService } from '@service/breadcrumb-header.service';
import { IBatchQuanityUnitResponse } from '@interface/batch.interface';
import { NotificationType } from '@app/core/constants';
import { QuestionnaireFacade } from '@facade/questionnaire.facade';
import { QuestionnaireService } from '@service/questionnaire.service';
import { QuestionnairesManagementGroup } from '@module/questionnaires-management/questionnaires-management-group';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-questionnaire',
  templateUrl: './create-questionnaire.component.html',
  styleUrls: ['./create-questionnaire.component.scss'],
})
export class CreateQuestionnaireComponent implements OnInit, OnDestroy, AfterViewInit {
  icon = 'saving';
  form: UntypedFormGroup;
  questionnaireDetails!: IQuestionnaire;
  urlHasId = false;
  subscriptions = new Array<Subscription>(0);

  @ViewChild('nameInput') nameInput!: ElementRef;
  constructor(
    private _group: QuestionnairesManagementGroup,
    private _snackbarService: SnackbarService,
    private router: Router,
    private route: ActivatedRoute,
    private questionnaireService: QuestionnaireService,
    private questionnaireFacade: QuestionnaireFacade,
    private breadcrumbsService: BreadcrumbHeaderService
  ) {
    this.form = this._group.createQuestion;
  }

  createSection() {
    if (this.questionnaireDetails) {
      this.questionnaireDetails.sections = Array.from(this.questionnaireDetails.sections);
      this.questionnaireDetails.sections.push({
        name: '',
        uuid: '',
        questions: new Array<IQuestionnaireSectionQuestion>(0),
        submitted_answers: '',
      });
    } else {
      this._snackbarService.openTypeSnackbar(`Add questionnaire name first`, NotificationType.success);
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(snapshot => {
      const id = snapshot.get('id');
      if (id) {
        this.urlHasId = true;
        this.icon = 'saved-changes';
        this.questionnaireFacade.getQuestionnaireDetails$(id).subscribe({
          next: this.getQuestionnaireDetailsSuccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  private createQuestionnairesSuccess(data: IQuestionnaireDetailsCreateUpdate) {
    this.icon = 'saved-changes';
    this.router.navigate(['/settings/questionnaires-management/create', data.uuid]);
    this.breadcrumbsService.setBreadCrumbs(true, 'Create Questionnaire');
  }

  private updateQuestionnaireDetailsSuccess(data: IQuestionnaireDetailsCreateUpdate) {
    this.icon = 'saved-changes';
    this.form.patchValue({
      name: data.name,
    });
  }

  private getQuestionnaireDetailsSuccess(data: IQuestionnaire) {
    this.questionnaireDetails = data;
    this.form.patchValue({
      name: data.name,
    });
  }

  private _error(error: IBatchQuanityUnitResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
    this.form.reset();
  }

  ngAfterViewInit(): void {
    fromEvent(this.nameInput.nativeElement, 'input')
      .pipe(debounceTime(2000))
      .subscribe(() => {
        this.icon = 'saving';
        !this.urlHasId
          ? this.questionnaireFacade.createQuestionnaires$(this.form.controls['name'].value).subscribe({
              next: this.createQuestionnairesSuccess.bind(this),
              error: this._error.bind(this),
            })
          : this.questionnaireFacade
              .updateQuestionnaireDetails$(this.questionnaireDetails.uuid, this.form.controls['name'].value)
              .subscribe({
                next: this.updateQuestionnaireDetailsSuccess.bind(this),
                error: this._error.bind(this),
              });
      });
  }
}
