import { ISupplier } from '@app/core/interface/suppliers.interface';
import { IProduct } from '@app/core/interface/products.interface';
import { Component, Inject, OnInit } from '@angular/core';
import { CreateBatchGroup } from './create-batch.group';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BatchFacade } from '@app/core/facade/batch.facade';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPhase } from '@app/core/interface/phase-management.interface';
import { IBatch, IBatchQuanityUnit, IBatchQuanityUnitResponse } from '@app/core/interface/batch.interface';
import { Router } from '@angular/router';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { IQuantityUnit } from '@app/core/interface/ingredient.interface';
import { DatePipe } from '@angular/common';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ChainStatus, IProductChainResult } from '@app/core/interface/productchain.interface';
import { DateAdapter } from '@angular/material/core';
import { IBrandFeatures, IBrandFeaturesResponse } from '@app/core/interface/brands.interface';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { PROJECT_MANAGEMENT, VIEW_MODES, NotificationType } from '@app/core/constants';
@Component({
  selector: 'app-create-batch',
  templateUrl: './create-batch.component.html',
  styleUrls: ['./create-batch.component.scss'],
  providers: [CreateBatchGroup, BatchFacade],
})
export class CreateBatchComponent implements OnInit {
  form!: UntypedFormGroup;
  minEndDate: Date | null = null;
  batchInfoTooltip = false;
  batchTransportTooltip = false;
  changedFormValues: Partial<IBatch> = {};
  allQuantityUnits!: IBatchQuanityUnit[];
  quantityUnits: Array<IBatchQuanityUnit> = [];
  quantityUnitsDropdown = new UntypedFormControl(null);
  opened = false;
  quantity_unit!: IBatchQuanityUnit | IQuantityUnit;
  quantity!: number;
  selectedView!: string;
  brandFeatures!: IBrandFeatures[];
  projectManagement!: IBrandFeatures | undefined;
  show_project!: boolean;
  isChainComplete = false;
  togleChecked = false;

  constructor(
    private _group: CreateBatchGroup,
    private _facade: BatchFacade,
    private _snackbarService: SnackbarService,
    private datePipe: DatePipe,
    private _router: Router,
    private _brandsFacade: BrandsFacade,
    @Inject(MAT_DIALOG_DATA)
    public data: { product_chain: IProductChainResult; batch?: IBatch; product?: IProduct; suppliers?: ISupplier[] },
    public dialogRef: MatDialogRef<CreateBatchComponent>,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.form = this._group.batchDetails;
    this.form.patchValue({
      product_chain: this.data.product_chain.uuid,
      name: this.data.batch?.name,
      quantity: this.data.batch?.quantity,
      quantity_unit: this.data.batch?.quantity_unit,
      transport: this.data.batch?.transport,
      free_text: this.data.batch?.free_text,
      date_order: this.data.batch?.date_order,
      date_delivered: this.data.batch?.date_delivered,
      external_reference: this.data.batch?.external_reference,
    });
    if (this.data.batch) {
      this.quantity_unit = this.data.batch.quantity_unit;
      this.quantity = this.data.batch.quantity;
    }

    this.quantityUnitsDropdown.setValue(this.quantity_unit);
    this.form.get('product_chain')?.markAsDirty();
    dateAdapter.setLocale('en-in'); // DD/MM/YYYY
    if (this.data.batch) {
      this.isChainComplete = this.data.product_chain.status === ChainStatus.complete;
    }
    this.dateAdapter.setLocale('en-in'); // DD/MM/YYYY
  }

  ngOnInit() {
    this._facade.getAllQuantityUnit$().subscribe({
      next: this._getQuantityUnitSuccess.bind(this),
      error: this._error.bind(this),
    });
    this.getAllQuantityUnits();
    this.getBrandFeatures();
    this.form.get('date_order')?.valueChanges.subscribe(date => {
      this.minEndDate = date;
    });

    this.selectedView = this.data.batch && this.data.batch.date_order ? VIEW_MODES.PERIOD : VIEW_MODES.DATE;
    this.togleChecked = this.selectedView === VIEW_MODES.PERIOD;
  }

  getAllQuantityUnits() {
    this._facade.getAllQuantityUnit$().subscribe({
      next: this._getAllQuantityUnitSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  onToggleChange(event: MatButtonToggleChange) {
    this.selectedView = event.value;
  }

  createBatch(): void {
    const changedFormValues: Partial<IBatch> = GetDirtyValues(this.form);
    const dateOrder: Date | null = changedFormValues['date_order'] ? new Date(changedFormValues['date_order']) : null;
    const dateDelivered: Date | null = changedFormValues['date_delivered']
      ? new Date(changedFormValues['date_delivered'])
      : null;

    if (dateOrder && !isNaN(dateOrder.getTime())) {
      changedFormValues['date_order'] = this.datePipe.transform(dateOrder, 'yyyy-MM-dd') || '';
    } else {
      changedFormValues['date_order'] = '';
    }
    if (dateDelivered && !isNaN(dateDelivered.getTime())) {
      changedFormValues['date_delivered'] = this.datePipe.transform(dateDelivered, 'yyyy-MM-dd') || '';
    } else {
      changedFormValues['date_delivered'] = '';
    }
    if (changedFormValues['quantity_unit']) {
      changedFormValues['quantity_unit'] = this.form.controls['quantity_unit'].value.uuid;
    }
    if (this.form.valid) {
      this._facade.createBatch$({ ...changedFormValues }).subscribe({
        next: this._createBatchSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private formatDateString(date: Date | null): string {
    return date ? this.datePipe.transform(date, 'yyyy-MM-dd') || '' : '';
  }

  updateBatch = (): void => {
    if (this.form.valid && this.data.batch) {
      let changedFormValues: Partial<IBatch> = GetDirtyValues(this.form);
      const currentDateOrder = this.data.batch.date_order;
      const currentDateDelivered = this.data.batch.date_delivered; // If the selected view is 'date' and the previous value was 'period', clear the start date (date_order)
      if (this.selectedView === VIEW_MODES.DATE) {
        changedFormValues['date_order'] = ''; // Set to empty string instead of null
      } else {
        if (!changedFormValues['date_order']) {
          changedFormValues['date_order'] = currentDateOrder;
        }
      }

      if (!changedFormValues['date_delivered']) {
        changedFormValues['date_delivered'] = currentDateDelivered;
      }
      changedFormValues = {
        ...changedFormValues,
        quantity_unit: this.quantity_unit?.uuid ? this.quantity_unit?.uuid : '',
        date_order: changedFormValues['date_order']
          ? this.formatDateString(new Date(changedFormValues['date_order']))
          : '',
        date_delivered: changedFormValues['date_delivered']
          ? this.formatDateString(new Date(changedFormValues['date_delivered']))
          : '',
      } as unknown as Partial<IBatch>;

      this._facade.updateBatch$(this.data.batch?.uuid, changedFormValues).subscribe({
        next: this._updateBatchSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  };

  private _getQuantityUnitSuccess(data: IBatchQuanityUnitResponse): void {
    const quantityUnits: IBatchQuanityUnit[] = data.results.filter(unit => unit.is_active);
    this.quantityUnits = quantityUnits;
    // if our batch has a unit that later is made inactive in settings,
    // we won't have this unit in the active batch units dropdown, so we push it manually,
    // just so we can have a preview for it
  }

  private _getAllQuantityUnitSuccess(data: IBatchQuanityUnitResponse): void {
    const allQuantity_Units: IBatchQuanityUnit[] = data.results.filter(unit => unit?.is_active);
    this.allQuantityUnits = allQuantity_Units;
    this.quantityUnits = this.allQuantityUnits;
    /// one extra empty mat-option field
  }

  private _createBatchSuccess(data: IBatch): void {
    this.dialogRef.close(true);
    this._router.navigate([
      `/chain-setup/batch-management/${data.batch_chain?.uuid}`,
      { currentUrl: this._router.url, tab: 'batches' },
    ]);
  }

  private _updateBatchSuccess(): void {
    this.dialogRef.close(true);
    this._router.navigate([
      `/chain-setup/batch-management/${this.data.batch?.batch_chain?.uuid}`,
      { currentUrl: this._router.url, tab: 'batches' },
    ]);
  }

  private _error(error: IPhase): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  displayFn(quantity_unit: IBatchQuanityUnit | null): string {
    return quantity_unit?.name || quantity_unit?.symbol ? ` ${quantity_unit?.symbol} (${quantity_unit?.name})` : '';
  }

  onUnitSelected(event: MatAutocompleteSelectedEvent) {
    this.quantity_unit = event.option.value;
  }

  filterItem(event: Event) {
    const value = (<HTMLInputElement>event.target).value;
    if (!value) {
      this.quantityUnits = this.allQuantityUnits;
    } else {
      this.quantityUnits = this.allQuantityUnits.filter(
        el =>
          el.name.toLowerCase().includes(value.toLowerCase()) || el.symbol.toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  openOrClosePanel(evt: Event, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
      this.opened = false;
    } else {
      trigger.openPanel();
      this.opened = true;
    }
  }

  getBrandFeatures(): void {
    this._brandsFacade.getBrandFeatures$().subscribe({
      next: this._successBrandFeatures.bind(this),
      error: this._error.bind(this),
    });
  }

  onStartDateChange() {
    const startDate = this.form.get('date_order')?.value;
    this.minEndDate = startDate;
  }

  private _successBrandFeatures(data: IBrandFeaturesResponse) {
    this.brandFeatures = data.results;
    if (this.brandFeatures) {
      this.projectManagement = this.brandFeatures.find(project => project.name === PROJECT_MANAGEMENT);
      if (this.projectManagement) {
        this.show_project = this.projectManagement.is_enabled;
      }
    }
  }
}
