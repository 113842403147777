import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IProject, IProjectResponse } from '@app/core/interface/projects.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectComponent } from '@app/module/projects/project/project.component';
import { ProjectDetailsDialogComponent } from '@app/module/projects/project-details-dialog/project-details-dialog.component';
import { ProjectsFacade } from '@app/core/facade/projects.facade';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent {
  @Input() project!: IProjectResponse;
  @Output() onUpdateSuccess = new EventEmitter<void>();
  has_products!: boolean;
  constructor(
    private _dialog: MatDialog,
    private _projectFacade: ProjectsFacade,
    private _snackbarService: SnackbarService,
    public router: Router
  ) {}

  openCreateDialog(uuid: string, is_editing: boolean): void {
    const dialogRef = this._dialog.open(ProjectComponent, {
      width: '1160px',
      minHeight: '768px',
      panelClass: 'top-padding-0',
      data: {
        is_editing: is_editing,
        projectId: uuid,
        is_project: true,
        project_view: true,
        custom_fields: this.project.custom_fields,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: IProject) => {
      if (result) {
        this.onUpdateSuccess.emit();
      }
    });
  }

  openViewDialog(project: IProject | IProjectResponse): void {
    this._dialog.open(ProjectDetailsDialogComponent, {
      width: '1160px',
      minHeight: '768px',
      panelClass: 'padding-0',
      data: {
        project: project,
        is_project: true,
        project_view: true,
        project_products: project.project_products,
        custom_fields: this.project.custom_fields,
      },
      disableClose: true,
    });
  }

  deactivateProject(project: IProjectResponse) {
    this.has_products = project.project_products.length >= 1;
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: this.has_products
          ? 'The selected project has active products under it.'
          : 'Note that once you deactivate this project, it’s sub-projects will be deactivated too. You can reactivate the project at anytime.',

        confirmationText: this.has_products
          ? 'The project cannot be deactivated!'
          : 'Are you sure you want to deactivate this project?',
        btnText: this.has_products ? 'Ok, Got It' : 'Deactivate Project',
        type: this.has_products ? 'forbidden' : 'warning',
        text: this.has_products ? 'forbidden-text' : 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._projectFacade.activateDeactivateProject$(project.uuid, { is_active: false }).subscribe({
          next: this._seccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  _seccess(): void {
    this.router.navigate(['products/projects']);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
