import { Component, Input } from '@angular/core';
import { ILanguageManagementSupplyChain } from '@app/core/interface/language-management.interface';

@Component({
  selector: 'app-supply-chain-translation-card',
  templateUrl: './supply-chain-translation-card.component.html',
  styleUrl: './supply-chain-translation-card.component.scss',
})
export class SupplyChainTranslationCardComponent {
  @Input() supply_chain!: ILanguageManagementSupplyChain;

  isTranslationComplete(supply_chain: ILanguageManagementSupplyChain): boolean {
    return supply_chain.translations_progress === supply_chain.translations_total;
  }
  manageTranslations(): void {
    //console.log('helloooo', this.supply_chain);
  }
}
