import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IBillingManagementResult, InvoiceDownloadResult, InvoiceObject } from '@app/core/interface/billing.interface';

import { BillingFacade } from '@app/core/facade/billing.facade';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-paid-invoices',
  templateUrl: './paid-invoices.component.html',
  styleUrls: ['./paid-invoices.component.scss'],
  providers: [BillingFacade],
})
export class PaidInvoicesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  displayedColumns: string[] = ['invoice-id', 'total', 'actions'];
  tableDataSource = new MatTableDataSource<InvoiceObject>();
  @Output() emptyTable = new EventEmitter<boolean>(false);

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  constructor(
    private _billingFacade: BillingFacade,
    private _snackbarService: SnackbarService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._billingFacade.getInvoices$().subscribe({
      next: this._setInvoices.bind(this),
      error: this._error.bind(this),
    });
  }

  /**
   * After view init
   */

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;

    this._billingFacade.getInvoices$().subscribe({
      next: (data: IBillingManagementResult) => {
        this.tableDataSource.data = data.invoices.filter(x => x.invoice.amount_due == '0');

        // Set the paginator's length property after setting the data
        if (this.paginator) {
          this.paginator.length = this.tableDataSource.data.length;
          this.paginator.pageSize = 12;
        }

        // Emit the emptyTable event
        this.emptyTable.emit(this.tableDataSource.data.length === 0);
      },
      error: this._error.bind(this),
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    if (this.tableDataSource) {
      this.tableDataSource.disconnect();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to set invoices
   * @param data
   */
  private _setInvoices(data: IBillingManagementResult): void {
    this.tableDataSource.data = data.invoices.filter(x => x.invoice.amount_due == '0');

    // Set the paginator's length property after setting the data
    if (this.paginator) {
      this.paginator.length = this.tableDataSource.data.length;
      this.paginator.pageSize = 12;
    }

    if (this.tableDataSource.data.length) {
      this.emptyTable.emit(false);
    } else {
      this.emptyTable.emit(true);
    }
  }

  /**
   * Function to download invoice
   * @param data
   */
  private _downloadInvoice(data: InvoiceDownloadResult): void {
    window.open(data.download_url, '_blank');
  }

  /**
   * Function for handling error
   * @param error
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to get invoice download link
   * @param invoiceId
   */
  getInvoiceDownloadLink(invoiceId: string) {
    this._billingFacade.downloadInvoice$(invoiceId).subscribe({
      next: this._downloadInvoice.bind(this),
      error: this._error.bind(this),
    });
  }
}
