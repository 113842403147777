@defer (when isFormLoaded()) {
  <div class="holder d-flex flex-column">
    <h2 mat-dialog-title>
      {{ data.title | translate }}
    </h2>

    <div class="content d-flex flex-column">
      <mat-dialog-content>
        <div class="publication-setup-wrapper d-flex flex-column">
          <div class="header d-flex align-items-center">
            <p class="header-text">{{ 'Supplier affected by this setup:' | translate }}</p>
            <div class="image">
              <img class="avatar" [src]="supplier?.logo ?? imgPlaceholder" alt="" />
            </div>
            <p class="supplier-name">{{ supplier?.name }}</p>
          </div>
          <div class="note">
            <app-custom-icon icon_category="essential" [icon_name]="'danger'"></app-custom-icon>
            <p>
              {{
                'Please note that all supplier configurations will immediately impact all related publications.'
                  | translate
              }}
            </p>
          </div>
          <div class="info d-flex align-items-center">
            <!-- Temporary commented this flag. will be used later -->
            <!-- <app-custom-icon
              class="{{ data?.related_publicated_chain ? 'tick-circle' : 'slash' }}"
              icon_category="essential"
              [icon_name]="data?.related_publicated_chain ? 'tick-circle' : 'slash'"
            >
            </app-custom-icon>

            <p class="info-text">
              {{
                data?.related_publicated_chain
                  ? ('This supplier have a published chain. If you make any changes now you can update all of the affected chains in the pending updates section.'
                    | translate)
                  : ('Please note that this supplier is not in any published chains yet. Once you publish a supply chain where this supplier is included, the setup that is done here will be applied for that published chain. Any additional changes later will go into the pending updates section were you will be able to update all of the chains that are affected.'
                    | translate)
              }}
            </p> -->
          </div>
          <div class="body-wrapper d-flex">
            <!-- Toggle fields -->
            <div class="form-wrapper flex-grow-1 p-0">
              <div class="select-label">{{ ' Setup which fields will be visible for this supplier' | translate }}</div>
              <!-- FORM -->
              <form [formGroup]="form">
                <div class="toggle-wrapper d-flex align-items-center">
                  <mat-checkbox formControlName="is_hidden" class="checkbox-primary" (change)="onConfidentialChange()">
                    {{ 'Confidential Supplier' | translate }}
                  </mat-checkbox>
                </div>

                <div *ngIf="form.get('is_hidden')?.value" class="row">
                  <div class="icon-w-sp">
                    <app-custom-icon [icon_category]="'security'" [icon_name]="'hidden'"></app-custom-icon>
                  </div>
                  <div class="col-11 hidden-s-t">
                    {{
                      'This supplier is marked as confidential. No supplier details will be displayed in Respect Code.'
                        | translate
                    }}
                  </div>
                </div>

                <div class="toggle-wrapper d-flex align-items-center">
                  <div class="label" [class.disabled]="isConfidential()">
                    {{ 'Supplier name' | translate }}
                  </div>
                  <mat-slide-toggle
                    [checked]="!form.get('is_name_private')?.value"
                    [disabled]="isConfidential()"
                    (change)="onToggleChange('is_name_private', $event)"
                  ></mat-slide-toggle>
                </div>
                <div class="toggle-wrapper d-flex align-items-center">
                  <div class="label" [class.disabled]="isConfidential()">
                    {{ 'Supplier description' | translate }}
                  </div>
                  <mat-slide-toggle
                    [checked]="!form.get('is_description_private')?.value"
                    [disabled]="isConfidential()"
                    (change)="onToggleChange('is_description_private', $event)"
                  ></mat-slide-toggle>
                </div>
                <!-- <div class="horizontal-line m-24"></div> -->
                <div class="toggle-wrapper d-flex align-items-center">
                  <mat-checkbox
                    class="checkbox-primary"
                    [class.disabled]="isConfidential()"
                    [disabled]="isConfidential()"
                    formControlName="is_location_private"
                  >
                    {{ 'Hide Location' | translate }}
                  </mat-checkbox>
                </div>

                <div *ngIf="form.get('is_location_private')?.value && !form.get('is_hidden')?.value" class="row lp">
                  <div class="icon-w-sp">
                    <app-custom-icon
                      class="location"
                      [icon_category]="'location'"
                      [icon_name]="'location-cross'"
                    ></app-custom-icon>
                  </div>
                  <div class="col-11 hidden-s-t">
                    {{ 'There won’t be any location displayed' | translate }}
                  </div>
                </div>

                <div class="select-label">
                  {{ 'Main site geopositioning display:' | translate }}
                </div>
                <div
                  class="select-description"
                  [class.fade-t]="isConfidential() || form.get('is_location_private')?.value"
                >
                  {{
                    'Please select bellow how your location will be displayed on a map. Only one option can be selected.'
                      | translate
                  }}
                </div>
                <div class="row">
                  <mat-radio-group (change)="changeMainSiteLocation($event.value.value, supplier?.main_location!)">
                    @for (option of selectOptions; track $index) {
                      <mat-radio-button
                        [value]="option"
                        class="opt"
                        [disabled]="isConfidential() || form.get('is_location_private')?.value"
                        [checked]="option.value === mainLocation().geopositioning"
                      >
                        {{ option.viewValue }} - {{ option.expVal }}
                      </mat-radio-button>
                    }
                  </mat-radio-group>
                  <div class="horizontal-line w-40"></div>
                </div>
                <mat-accordion>
                  <div class="select-label">
                    {{ 'Other supplier sites locations:' | translate }}
                  </div>
                  @for (item of siteLocations(); track $index) {
                    <mat-expansion-panel
                      (opened)="panelOpenState.set(true)"
                      (closed)="panelOpenState.set(false)"
                      [disabled]="isConfidential() || form.get('is_location_private')?.value"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div class="expasion-title">
                            <div class="panel-title-name">{{ item.name }}</div>
                            @switch (item.geopositioning) {
                              @case (1) {
                                <div class="panel-title-description">
                                  <app-custom-icon
                                    class="location"
                                    [icon_category]="'location'"
                                    [icon_name]="'location'"
                                    [disabled]="isConfidential() || form.get('is_location_private')?.value"
                                  ></app-custom-icon>
                                  <span>{{ 'Country will be displayed' | translate }}</span>
                                </div>
                              }
                              @case (2) {
                                <div class="panel-title-description">
                                  <app-custom-icon
                                    class="location"
                                    [icon_category]="'location'"
                                    [icon_name]="'location'"
                                    [disabled]="isConfidential() || form.get('is_location_private')?.value"
                                  ></app-custom-icon>
                                  <span>{{ 'Region will be displayed' | translate }}</span>
                                </div>
                              }
                              @case (3) {
                                <div class="panel-title-description">
                                  <app-custom-icon
                                    class="location"
                                    [icon_category]="'location'"
                                    [icon_name]="'location'"
                                    [disabled]="isConfidential() || form.get('is_location_private')?.value"
                                  ></app-custom-icon>
                                  <span>{{ 'City will be displayed' | translate }}</span>
                                </div>
                              }
                              @case (4) {
                                <div class="panel-title-description">
                                  <app-custom-icon
                                    class="location"
                                    [icon_category]="'location'"
                                    [icon_name]="'location'"
                                    [disabled]="isConfidential() || form.get('is_location_private')?.value"
                                  ></app-custom-icon>
                                  <span>{{ 'Full address will be displayed' | translate }}</span>
                                </div>
                              }
                            }
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="row">
                        <mat-radio-group (change)="changeSiteLocation($event.value.value, item.uuid)">
                          @for (option of selectOptions; track $index) {
                            <mat-radio-button
                              [value]="option"
                              class="opt"
                              [disabled]="isConfidential() || form.get('is_location_private')?.value"
                              [checked]="option.value === item.geopositioning"
                            >
                              {{ option.viewValue }} - {{ option.expVal }}
                            </mat-radio-button>
                          }
                        </mat-radio-group>
                      </div>
                    </mat-expansion-panel>
                  }
                </mat-accordion>
              </form>
            </div>
          </div>
          <div class="horizontal-line"></div>
          <div class="data-holder">
            <div class="documents-label">{{ 'Which of the supplier useful data will be visible' | translate }}</div>
            <!-- Document toggles -->
            <div class="data-wrap d-flex flex-column">
              <h6 class="data-title" *ngIf="supplier?.documents?.length">{{ 'Documents' | translate }}</h6>
              <div class="card-wrap">
                @for (item of documents(); track $index) {
                  <div class="data-card d-flex flex-column">
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="document-icon">
                        <img src="assets/images/document.png" />
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      [ngClass]="{ 'disabled-useful-data': isConfidential() }"
                    >
                      <p class="data-name">{{ item.name }}</p>
                    </div>

                    <div
                      class="toggle-wrap d-flex align-items-center"
                      [ngClass]="{ 'disabled-useful-data': isConfidential() }"
                    >
                      <p>{{ !item.is_entity_private ? 'On' : ('Off' | translate) }}</p>
                      <mat-slide-toggle
                        class="custom-slide-toggle"
                        (change)="toggleDocuments(item.uuid, item, $event.checked)"
                        [color]="'primary'"
                        [checked]="!item.is_entity_private"
                        [class.disabled]="isConfidential()"
                        [disabled]="isConfidential()"
                      >
                      </mat-slide-toggle>
                    </div>
                  </div>
                }
              </div>
            </div>

            <!-- Photos toggles -->
            <div class="data-wrap d-flex flex-column" *ngIf="images().length">
              <h6 class="data-title">{{ 'Photos' | translate }}</h6>
              <div class="card-wrap">
                @for (item of images(); track $index) {
                  <div class="data-card d-flex flex-column">
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="document-icon">
                        <img
                          class="document-icon"
                          [ngClass]="{ 'disabled-useful-data': isConfidential() }"
                          src="{{ item.thumbnail }}"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      [ngClass]="{ 'disabled-useful-data': isConfidential() }"
                    >
                      <p class="data-name">{{ item.name }}</p>
                    </div>

                    <div
                      class="toggle-wrap d-flex align-items-center"
                      [ngClass]="{ 'disabled-useful-data': isConfidential() }"
                    >
                      <p>{{ !item.is_entity_private ? 'On' : ('Off' | translate) }}</p>
                      <mat-slide-toggle
                        class="custom-slide-toggle"
                        (change)="toggleMedias(item.uuid, item, $event.checked)"
                        [color]="'primary'"
                        [checked]="!item.is_entity_private"
                        [class.disabled]="isConfidential()"
                        [disabled]="isConfidential()"
                      >
                      </mat-slide-toggle>
                    </div>
                  </div>
                }
              </div>
            </div>
            <!-- Videos toggles -->
            <div class="data-wrap d-flex flex-column" *ngIf="videos().length">
              <h6 class="data-title">{{ 'Videos' | translate }}</h6>
              <div class="card-wrap">
                @for (item of videos(); track $index) {
                  <div class="data-card d-flex flex-column">
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="document-icon">
                        <img
                          class="document-icon"
                          [ngClass]="{ 'disabled-useful-data': isConfidential() }"
                          src="{{ item.thumbnail }}"
                        />
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      [ngClass]="{ 'disabled-useful-data': isConfidential() }"
                    >
                      <p class="data-name">{{ item.name }}</p>
                    </div>

                    <div
                      class="toggle-wrap d-flex align-items-center"
                      [ngClass]="{ 'disabled-useful-data': isConfidential() }"
                    >
                      <p>{{ !item.is_entity_private ? 'On' : ('Off' | translate) }}</p>
                      <mat-slide-toggle
                        class="custom-slide-toggle"
                        (change)="toggleMedias(item.uuid, item, $event.checked)"
                        [color]="'primary'"
                        [checked]="!item.is_entity_private"
                        [class.disabled]="isConfidential()"
                        [disabled]="isConfidential()"
                      >
                      </mat-slide-toggle>
                    </div>
                  </div>
                }
              </div>
            </div>

            <!-- Link toggles -->
            <div class="data-wrap d-flex flex-column">
              <h6 class="data-title" *ngIf="supplier?.links?.length">{{ 'Links' | translate }}</h6>
              <div class="card-wrap">
                @for (item of links(); track $index) {
                  <div class="data-card d-flex flex-column">
                    <div class="d-flex justify-content-center align-items-center">
                      <a [href]="item.url" [matTooltip]="item.url" aria-label="Visit page" target="_blank">
                        <img src="assets/images/link.png" />
                      </a>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      [ngClass]="{ 'disabled-useful-data': isConfidential() }"
                    >
                      <p class="data-name">{{ item.name }}</p>
                    </div>

                    <div
                      class="toggle-wrap d-flex align-items-center"
                      [ngClass]="{ 'disabled-useful-data': isConfidential() }"
                    >
                      <p>{{ !item.is_entity_private ? 'On' : ('Off' | translate) }}</p>
                      <mat-slide-toggle
                        class="custom-slide-toggle"
                        (change)="toggleLink(item.uuid, item, $event.checked)"
                        [color]="'primary'"
                        [checked]="!item.is_entity_private"
                        [class.disabled]="isConfidential()"
                        [disabled]="isConfidential()"
                      >
                      </mat-slide-toggle>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div
              *ngIf="!supplier?.links?.length && !supplier?.medias?.length && !supplier?.documents?.length"
              class="no-media d-flex align-items-center"
            >
              <app-custom-icon icon_category="finance" icon_name="document"></app-custom-icon>
              <app-custom-icon icon_category="essential" icon_name="image"></app-custom-icon>
              <p>{{ 'There are no documents, media or links uploaded yet.' | translate }}</p>
            </div>
          </div>
        </div>
      </mat-dialog-content>
    </div>

    <mat-dialog-actions>
      <app-rounded-button
        class="underline-button"
        color="tetriary-link underlined"
        [button_text]="'Cancel'"
        [size]="'md'"
        (click)="cancel()"
      >
      </app-rounded-button>
      <app-rounded-button
        color="success"
        [button_text]="'Save supplier setup'"
        [icon_category]="'arrows'"
        [icon_name]="'arrow-right'"
        [icon_right]="true"
        [size]="'md'"
        (click)="onSubmit()"
      >
      </app-rounded-button>
    </mat-dialog-actions>
  </div>
} @loading {
  Loading...
} @error {
  Error...
}
