import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BillingFacade } from '@app/core/facade/billing.facade';
import { BillingManagementGroup } from '../../billing-management.group';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IBillingAddressDetails } from '@app/core/interface/billing.interface';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-manage-billing-address',
  templateUrl: './manage-billing-address.component.html',
  styleUrls: ['./manage-billing-address.component.scss'],
  providers: [BillingManagementGroup, BillingFacade],
})
export class ManageBillingAddressComponent implements OnInit {
  billingInfoForm: UntypedFormGroup;
  @Input() billingDetails!: IBillingAddressDetails;
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _group: BillingManagementGroup,
    private _billingFacade: BillingFacade,
    private _snackbarService: SnackbarService
  ) {
    this.billingInfoForm = this._group.billingInfoForm;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._mapBillingInfoData();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to map billing details data
   */
  private _mapBillingInfoData(): void {
    this.billingInfoForm.patchValue({
      billing_first_name: this.billingDetails.billing_first_name,
      billing_last_name: this.billingDetails.billing_last_name,
      line1: this.billingDetails.line1,
      city: this.billingDetails.city,
      zip: this.billingDetails.zip,
      state: this.billingDetails.state,
      country: this.billingDetails.country,
    });

    this._markInputsAsDirty();
  }

  /**
   * Function to mark inputs as dirty by default
   */
  private _markInputsAsDirty() {
    this.billingInfoForm.get('first_name')?.markAsDirty();
    this.billingInfoForm.get('last_name')?.markAsDirty();
    this.billingInfoForm.get('street_line_1')?.markAsDirty();
    this.billingInfoForm.get('city')?.markAsDirty();
    this.billingInfoForm.get('state')?.markAsDirty();
    this.billingInfoForm.get('country')?.markAsDirty();
  }

  private _success(): void {
    this._snackbarService.openTypeSnackbar('Account details updated successfully', NotificationType.success);
    this.closeDialog.emit(true);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
    this.closeDialog.emit(true);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to update billing info
   */
  updateBillingInfo(): void {
    const changedFormValues: Partial<IBillingAddressDetails> = GetDirtyValues(this.billingInfoForm);
    if (this.billingInfoForm.valid) {
      this._billingFacade.updateBillingInfo$({ ...changedFormValues }).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }
}
