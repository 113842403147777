import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IIngredient } from '@app/core/interface/ingredient.interface';
import { IngredientDetailsGroup } from './ingredient-details-form.group';
import { IngredientFacade } from '@app/core/facade/ingredient.facade';
import { NotificationType } from '@app/core/constants';
import { Observable } from 'rxjs';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-ingredient-details-form',
  templateUrl: './ingredient-details-form.component.html',
  styleUrls: ['./ingredient-details-form.component.scss'],
  providers: [IngredientDetailsGroup, IngredientFacade],
})
export class IngredientDetailsFormComponent implements OnChanges {
  form!: UntypedFormGroup;
  @Input() componentUuid!: string;
  component!: IIngredient;

  constructor(
    private _group: IngredientDetailsGroup,
    private _ingredientFacade: IngredientFacade,
    private _snackbarService: SnackbarService
  ) {
    this.form = this._group.ingredientDetails;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['componentUuid']) {
      this._ingredientFacade.getIngredient$(changes['componentUuid'].currentValue).subscribe({
        next: this._getComponentSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  updateIngredientDetails(): Observable<IIngredient> {
    const submitForm = GetDirtyValues(this.form);
    return this._ingredientFacade.updateIngredient$(this.componentUuid, submitForm);
  }

  private _getComponentSuccess(data: IIngredient): void {
    this.component = data;
    this.form.patchValue({
      reference: data.reference,
      quantity: data.quantity,
      sourcing_location: data.sourcing_location,
    });
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
