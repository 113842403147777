import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ISupplier, ISuppliersResponse } from '@app/core/interface/suppliers.interface';

import { ICertificationObjectSelected } from '@app/core/interface/certificates.interface';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { Observable } from 'rxjs';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { applyFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-certification-suppliers-list',
  templateUrl: './certification-suppliers-list.component.html',
  styleUrls: ['./certification-suppliers-list.component.scss'],
  providers: [SuppliersFacade],
})
export class CertificationSuppliersListComponent implements OnInit {
  @Output() objectSelected = new EventEmitter<ICertificationObjectSelected>();
  suppliersList: ISupplier[] = [];
  suppliersDataSource = new MatTableDataSource<ISupplier>();
  suppliersObservable$!: Observable<ISupplier[]>;
  selectedObject: ICertificationObjectSelected | null = null;

  constructor(
    private _suppliersFacade: SuppliersFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this._suppliersFacade.getSupplierList$().subscribe({
      next: this._getSupplierListSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  // Search product filter
  applyFilter(event: Event): void {
    applyFilter(event, this.suppliersDataSource, this.suppliersDataSource);
  }

  onSelectObject(object: ISupplier) {
    this.selectedObject = {
      name: object.name,
      uuid: object.uuid,
      image: object.logo,
    };

    this.objectSelected.emit(this.selectedObject);
  }

  private _getSupplierListSuccess(data: ISuppliersResponse): void {
    if (data && data.results.length) {
      this.suppliersList = data.results;
      this.suppliersDataSource.data = data.results;
      this.suppliersObservable$ = this.suppliersDataSource.connect();
    }
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
