<div class="card-holder d-flex flex-column">
  <div class="wrapper d-flex align-items-center justify-content-between">
    <div class="info-wrapp d-flex align-items-center">
      <p class="m-0 chain-name">{{ supply_chain.name }}</p>
    </div>
    <app-custom-icon
      class="cursor-pointer"
      [icon_category]="'essential'"
      [icon_name]="'translate'"
      (click)="manageTranslations()"
    ></app-custom-icon>
  </div>
  <div class="progress" [ngClass]="isTranslationComplete(supply_chain) ? 'fully' : 'missing'">
    <!-- no backend yet  -->
    <!-- <p class="m-0">{{ 'Translations progress:' }}</p> -->
    <!-- <span class="txt">{{ supply_chain?.translations_progress + '/' + supply_chain?.translations_total }}</span> -->
    <p class="m-0">{{ 'Translations progress: 9/9' | translate }}</p>
  </div>
</div>
