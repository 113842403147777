<div class="card-holder d-flex flex-column">
  <div class="wrapper d-flex align-items-center justify-content-between">
    <div class="info-wrapp d-flex align-items-center">
      <img class="logo" [src]="supplier.logo ? supplier.logo : imgPlaceholder" alt="" />
      <p class="m-0 cert-name">{{ supplier.name }}</p>
    </div>
    <app-custom-icon
      class="cursor-pointer"
      (click)="manageTranslations(supplier)"
      [icon_category]="'essential'"
      [icon_name]="'translate'"
    ></app-custom-icon>
  </div>
  <div class="progress" [ngClass]="isMissing ? 'missing' : 'fully'">
    <p class="m-0">{{ 'Translations progress: 0/9' | translate }}</p>
  </div>
</div>
