import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DIRECT_SUPPLIER,
  MAIN,
  NotificationType,
  OFFICE_FILENAME_EXTENSIONS,
  infoDialogHeight,
  infoDialogWidth,
} from '@app/core/constants';
import { IBatchChainStep, ISuppChainStep, ISuppManufactory } from '@app/core/interface/supp.interface';
import { UntypedFormGroup, Validators } from '@angular/forms';

import { AccountsService } from '@app/core/service/accounts.service';
import { EditStepBatch } from './batch-main-info.group';
import { FileViewComponent } from '@app/shared/components/file-view/file-view.component';
import { IMedia } from '@app/core/interface/medias.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { InfoInputDialogComponent } from '@app/shared/components/info-input-dialog/info-input-dialog.component';
import { LoginFacade } from '@app/core/facade/login.facade';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { SiteDialogComponent } from '@app/module/company-info/site-dialog/site-dialog.component';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { SupplierStatus } from '@app/core/interface/steps.interface';
import { TranslateService } from '@ngx-translate/core';
import { UrlStorageService } from '@app/core/service/url-storage.service';

@Component({
  selector: 'app-batch-main-info',
  templateUrl: './batch-main-info.component.html',
  styleUrls: ['./batch-main-info.component.scss'],
  providers: [EditStepBatch],
})
export class BatchMainInfoComponent implements OnChanges, OnDestroy {
  disapproved = false;
  disapprovedViewOnly = false;
  alreadyApproved = false;
  showTooltip = true;
  supplier_site_inactive = false;
  inactive_tooltip = false;
  firstPanelOpenState = true;
  secondPanelOpenState = true;
  confirmationPanel = true;
  completeStepFirstTootltip = false;
  completeStepSecondTootltip = false;
  is_first_update = false;

  @Input() supplier_sites!: ISuppManufactory[];
  @ViewChild('secondPanel') firstPanel!: MatExpansionPanel;
  @ViewChild('secondPanel') secondPanel!: MatExpansionPanel;
  // @Input() selected_site!: UntypedFormControl;
  @Input() screen!: string;
  @Input() step!: ISuppChainStep;
  @Input() stepBatch!: IBatchChainStep;
  @Input() is_view!: boolean;
  @Input() warningConfirmation = false;
  @Input() warningAnyField!: boolean;
  @Input() warningSite = false;
  @Input() is_enabled!: boolean;
  form!: UntypedFormGroup;
  stepBatchUpdateObj!: Partial<IBatchChainStep>;
  @Output() disableDirectSupp: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() stepBatchObj = new EventEmitter<IBatchChainStep>();
  @ViewChild('supplierSiteDropdown', { read: ElementRef }) supplierSiteDropdown!: ElementRef<HTMLElement>;
  timeout?: NodeJS.Timeout;
  @Output() refresh = new EventEmitter<string>();
  constructor(
    private _dialog: MatDialog,
    private _group: EditStepBatch,
    private _supplierfacade: SuppSuppliersFacade,
    private _snackbarService: SnackbarService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _accountsService: AccountsService,
    private _loginFacade: LoginFacade,
    private _ulrService: UrlStorageService,
    public translate: TranslateService
  ) {
    this.form = this._group.editStepBatch;
  }

  patchValue(): void {
    this.form.patchValue({
      manufactory: this.stepBatch?.manufactory?.uuid,
      step_confirmed_by_supplier: this.stepBatch?.step_confirmed_by_supplier,
      supplier_disapproval_reason: this.stepBatch?.supplier_disapproval_reason,
      uuid: this.stepBatch?.uuid,
      is_first_time_updated: this.stepBatch?.is_first_time_updated,
    });
    this.setVariablesForInactiveSections();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['stepBatch']) this.patchValue();
    if (changes['screen']) {
      if (this.screen === DIRECT_SUPPLIER) {
        this.secondPanel.close();
        this.secondPanelOpenState = false;
      } else if (this.screen === MAIN) {
        this.firstPanel.open();
        this.secondPanel.open();
        this.firstPanelOpenState = this.secondPanelOpenState = true;
      }
    }
    if (changes['warningSite']?.currentValue === true) {
      this.setFormControlError();
    }
  }
  ngOnDestroy(): void {
    if (this.timeout) clearTimeout(this.timeout);
  }
  childMethodMarkAsComplete() {
    this.onMarkStepAsCompleted();
  }

  // Funtion for setting value to variables for showing or hiding some sections
  setVariablesForInactiveSections(): void {
    if (this.stepBatch) {
      this.stepBatchObj.emit(this.stepBatch);

      if (this.stepBatch?.step_confirmed_by_supplier == null) {
        this.supplier_site_inactive = true;
      } else {
        this.supplier_site_inactive = !this.stepBatch?.step_confirmed_by_supplier;
        if (this.stepBatch?.step_confirmed_by_supplier)
          this.form.controls['manufactory'].addValidators(Validators.required);
      }
      this.alreadyApproved = this.is_view && this.stepBatch.step_confirmed_by_supplier;
      this.disapproved = !this.is_view && this.stepBatch.step_confirmed_by_supplier === false;
      this.disapprovedViewOnly = this.is_view && this.stepBatch.step_confirmed_by_supplier === false;
      this.is_first_update = this.stepBatch.is_first_time_updated;
      this.warningSite = false;
      if (!this.is_view) this.setDisableDirectSupp();
    }
    this._cdr.detectChanges();
  }

  // Function for showing tooltip inactive section for site
  mouseEvHandler(status: boolean) {
    this.inactive_tooltip = status;
  }

  // Function for creating new site and adding it to this step of a batch
  onCreateNewSite() {
    const dialogRef = this._dialog.open(SiteDialogComponent, {
      width: '1160px',
      height: '768px',
      data: {
        suppSupplier: this.stepBatch.supplier,
      },
      panelClass: 'top-padding-0',
    });

    dialogRef.afterClosed().subscribe((result: ISuppManufactory | null) => {
      if (result) {
        this.supplier_sites.push(result);
        this.form.controls['manufactory'].setValue(result.uuid);
        this.updateStepBatch();
      }
    });
  }

  // Function for opening dialog and confirm disapprovement
  confirmDisapprove(): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: this.translate.instant(
          'supply-batch-main.You are about to disapprove all the mentioned information that concerns you for this batch.'
        ),
        confirmationText: this.translate.instant('supply-batch-main.Are you sure you want to continue?'),
        btnText: 'supply-batch-main.Yes',
        type: 'info-actions',
        text: 'info-text',
        disapproved: true,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.disapproveDialog();
      } else {
        this.patchValue();
      }
    });
  }

  // Function for update step batch
  updateStepBatch(): void {
    this.is_first_update = this.stepBatch.is_first_time_updated;
    const stepBatchUpdateObj: Partial<IBatchChainStep> = {
      step_confirmed_by_supplier: this.form.controls['step_confirmed_by_supplier'].value,
      supplier_disapproval_reason: this.form.controls['supplier_disapproval_reason'].value,
      is_first_time_updated: true,
    };

    if (this.stepBatch?.manufactory?.uuid) {
      stepBatchUpdateObj.manufactory = this.form.controls['manufactory'].value;
    }

    this._supplierfacade.updateSupplierStepBatch$(this.stepBatch.uuid, stepBatchUpdateObj).subscribe({
      next: this._updateStepBatchSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  onManufactoryChangeValue(): void {
    this._supplierfacade.changingFlagSignal.set(true);
    const selectedManufactoryUUID = this.form.controls['manufactory'].value;
    const selectedManufactory = this.supplier_sites.find(site => site.uuid === selectedManufactoryUUID);
    if (selectedManufactory) {
      this.stepBatch.manufactory = selectedManufactory;
      this.updateStepBatch();
    }
  }

  // Function for disable direct supplier tab in edit-step
  setDisableDirectSupp(): void {
    this.disableDirectSupp.emit(!this.stepBatch.step_confirmed_by_supplier);
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  // Function for openig dialog and specify reason for disapprovment
  disapproveDialog(): void {
    const dialogRef = this._dialog.open(InfoInputDialogComponent, {
      width: '580px',
      data: {
        infoText:
          'supply-batch-main.You have disapproved all the mentioned information that concerns you for this batch.',
        btnText: 'supply-batch-main.Mark step as completed',
        label: 'supply-batch-main.Specify the reason',
        placeholder: 'supply-batch-main.Enter reason',
        limitation: 255,
      },
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.disapproveStepBatch(result);
      } else {
        this.patchValue();
      }
    });
  }

  disapproveStepBatch(reason: string): void {
    this.is_first_update = this.stepBatch.is_first_time_updated;
    const stepBatchUpdateObj: Partial<IBatchChainStep> = {
      step_confirmed_by_supplier: false,
      supplier_disapproval_reason: reason,
      is_first_time_updated: true,
    };

    if (this.stepBatch?.manufactory?.uuid) {
      stepBatchUpdateObj.manufactory = this.form.controls['manufactory'].value;
    }

    this._supplierfacade.disapproveStepBatch$(this.stepBatch.uuid, stepBatchUpdateObj).subscribe({
      next: this._disapproveSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  // openFileList(screen: IMediaScreens, media?: IMedia): void {
  //   const list: IMedia[] = [];
  //   this._dialog.open(ViewUsefullDataDialogComponent, {
  //     width: '1160px',
  //     height: '660px',
  //     panelClass: ['overflow-hidden-dialog', 'view-documents-dialog', 'padding-0'],
  //     data: {
  //       data: list,
  //       screen: screen,
  //       selectedFile: media,
  //       is_supplier: true,
  //     },
  //     disableClose: true,
  //   });
  // }

  openDocument(file: IMedia) {
    let mimetype;
    const extIndex = file.name.split('.').length - 1;
    const ext = file.name.split('.')[extIndex];
    if (OFFICE_FILENAME_EXTENSIONS.includes(ext)) {
      mimetype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else {
      mimetype = 'application/pdf';
    }

    this._dialog.open(FileViewComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'padding-0',
      data: {
        url: file.file,
        name: file.name,
        mimetype: mimetype,
        created_at: file.created_at,
        isBatch: true,
        backTitle: 'Step Details',
      },
    });
  }

  // Function for confirm information if supplier is agree with the information
  confirmInfo(event: MatRadioChange) {
    const choice: boolean = event.value;
    if (choice === true) {
      this.stepBatch.step_confirmed_by_supplier = choice;
      // this._supplierfacade.changingFlag.next(true);
      this.updateStepBatch();
      this.secondPanel.close();
      this.secondPanelOpenState = false;
    } else {
      this.confirmDisapprove();
    }
  }

  // Function for show/hide info tooltip
  showHideTooltipInfo(show: boolean): void {
    this.showTooltip = !show;
    this.is_first_update = show;
  }

  // Method to set errors on the form control
  setFormControlError(): void {
    this.firstPanel.close();
    this.secondPanel.close();
    this.firstPanelOpenState = this.secondPanelOpenState = false;
    setTimeout(() => {
      this.form.controls['manufactory'].setErrors({ required: true });
      this.form.controls['manufactory'].markAsTouched();
    }, 200);
  }

  // Function for marking step as completed send to backend step.supplier_status = 'Updated'
  onMarkStepAsCompleted(): void {
    const supplierStatus = SupplierStatus.updated;

    this._supplierfacade.markBatchStepAsComplete$(this.stepBatch?.uuid, { supplier_status: supplierStatus }).subscribe({
      next: this._markBatchStepSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  ///////////////////////////////////// PRIVATE METHODS //////////////////////////////////////////

  private _markBatchStepSuccess(): void {
    this._supplierfacade.changingFlagSignal.set(false);
    this._accountsService.setIsSaved(false);
    if (this._ulrService.getRedirectUrl() === '/login') {
      this._loginFacade.logout$().subscribe({
        next: () => this._router.navigate(['/login']),
        error: this._error.bind(this),
      });
    } else {
      this._router.navigate(['/chain-steps-info']);
      this._snackbarService.openTypeSnackbar('The Batchstep is updated', NotificationType.success);
    }
  }
  private _disapproveSuccess(): void {
    this._accountsService.setIsSaved(false);
    this._snackbarService.openTypeSnackbar('After this must send email from backend', NotificationType.success);
    this._router.navigate(['chain-steps-info']);
  }
  private _updateStepBatchSuccess(stepBatch: IBatchChainStep): void {
    this.stepBatch = stepBatch;
    this._accountsService.setIsSaved(!!this.stepBatch.step_confirmed_by_supplier);
    this.stepBatchObj.emit(stepBatch);
    this.supplier_site_inactive = !this.stepBatch.step_confirmed_by_supplier;
    if (this.stepBatch.step_confirmed_by_supplier) this.form.controls['manufactory'].addValidators(Validators.required);
    if (!this.is_first_update && this.stepBatch.step_confirmed_by_supplier) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.completeStepFirstTootltip = true;
      }, 1000);
    }
    this.setVariablesForInactiveSections();
    this.refresh.emit(stepBatch.uuid);
  }
}
