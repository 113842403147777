import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BillingFacade } from '@app/core/facade/billing.facade';
import { BillingManagementGroup } from '../../billing-management.group';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IBillingCustomerDetails } from '@app/core/interface/billing.interface';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-manage-customer-details',
  templateUrl: './manage-customer-details.component.html',
  styleUrls: ['./manage-customer-details.component.scss'],
  providers: [BillingManagementGroup, BillingFacade],
})
export class ManageCustomerDetailsComponent implements OnInit {
  customerDetailsForm: UntypedFormGroup;
  @Input() customerDetails!: IBillingCustomerDetails;
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _group: BillingManagementGroup,
    private _billingFacade: BillingFacade,
    private _snackbarService: SnackbarService
  ) {
    this.customerDetailsForm = this._group.customerDetailsForm;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._mapCustomerDetailsData();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to map customer details data
   */
  private _mapCustomerDetailsData(): void {
    this.customerDetailsForm.patchValue({
      first_name: this.customerDetails.first_name,
      last_name: this.customerDetails.last_name,
      email: this.customerDetails.email,
      phone: this.customerDetails.phone,
    });
    this._markInputsAsDirty();
  }

  /**
   * Function to mark inputs as dirty by default
   */
  private _markInputsAsDirty() {
    this.customerDetailsForm.get('first_name')?.markAsDirty();
    this.customerDetailsForm.get('last_name')?.markAsDirty();
    this.customerDetailsForm.get('phone')?.markAsDirty();
  }

  private _success(): void {
    this._snackbarService.openTypeSnackbar('Account details updated successfully', NotificationType.success);
    this.closeDialog.emit(true);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
    this.closeDialog.emit(true);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to update customer details
   */
  updateCustomerDetails(): void {
    const changedFormValues: Partial<IBillingCustomerDetails> = GetDirtyValues(this.customerDetailsForm);
    if (this.customerDetailsForm.valid) {
      this._billingFacade.updateCustomerDetails$({ ...changedFormValues }).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }
}
