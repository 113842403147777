import { Component, OnInit } from '@angular/core';
import { IBrandListResult, IBrandsListResponse } from '@app/core/interface/brands.interface';
import { NotificationType, REGISTER_STEP, Storage } from '@app/core/constants';

import { AccountsService } from '@app/core/service/accounts.service';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { ICompleteProfileResponse } from '@app/core/interface/register.interface';
import { IProduct } from '@app/core/interface/products.interface';
import { IProductsListResponse } from '@app/core/interface/products.interface';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-profile-completed',
  templateUrl: './profile-completed.component.html',
  styleUrls: ['./profile-completed.component.scss'],
  providers: [ProductsFacade],
})
export class ProfileCompletedComponent implements OnInit {
  panelOpenState = false;
  product!: IProduct;
  brand!: IBrandListResult;
  accountDetails!: ICompleteProfileResponse;
  showFullBrandDescription: boolean = false;
  showFullTraceability: boolean = false;
  showFullProductDescription: boolean = false;
  activeSection: string | null = null;

  constructor(
    private _router: Router,
    private _dataStorageService: DataStorageService,
    private _productsFacade: ProductsFacade,
    private _snackbarService: SnackbarService,
    private _brandsFacade: BrandsFacade,
    private _accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.getAccountDetails();
    this.getProducts();
    this.getBrands();
  }

  completeSetup() {
    this._snackbarService.openTypeSnackbar(`Your account have been successfully created`, NotificationType.success);
    this._dataStorageService.removeKey(REGISTER_STEP, Storage.session);
    this._router.navigate(['/dashboard']);
  }

  getProducts(): void {
    this._productsFacade.getProducts$().subscribe({
      next: this._getProductsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _getProductsSuccess(data: IProductsListResponse): void {
    if (data && data.results.length) {
      this.product = data.results[0];
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  getBrands() {
    this._brandsFacade.getBrands$().subscribe({
      next: this._getBrandsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _getBrandsSuccess(data: IBrandsListResponse): void {
    this.brand = data.results[0];
  }

  getAccountDetails() {
    this._accountService.getAccountDetails().subscribe({
      next: this._setAccountDetails.bind(this),
      error: this._error.bind(this),
    });
  }

  private _setAccountDetails(data: ICompleteProfileResponse): void {
    this.accountDetails = data;
  }

  toggleBrandDescription() {
    this.showFullBrandDescription = !this.showFullBrandDescription;
  }

  toggleTraceability() {
    this.showFullTraceability = !this.showFullTraceability;
  }

  toggleProductDescription() {
    this.showFullProductDescription = !this.showFullProductDescription;
  }

  toggleSection(section: string) {
    this.activeSection = this.activeSection === section ? null : section;
  }
}
