import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FlagComponentGroup } from './flag-component.group';
import { IngredientFacade } from '@app/core/facade/ingredient.facade';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IProductComponent } from '@app/core/interface/products.interface';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IIngredient } from '@app/core/interface/ingredient.interface';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-flag-component-as-chemical',
  templateUrl: './flag-component-as-chemical.component.html',
  styleUrls: ['./flag-component-as-chemical.component.scss'],
  providers: [FlagComponentGroup, IngredientFacade],
})
export class FlagComponentAsChemicalComponent {
  showNote = true;
  form: UntypedFormGroup;
  isFlagged = false;

  constructor(
    private _group: FlagComponentGroup,
    private _ingredientFacade: IngredientFacade,
    private _snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public component: IProductComponent,
    public dialogRef: MatDialogRef<FlagComponentAsChemicalComponent>
  ) {
    this.dialogRef.disableClose;
    this.form = this._group.flagAsChemicalForm;

    if (component.ec_list && component.cas_no) {
      this.isFlagged = true;

      this.form.patchValue({
        ec_list: component.ec_list,
        cas_no: component.cas_no,
      });
    }
  }

  onFlagUnflagComponent(isFlag: boolean) {
    this.form.controls['ec_list'].markAsDirty();
    this.form.controls['cas_no'].markAsDirty();
    const changedFromValues = isFlag ? GetDirtyValues(this.form) : { cas_no: '', ec_list: '' };

    this._ingredientFacade.updateIngredient$(this.component.uuid, changedFromValues).subscribe({
      next: this._flagComponentSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _flagComponentSuccess(data: IIngredient): void {
    this._snackbarService.openTypeSnackbar(
      `Component ${data.name} is ${data.ec_list && data.cas_no ? 'flagged' : 'unflagged'} as chemical.`,
      NotificationType.success
    );
    this.dialogRef.close(true);
  }

  private _error(error: IProductComponent): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
