import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IBatchQuanityUnit, IBatchQuanityUnitResponse } from '@app/core/interface/batch.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { BatchFacade } from '@app/core/facade/batch.facade';
import { CreateUnitComponent } from './create-unit/create-unit.component';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-unit-management',
  templateUrl: './unit-management.component.html',
  styleUrls: ['./unit-management.component.scss'],
  providers: [BatchFacade],
})
export class UnitManagementComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  displayedColumns: string[] = ['unit_symbol', 'unit_name', 'actions'];
  tableDataSource = new MatTableDataSource<IBatchQuanityUnit>();
  tableView = true;
  needHelpTooltip!: boolean;
  show_deactivated = false;
  usedUnit!: boolean;
  unitList: Array<IBatchQuanityUnit> = [];
  allQuantityUnits!: IBatchQuanityUnit[];

  constructor(
    private _dialog: MatDialog,
    private _batchFacade: BatchFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getBatchQuantityUnits();
  }

  getBatchQuantityUnits() {
    this._batchFacade.getAllQuantityUnit$().subscribe({
      next: this._getBatchQuantityUnitSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _getBatchQuantityUnitSuccess(data: IBatchQuanityUnitResponse): void {
    this.allQuantityUnits = data.results;
    this.updateTableData();
  }

  updateTableData() {
    if (this.show_deactivated) {
      // Show all units, with inactive units first
      this.tableDataSource.data = this.allQuantityUnits.sort((a, b) => {
        if (a.is_active && !b.is_active) {
          return 1;
        } else if (!a.is_active && b.is_active) {
          return -1;
        } else {
          return 0; // no change in order
        }
      });
    } else {
      // Show only active units
      this.tableDataSource.data = this.allQuantityUnits.filter(unit => unit.is_active);
    }
    // Update paginator length
    if (this.paginator) {
      this.paginator.length = this.tableDataSource.data.length;
      this.paginator.pageSize = 12;
    }
  }

  ngAfterViewInit() {
    this.tableDataSource.sort = this.sort;
    this._batchFacade.getAllQuantityUnit$().subscribe({
      next: (data: IBatchQuanityUnitResponse) => {
        this.allQuantityUnits = data.results;
        this.tableDataSource.data = this.allQuantityUnits.filter(unit => unit?.is_active);
        // Set the paginator's length property after setting the data
        if (this.paginator) {
          this.paginator.length = this.tableDataSource.data.length;
          this.paginator.pageSize = 12;
        }
      },
      error: this._error.bind(this),
    });
  }

  _seccess(): void {
    this.getBatchQuantityUnits();
  }

  private _error(error: IBatchQuanityUnitResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  createUnit(is_editing: boolean, unit: IBatchQuanityUnit | string) {
    const dialogRef = this._dialog.open(CreateUnitComponent, {
      width: '1160px',
      height: '768px',
      panelClass: ['top-padding-0', 'overflow-hidden-dialog'],
      data: {
        is_editing: is_editing,
        unitList: this.allQuantityUnits,
        unit: unit,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getBatchQuantityUnits();
      }
    });
  }

  deactivateUnit(unit: IBatchQuanityUnit) {
    unit.is_assign_to_batch || unit.is_assign_to_ingredient_category || unit.is_assign_to_step
      ? (this.usedUnit = true)
      : (this.usedUnit = false);
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: this.usedUnit
          ? 'The selected unit is used in some element configurations.'
          : `Note that once you deactivate this unit, it won't be available as an option in the other screens that use this data.`,
        confirmationText: this.usedUnit
          ? 'The unit cannot be deactivated!'
          : 'Are you sure you want to deactivate this unit?',
        btnText: this.usedUnit ? 'Ok, Got It' : 'Deactivate Unit',
        type: this.usedUnit ? 'forbidden' : 'warning',
        text: this.usedUnit ? 'forbidden-text' : 'warning-text',
        usedUnit: this.usedUnit,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._batchFacade.updateQuantityUnit$(unit.uuid, { is_active: false }).subscribe({
          next: this._seccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  reactivateUnit(unit: IBatchQuanityUnit) {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `You are about to reactivate the selected unit. The unit will be available as an option in other screens that use this data.`,
        confirmationText: 'Do you wish to continue?',
        btnText: 'Reactivate Unit',
        type: 'info-actions',
        text: 'info-text',
        reactivate: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._batchFacade.updateQuantityUnit$(unit.uuid, { is_active: true }).subscribe({
          next: this._seccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  showDeactivatedUnit(event: MatCheckboxChange): void {
    this.show_deactivated = event.checked;
    this.paginator.pageIndex = 0;
    this.updateTableData();
  }

  mouseEvHandler(status: boolean) {
    status ? (this.needHelpTooltip = true) : (this.needHelpTooltip = false);
  }
}
