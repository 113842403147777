import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  IProduct,
  IProductComponent,
  IProductComponentsResponse,
  IProductResponse,
} from '@app/core/interface/products.interface';
import { NotificationType, PRODUCT_CHAINS, SUPPLIER, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { AddIngredientComponent } from './add-ingredient/add-ingredient.component';
import { FlagComponentAsChemicalComponent } from '../../../../shared/components/flag-component-as-chemical/flag-component-as-chemical.component';
import { IIngredient } from '@app/core/interface/ingredient.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { IngredientFacade } from '@app/core/facade/ingredient.facade';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-product-ingredients',
  templateUrl: './product-ingredients.component.html',
  styleUrls: ['./product-ingredients.component.scss'],
  providers: [ProductsFacade, IngredientFacade],
})
export class ProductProductIngredientsComponent implements OnInit, AfterViewInit {
  @Input() add!: boolean;
  @Input() product!: IProduct | IProductResponse;
  @Output() toggleAdd = new EventEmitter<boolean>(false);
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource: MatTableDataSource<IProductComponent> = new MatTableDataSource<IProductComponent>();
  productComponentsList: IProductComponent[] = [];
  ingredients = [];
  selectedIngredient = {};
  displayedColumns: string[] = ['name', 'reference', 'supplier', 'supplier_site_country', 'supply_chain_id', 'actions'];
  showInactiveComponents = false;

  constructor(
    private _dialog: MatDialog,
    private _productsFacade: ProductsFacade,
    private _ingredientFacade: IngredientFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this._productsFacade.getProductComponents$(this.product.uuid, 'is_active,name').subscribe({
      next: this._getProductComponentsSuccess.bind(this),
      error: this._error.bind(this),
    });

    // custom filter for searching string inside nested object properties
    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm: string, key: string) => {
        if (key === SUPPLIER) {
          return currentTerm + data.supplier?.name;
        } else if (key === PRODUCT_CHAINS && data.product_chains.length) {
          const concatValues: string = data.product_chains
            .map(chain => {
              return chain.reference;
            })
            .join('');

          return currentTerm + concatValues;
        } else {
          return currentTerm + data[key as keyof IProductComponent];
        }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'supplier':
          return item['supplier'].name;
        case 'supply_chain_id':
          return item['product_chains'][0].reference;
        case 'supplier_site_country':
          return item['manufactoring_location'];
        default:
          return item[property as keyof IProductComponent] as string;
      }
    };
  }

  applyFilter(event: Event) {
    applyFilter(event, this.dataSource);
  }

  onShowInactiveComponents(event: MatCheckboxChange) {
    if (event.checked) {
      this.showInactiveComponents = true;
      this.dataSource.data = this.productComponentsList;
    } else {
      this.showInactiveComponents = false;
      this.dataSource.data = this.productComponentsList.filter(component => component.is_active === true);
    }
  }

  onAddComponent() {
    const dialogRef = this._dialog.open(AddIngredientComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: this.product,
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.ngOnInit();
      }
    });
  }

  deactivateComponent(component: IProductComponent, isDeactivate: boolean) {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: 'Note that once you deactivate this component, you can reactivate it at the product component list.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, continue',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        component.is_active = isDeactivate;
        this._ingredientFacade.updateIngredient$(component.uuid, { is_active: !isDeactivate }).subscribe({
          next: this._componentStatusSuccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  reactivateComponent(component: IProductComponent, isDeactivate: boolean) {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: 'You are about to reactivate this component, to the list of product components.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, continue',
        type: 'info-actions',
        text: 'info-text',
        reactivate: true,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        component.is_active = isDeactivate;
        this._ingredientFacade.updateIngredient$(component.uuid, { is_active: !isDeactivate }).subscribe({
          next: this._componentStatusSuccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  onFlagAsChemical(component: IProductComponent) {
    const dialogRef = this._dialog.open(FlagComponentAsChemicalComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: component,
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.ngOnInit();
      }
    });
  }

  removeProductIngredient() {
    // console.log(uuid);
    // console.log(name);
  }
  cancel() {
    this.toggleAdd.emit(false);
  }
  createIngredientDialog() {
    // console.log('open create popup');
  }
  save() {
    // console.log(this.selectedIngredient);
  }

  private _getProductComponentsSuccess(data: IProductComponentsResponse): void {
    if (data.results) {
      // mapping data for table, reassigning empty values
      const mapData: IProductComponent[] = data.results.map(
        ({ supplier, manufactoring_location, product_chains, ...component }) => ({
          ...component,
          supplier: {
            uuid: supplier?.uuid,
            name: supplier ? supplier.name : 'No Supplier',
          },
          manufactoring_location: manufactoring_location || 'No Supplier Site',
          product_chains: product_chains.length
            ? product_chains
            : [{ reference: 'No Supply Chain', uuid: '', name: '' }],
        })
      );
      this.productComponentsList = mapData;

      if (this.showInactiveComponents) {
        this.dataSource.data = this.productComponentsList;
      } else {
        this.dataSource.data = this.productComponentsList.filter(component => component.is_active === true);
      }
      this.paginator.pageSize = 6;
      this.dataSource.sort = this.sort;
    }
  }

  private _componentStatusSuccess(data: IIngredient): void {
    this._snackbarService.openTypeSnackbar(
      `Component ${data.name} is ${data.is_active ? 'activated.' : 'deactivated.'}`,
      NotificationType.success
    );
    this.productComponentsList.forEach(component => {
      if (component.uuid === data.uuid) {
        component.is_active = data.is_active;
        if (this.showInactiveComponents) {
          this.dataSource.data = this.productComponentsList;
        } else {
          this.dataSource.data = this.productComponentsList.filter(component => component.is_active === true);
        }
        return;
      }
    });
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
