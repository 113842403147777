import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IPublicationSupplier,
  IPublicationSupplierListResponse,
} from '@app/core/interface/publication-management.interface';

import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { PublicationManagementFacade } from '@app/core/facade/publication-management.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SupplierSetupDialogComponent } from '@app/module/publication-management/publication-setup/suppliers-publication/supplier-setup-dialog/supplier-setup-dialog.component';

@Component({
  selector: 'app-supplier-publication-card',
  templateUrl: './supplier-publication-card.component.html',
  styleUrl: './supplier-publication-card.component.scss',
})
export class SupplierPublicationCardComponent {
  @Input() supplier?: IPublicationSupplier;
  @Output() refresh = new EventEmitter<boolean>();
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';

  constructor(
    private _dialog: MatDialog,
    private _publicationManagementFacade: PublicationManagementFacade,
    private _snackbarService: SnackbarService
  ) {}

  openSetupSupplier() {
    const dialogRef = this._dialog.open(SupplierSetupDialogComponent, {
      width: '1160px',
      height: '800px',
      panelClass: 'top-padding-0',
      data: {
        title: 'Supplier Setup',
        supplierUuid: this.supplier?.uuid,
        related_publicated_chain: this.supplier?.related_publicated_chain,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshSupplierData();
      }
    });
  }

  refreshSupplierData() {
    this._publicationManagementFacade?.getPublicationSuppliersList$().subscribe({
      next: this._getPublicationSuppliersListSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _getPublicationSuppliersListSuccess(data: IPublicationSupplierListResponse): void {
    if (data && data.results.length) {
      this.refresh.emit(true);
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}
