import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IProduct, IProductChain, IProductsListResponse } from '@app/core/interface/products.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { CreateProductDialogComponent } from '@app/shared/components/create-product-dialog/create-product-dialog.component';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { ProductsService } from '@app/core/service/products.service';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';
import { environment } from '@env/environment';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
  providers: [ProductsFacade],
})
export class ProductsListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  displayedColumns: string[] = ['name', 'description', 'product_chains', 'actions'];
  productsList: IProduct[] = [];
  cardDataSource = new MatTableDataSource<IProduct>();
  tableDataSource = new MatTableDataSource<IProduct>();
  productsObservable$!: Observable<IProduct[]>;
  assetPath = environment.assetPath;
  deleteUuid: string;
  has_chains!: boolean;
  has_batches!: boolean;
  is_active = true;
  archived = false;
  selectedIndex!: number;
  archived_tooltip!: boolean;
  show_deactivated = false;
  constructor(
    private _productsFacade: ProductsFacade,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _productsService: ProductsService,
    public dialog: MatDialog
  ) {
    this.deleteUuid = '';
  }

  ngOnInit(): void {
    this.getProducts();
  }

  getProducts(): void {
    this._productsFacade.getProducts$('is_active,name').subscribe({
      next: this._getProductsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  ngAfterViewInit() {
    this.cardDataSource.paginator = this.paginator;
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;
  }

  ngOnDestroy() {
    if (this.cardDataSource) {
      this.cardDataSource.disconnect();
    }
  }

  viewChange(value: string): void {
    if (value === 'tableView') {
      this._changeDetectorRef.detectChanges();
      this.tableDataSource.paginator = this.paginator;
      this.tableDataSource.sort = this.sort;
    } else {
      this.cardDataSource.paginator = this.paginator;
    }
  }

  applyFilter(event: Event): void {
    applyFilter(event, this.cardDataSource, this.tableDataSource);
  }

  openCreateDialog(productForCopy?: IProduct) {
    const config = productForCopy
      ? {
          width: '1160px',
          height: '768px',
          panelClass: ['overflow-hidden-dialog', 'top-padding-0'],
          data: { mode: 'duplicate', productId: productForCopy.uuid },
        }
      : { width: '1160px', height: '768px', panelClass: ['overflow-hidden-dialog', 'top-padding-0'] };
    const dialogRef = this.dialog.open(CreateProductDialogComponent, config);

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._productsService.checkActivProducts.next(true);
        this.ngOnInit();
      }
    });
  }

  private _getProductsSuccess(data: IProductsListResponse): void {
    if (data && data.results.length) {
      this.productsList = data.results;
      if (this.show_deactivated) {
        this.tableDataSource.data = this.productsList;
        this.cardDataSource.data = this.productsList;
        this.productsObservable$ = this.cardDataSource.connect();
      } else {
        this.cardDataSource.data = this.productsList.filter(product => product.is_active);
        this.tableDataSource.data = this.productsList.filter(product => product.is_active);
        this.productsObservable$ = this.cardDataSource.connect();
      }

      this._changeDetectorRef.detectChanges();
      this.paginator.pageSize = 12;
      this.tableDataSource.sort = this.sort;
      this.tableDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'product_chains':
            return item[property].length;
          case 'name':
          case 'description':
            return item[property].toUpperCase();
          default:
            return 1;
        }
      };
    } else {
      this._router.navigate(['/products/create-product']);
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  deactivateProduct(product: IProduct) {
    const incompleteBatches = product.batch_chains?.filter(el => el.status === 'IN PROGRESS');
    this.has_chains = !!product.product_chains.length;
    this.has_batches = !!incompleteBatches?.length;
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: this.has_chains
          ? 'The selected product has published chains, visible on respect-code platform.'
          : this.has_batches
            ? 'The selected product has incomplete batches.'
            : 'Note that once you deactivate this product all of the related projects, certificates & labels will also be deactivated. The product can be reactivated at anytime.',
        confirmationText:
          this.has_batches || this.has_chains
            ? 'The product cannot be deactivated!'
            : 'Are you sure you want to deactivate this product?',
        btnText: this.has_batches || this.has_chains ? 'Ok, Got It' : 'Deactivate Product',
        type: this.has_batches || this.has_chains ? 'forbidden' : 'warning',
        text: this.has_batches || this.has_chains ? 'forbidden-text' : 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._productsFacade.activateDeactivateProduct$(product.uuid, { is_active: false }).subscribe({
          next: this._seccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  reactivateProduct(product: IProduct) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `You are about to reactivate the selected product. Note that with reactivating the product, you are also reactivating all of the  associated projects, certificates & labels.`,
        confirmationText: 'Do you wish to continue?',
        btnText: 'Reactivate Product',
        type: 'info-actions',
        text: 'info-text',
        reactivate: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._productsFacade.activateDeactivateProduct$(product.uuid, { is_active: true }).subscribe({
          next: this._seccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  _seccess(): void {
    this._productsService.checkActivProducts.next(true);
    this.getProducts();
  }

  mouseEvHandler(status: boolean, index: number) {
    status ? (this.archived_tooltip = true) : (this.archived_tooltip = false);
    if (status) {
      this.selectedIndex = index;
    }
  }

  showDeactivatedProducts(event: MatCheckboxChange): void {
    this.show_deactivated = !this.show_deactivated;
    this.tableDataSource.data = !event.checked
      ? this.productsList?.filter(product => product?.is_active)
      : this.productsList;
    this.tableDataSource.paginator?.firstPage();
    this.cardDataSource.data = !event.checked
      ? this.productsList?.filter(product => product?.is_active)
      : this.productsList;
    this.cardDataSource.paginator?.firstPage();
    this.productsObservable$ = this.cardDataSource.connect();
  }

  countActiveChains(chains: IProductChain[]): number {
    return chains.filter(chain => chain.is_active).length;
  }
}
