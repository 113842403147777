import { Component, Input, OnInit } from '@angular/core';
import { NotificationType, Storage, USER } from '@app/core/constants';

import { AccountsService } from '@app/core/service/accounts.service';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { DataAuthService } from '@app/core/service/data-auth.service';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { IUser } from '@app/core/interface/login.interface';
import { Location } from '@angular/common';
import { LoginFacade } from '@app/core/facade/login.facade';
import { ManageProfileDialogComponent } from '@app/module/user-settings/manage-profile-dialog/manage-profile-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationFacade } from '@app/core/facade/translation.facade';
import { TranslationService } from '@app/core/service/translation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [TranslationFacade],
})
export class HeaderComponent implements OnInit {
  @Input() isChainSetup = false;
  @Input() isPublish = false;
  @Input() showWelcomeMessage = true;
  @Input() avatarUpdated = false;
  user?: IUser | null;
  isSupplierUser = false;
  numberOfBrands = 0;
  companyInfoUuid!: string;
  currentLang: string;

  constructor(
    private _router: Router,
    private _loginFacade: LoginFacade,
    private _dataAuthService: DataAuthService,
    public location: Location,
    private _facade: BrandsFacade,
    public translate: TranslateService,
    public translationService: TranslationService,
    private _dialog: MatDialog,
    private _dataStorageService: DataStorageService,
    private _accountsService: AccountsService,
    private _snackbarService: SnackbarService
  ) {
    this.currentLang = this.translate.currentLang;
  }

  ngOnInit() {
    this.user = this._dataAuthService.user;
    this.user = typeof this.user === 'string' ? JSON.parse(this.user) : this.user;
    this.isSupplierUser = this.user?.is_supplier as boolean;
    this._facade.getNumberOfBrands$().subscribe(brandsCount => {
      this.numberOfBrands = brandsCount || 0;
    });

    if (!this.user?.avatar && !this.user?.function) {
      const storedUser: IUser = JSON.parse(this._dataStorageService.get(USER, Storage.local));
      this.user!.avatar = storedUser?.avatar;
      this.user!.function = storedUser?.function;
    }
    this._dataAuthService.updateUserAvatar$.subscribe(data => {
      if (data) {
        this.getUpdatedUser();
      }
    });
  }

  setLangueage(language: string) {
    this.currentLang = language;
    this.translationService.language.next(language);
  }

  getUpdatedUser(): void {
    const storedUser: IUser = JSON.parse(this._dataStorageService.get(USER, Storage.local));
    this.user = storedUser;
  }

  logout() {
    // first check if there are unsaved changes in app-edit-step
    const changesUnsaved: boolean = this._accountsService.getIsSaved();
    if (changesUnsaved) {
      // if there is unsaved changes in app-edit-step we only try to navigate on login how can we activate alert if leaving page without marking step as 'Updated'
      this._router.navigate(['/login']);
    } else {
      this._loginFacade.logout$().subscribe({
        next: () => this._router.navigate(['/login']),
        error: this._error.bind(this),
      });
    }
  }

  /**
   *
   * @param error hadles HTTP error desplaying message from backend
   */

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  manageProfile(user?: IUser | null): void {
    const dialogRef = this._dialog.open(ManageProfileDialogComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        user: user,
      },
    });

    dialogRef.afterClosed().subscribe((result: IUser) => {
      if (result) {
        this.user = this._dataAuthService.user = result;
        this._dataStorageService.set(USER, JSON.stringify(this.user), Storage.local);
      }
    });
  }
}
