import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICountry, ISupplierResponse, ISuppliersErrorResponse } from '@app/core/interface/suppliers.interface';

import { AddFactoryGroup } from '@app/module/supplier-management/add-factory/add-factory.group';
import { CountryList } from '@app/core/utils/country-list';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { ISuppSupplier } from '@app/core/interface/supp.interface';
import { ManufactoriesFacade } from '@app/core/facade/manufactories.facade';
import { NotificationType } from '@app/core/constants';
import { Observable } from 'rxjs/internal/Observable';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { UntypedFormGroup } from '@angular/forms';
import { map } from 'rxjs/internal/operators/map';
import { startWith } from 'rxjs/internal/operators/startWith';

@Component({
  selector: 'app-create-site-form',
  templateUrl: './create-site-form.component.html',
  styleUrls: ['./create-site-form.component.scss'],
  providers: [AddFactoryGroup, ManufactoriesFacade, SuppliersFacade],
})
export class CreateSiteFormComponent implements OnInit {
  /**
   * isSupplier is flag for supplier account
   */
  @Input() isSupplier = false;
  @Input() site: IManufactory | undefined;
  @Input() supplierUuid!: string;
  @Input() suppSupplier!: ISuppSupplier | undefined;
  @Output() siteCreated = new EventEmitter<IManufactory | null>();
  supplier!: ISupplierResponse;
  form!: UntypedFormGroup;
  filteredCountries!: Observable<ICountry[]>;
  countryList = CountryList.isoCountries;

  constructor(
    private _group: AddFactoryGroup,
    private _facade: ManufactoriesFacade,
    private _snackbarService: SnackbarService,
    private _suppliersFacade: SuppliersFacade,
    private _suppSuppliersFacade: SuppSuppliersFacade
  ) {
    this.form = this._group.addFactory;
  }

  ngOnInit(): void {
    if (this.isSupplier) this.form = this._group.addFactorySupplier;
    if (this.supplierUuid && !this.isSupplier) {
      this.form.get('supplier')?.setValue(this.supplierUuid);
      this.form.get('supplier')?.markAsDirty();
      this._suppliersFacade.getSupplier$(this.supplierUuid).subscribe({
        next: this._setSupplierResponse.bind(this),
        error: this._error.bind(this),
      });
    }
    if (this.site && this.isSupplier) {
      this._setSupplierSite(this.site);
    }
    this.filteredCountries = this.form.controls['country'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter((typeof value === 'string' && value) || ''))
    );
  }

  displayFn(subject: ICountry | null): string {
    return subject?.name ?? '';
  }

  createSupplierSite(): void {
    if (this.form.valid && this.isSupplier && this.site) {
      this.form.controls['name'].markAsDirty();
      this.form.controls['country'].markAsDirty();
    }
    const changedFromValues = GetDirtyValues(this.form);
    if (changedFromValues['country']) {
      changedFromValues['country'] = this.form.controls['country'].value.code;
    }

    if (this.form.valid && !this.isSupplier) {
      this._facade.createSupplierSite$(changedFromValues).subscribe({
        next: this._createSupplierSiteSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
    if (this.form.valid && this.isSupplier && !this.site) {
      this._suppSuppliersFacade.createSupplierSite$(changedFromValues).subscribe({
        next: this._createSupplierSiteSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
    if (this.form.valid && this.isSupplier && this.site) {
      this._suppSuppliersFacade.updateSupplierSite$(changedFromValues, this.site.uuid).subscribe({
        next: this._updateSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  toggleMainAddress() {
    const isMainAddressControl = this.form.get('is_main_location');
    if (isMainAddressControl && isMainAddressControl.value === true) return isMainAddressControl?.markAsDirty();
    if (isMainAddressControl) {
      isMainAddressControl.setValue(true);
    }
    isMainAddressControl?.markAsDirty();
  }

  private _setSupplierSite(site: IManufactory) {
    this.form.controls['address'].setValue(site.address ?? '');
    this.form.controls['city'].setValue(site.city ?? '');
    this.form.controls['country'].setValue(CountryList.getCountryByName(site.country) ?? '');
    this.form.controls['description'].setValue(site.description ?? '');
    this.form.controls['is_main_location'].setValue(!!site.is_main_location);
    this.form.controls['logo'].setValue(site.logo ?? '');
    this.form.controls['name'].setValue(site.name ?? '');
    this.form.controls['region'].setValue(site.region ?? '');
  }
  private _updateSuccess() {
    if (this.site)
      this.siteCreated.emit({
        ...this.form.value,
        uuid: this.site.uuid,
        country: this.form.controls['country'].value.name,
      });
  }
  private _setSupplierResponse(response: ISupplierResponse) {
    this.supplier = response;
  }

  private _createSupplierSiteSuccess(data: IManufactory): void {
    this._snackbarService.openTypeSnackbar(`Site ${data.name} created.`, NotificationType.success);
    this.siteCreated.emit(data);
  }

  private _filter(value: string): ICountry[] {
    const filterValue = value && value.toLowerCase();

    return this.countryList.filter(country => country.name.toLowerCase().includes(filterValue));
  }

  private _error(error: IManufactory | ISuppliersErrorResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
