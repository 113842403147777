import { Component, OnInit } from '@angular/core';
import { IBrandListResult, IBrandsListResponse } from '@app/core/interface/brands.interface';
import { NotificationType, Storage, USER } from '@app/core/constants';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { IProduct } from '@app/core/interface/products.interface';
import { Location } from '@angular/common';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-brand-preview',
  templateUrl: './brand-preview.component.html',
  styleUrls: ['./brand-preview.component.scss'],
})
export class BrandPreviewComponent implements OnInit {
  productList!: IProduct[];
  selectedBrand!: IBrandListResult;
  respectCodeUrl = environment.respectCodeUrl;

  constructor(
    private _brandsFacade: BrandsFacade,
    private _productsFacade: ProductsFacade,
    private _snackbarService: SnackbarService,
    private _dataStorageService: DataStorageService,
    private location: Location
  ) {}

  ngOnInit() {
    this._productsFacade.getPublicProducts$().subscribe({
      next: this._getProductsSucess.bind(this),
      error: this._error.bind(this),
    });

    this._brandsFacade.getBrands$().subscribe({
      next: this._getBrandsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  openBrandWebsite(): void {
    window.open(`${this.respectCodeUrl}/brand/${this.selectedBrand.slug}`, '_blank');
  }

  goBack(): void {
    this.location.back();
  }

  private _getBrandsSuccess(data: IBrandsListResponse): void {
    const storedUser = this._dataStorageService.get(USER, Storage.local)
      ? this._dataStorageService.get(USER, Storage.local)
      : this._dataStorageService.get(USER, Storage.session);

    this.selectedBrand = data.results.find(brand => brand.uuid === JSON.parse(storedUser).brand) ?? data.results[0];
  }

  private _getProductsSucess(data: IProduct[]): void {
    this.productList = data.filter(product => product.is_display_on_brand_page === true);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
