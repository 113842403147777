import { Component, Input } from '@angular/core';
import { IQuestionnaireErrorResponse, IQuestionnaireSection } from '@interface/questionnaire.interface';

import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { QuestionnaireFacade } from '@facade/questionnaire.facade';
import { QuestionnaireNameEditModalComponent } from '@module/questionnaires-management/questionnaire-name-edit-modal/questionnaire-name-edit-modal.component';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-section-question',
  templateUrl: './section-question.component.html',
  styleUrls: ['./section-question.component.scss'],
})
export class SectionQuestionComponent {
  @Input() section!: IQuestionnaireSection;
  @Input() questionnaireUuid!: string;
  sectionExpanded = false;
  @Input() isCreate = false;
  id = 0;

  constructor(
    private _dialog: MatDialog,
    private _snackbarService: SnackbarService,
    private questionnaireFacade: QuestionnaireFacade
  ) {}

  expandSection() {
    this.sectionExpanded = !this.sectionExpanded;
  }

  editName(name: string) {
    const dialogRef = this._dialog.open(QuestionnaireNameEditModalComponent, {
      data: {
        title: 'Edit Section Name',
        data: name,
      },
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        !this.isCreate
          ? this.questionnaireFacade
              .updateQuestionnaireSectionDetails$(this.section.uuid, this.questionnaireUuid, result)
              .subscribe(
                result => {
                  this.section.name = result.name;
                },
                error => this._error(error)
              )
          : this.questionnaireFacade.createQuestionnaireSection$(this.questionnaireUuid, result).subscribe(
              result => {
                this.section.name = result.name;
                this.section.uuid = result.uuid;
              },
              error => this._error(error)
            );
      }
    });
  }

  private _error(error: IQuestionnaireErrorResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  questionDeleted($event: string) {
    this.section.questions = this.section.questions.filter(x => x.uuid != $event);
  }

  createQuestion() {
    if (this.section.uuid !== '') {
      this.id++;
      this.section.questions = Array.from(this.section.questions);
      this.section.questions.push({ name: '', answer: '', uuid: this.id.toString(), submitted: false });
    } else {
      this._snackbarService.openTypeSnackbar(`Please add name to the section`, NotificationType.success);
    }
  }
}
