import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IBatchQuanityUnit, IBatchQuanityUnitResponse } from '@app/core/interface/batch.interface';
import { IFactory, ISupplier, ISupplierIngredient } from '@app/core/interface/suppliers.interface';
import { ILanguage, ILanguagesResponse } from '@app/core/interface/languages.interface';
import { INode, IProductChainLink, IProductChainResult } from '@app/core/interface/productchain.interface';
import { IProductChainStep, IProductChainStepErrorResponse } from '@app/core/interface/steps.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  NotificationType,
  OFFICE_FILENAME_EXTENSIONS,
  infoDialogHeight,
  infoDialogWidth,
  uploadFileSize,
} from '@app/core/constants';

import { AddDocumentsAndMediasComponent } from '@app/shared/components/add-documents-and-medias/add-documents-and-medias.component';
import { BatchFacade } from '@app/core/facade/batch.facade';
import { DatePipe } from '@angular/common';
import { DocumentsCertificatesComponent } from '../documents-certificates/documents-certificates.component';
import { FileViewComponent } from '@app/shared/components/file-view/file-view.component';
import { IEmailVarValues } from '@app/core/interface/email.interface';
import { IMedia } from '@app/core/interface/medias.interface';
import { IPhase } from '@app/core/interface/phase-management.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { InviteUsersDialogComponent } from '@app/shared/components/invite-users-dialog/invite-users-dialog.component';
import { LanguagesFacade } from '@app/core/facade/languages.facade';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NodeService } from '@app/core/service/node.service';
import { Observable } from 'rxjs';
import { ProductChainGroup } from '../product-chain.group';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { StepsFacade } from '@app/core/facade/steps.facade';
import { StepsService } from '@app/core/service/steps.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-batch',
  templateUrl: './step-batch.component.html',
  styleUrls: ['./step-batch.component.scss'],
  providers: [ProductChainGroup, StepsFacade, DatePipe],
})
export class StepCompleteComponent implements OnInit {
  @Input() productChain?: IProductChainResult;
  @Input() isChainComplete!: boolean;
  @Input() suppliers?: ISupplier[];
  @Input() phases?: IPhase[];
  @Input() variables!: IEmailVarValues;
  @Output() closeDialog = new EventEmitter<boolean>();
  form: UntypedFormGroup;
  selectedStepUuid: string | undefined;
  stepData!: IProductChainStep;
  batchQuantityUnits!: IBatchQuanityUnit[];
  filtredBatchQuantityUnits!: IBatchQuanityUnit[];
  documentsDialog?: MatDialogRef<DocumentsCertificatesComponent>;
  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;
  opened = false;
  photos?: IMedia[];
  videos?: IMedia[];
  filteredSuppliers!: Observable<ISupplier[] | undefined>;
  selectedSupplier?: ISupplier;
  manufactories!: IFactory[];
  ingredients!: ISupplierIngredient[];
  proofs?: IMedia[];
  showReadMore = false;

  docURL = '';
  docURLTransformed: SafeResourceUrl = '';
  fileName? = '';

  viewer: 'url' | 'office' = 'office';
  warn = false;

  languages: ILanguage[] = [];
  isChainCompleted = false;

  constructor(
    private _group: ProductChainGroup,
    private _stepsFacade: StepsFacade,
    private _batchFacade: BatchFacade,
    private nodeService: NodeService,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _stepService: StepsService,
    public datepipe: DatePipe,
    public sanitizer: DomSanitizer,
    private languagesFacade: LanguagesFacade
  ) {
    this.form = this._group.batchStepForm;

    this.nodeService.completeStepData$.subscribe((node: INode | null) => {
      if (node) {
        this.selectedStepUuid = node.uuid ? node.uuid : node.id;
        if (this.selectedStepUuid) {
          this.getStep(this.selectedStepUuid);
        }
      }
    });
  }

  ngOnInit() {
    this._batchFacade.getActiveQuantityUnit$().subscribe({
      next: this._getBatchQuantityUnitSuccess.bind(this),
      error: this._error.bind(this),
    });

    this._stepService.refetchStepDetails$.subscribe(data => {
      if (data && this.selectedStepUuid) {
        this.getStep(this.selectedStepUuid);
      }
    });

    this.getLanguages();
  }

  getStep(uuid: string): void {
    this._stepsFacade.getStep$(uuid, true).subscribe({
      next: this.getStepSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  getStepSuccess(data: IProductChainStep): void {
    this.stepData = data;

    if (data.is_active === false) {
      this.isChainCompleted = true;
    } else {
      this.isChainCompleted = data.isChainComplete === true ? true : (this.isChainCompleted = this.isChainComplete);
    }

    this.proofs = data.proofs;
    this.photos = data.medias?.filter((media: IMedia) => media.mimetype.indexOf('image') > -1);
    this.videos = data.medias?.filter((media: IMedia) => media.mimetype.indexOf('video') > -1);

    this.form.patchValue({
      name: data.name ?? '',
      reference: data.reference ? data.reference : '',
      quantity: data.quantity ? data.quantity : '',
      quantity_unit: data.quantity_unit?.uuid ? data.quantity_unit.uuid : '',
      date_completion: data.date_completion ? data.date_completion : '',
      step_operator_name: data.step_operator_name ? data.step_operator_name : '',
      description: data.description ?? '',
      supplier: data.supplier,
      manufactories: data.manufactories?.uuid ?? '',
    });

    this.form.disabled;

    if (this.documentsDialog) {
      this.documentsDialog.componentInstance.data.step = data;
    }
    this.manufactories = data.supplier?.manufactories as IFactory[];

    // For some steps the manufactory is not added into the supplier manufactories.
    if (!this.manufactories?.some(site => site.uuid === data.manufactories.uuid))
      this.manufactories.push(data.manufactories as IFactory);

    // TEMPORALLY HIDDEN FOR SUPPLIER DROPDOWN MENU
    // this.filteredSuppliers = this.form.controls['supplier'].valueChanges.pipe(
    //   startWith(''),
    //   map(value => {
    //     if (typeof value == 'string') {
    //       return value ? this._filter(value as string) : this.suppliers?.slice();
    //     }
    //     return this.suppliers?.slice();
    //   })
    // );
  }

  updateStep(): void {
    if (this.form.valid && this.selectedStepUuid) {
      const submitForm = { ...this.form.value, supplier_manufactory: this.form.value['manufactories'] };
      if (submitForm && submitForm['date_completion'])
        submitForm['date_completion'] =
          this.datepipe.transform(new Date(submitForm['date_completion']), 'yyyy-MM-dd HH:mm') ?? '';

      this._stepsFacade.updateBatchStep$(submitForm, this.selectedStepUuid).subscribe({
        next: this._updateStepSuccess.bind(this),
        error: this._updateStepError.bind(this),
      });
    }
  }
  updateStepAndOpenIvite(): void {
    if (this.form.valid && this.selectedStepUuid) {
      const submitForm = { ...this.form.value, supplier_manufactory: this.form.value['manufactories'] };
      if (submitForm && submitForm['date_completion'])
        submitForm['date_completion'] =
          this.datepipe.transform(new Date(submitForm['date_completion']), 'yyyy-MM-dd HH:mm') ?? '';
      this._stepsFacade.updateBatchStep$(submitForm, this.selectedStepUuid).subscribe({
        next: this.inviteProcess.bind(this),
        error: this._updateStepError.bind(this),
      });
    }
  }
  inviteProcess(): void {
    this.form.markAsPristine();
    this.openEmailDialog();
  }
  // markFieldsAsPristine(): void {
  //   this.form.get('quantity')?.markAsPristine();
  //   this.form.get('quantity_unit')?.markAsPristine();
  //   }
  // dirtyFields(): boolean {
  //   return (
  //     this.form.get('quantity')?.dirty || this.form.get('quantity_unit')?.dirty || this.stepData.proofs?.length == 0
  //   );
  // }
  completeStep(): void {
    this.form.patchValue({
      status: 'COMPLETED',
    });

    if (this.form.valid && this.selectedStepUuid) {
      this._stepsFacade.updateBatchStep$({ ...this.form.value }, this.selectedStepUuid).subscribe({
        next: this._updateStepSuccess.bind(this),
        error: this._updateStepError.bind(this),
      });
    }
  }

  manageDocumentsCertificates(): void {
    this.documentsDialog = this._dialog.open(DocumentsCertificatesComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        product_chain: this.productChain?.uuid,
        step: this.stepData,
        suppliers: this.suppliers,
      },
    });
  }

  private _getBatchQuantityUnitSuccess(data: IBatchQuanityUnitResponse): void {
    this.batchQuantityUnits = data.results;
    this.filtredBatchQuantityUnits = data.results;
  }

  private _updateStepSuccess(data: IProductChainStep): void {
    this.form.markAsPristine();
    this.nodeService.completeStepData.next(null);
    this._snackbarService.openTypeSnackbar(`Step ${data.name} updated`, NotificationType.success);
  }

  private _updateStepError(error: IProductChainStepErrorResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  private _error(error: IBatchQuanityUnitResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  openOrClosePanel(evt: Event, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
      this.opened = false;
    } else {
      trigger.openPanel();
      this.opened = true;
    }
  }

  onClosedEvent() {
    this.opened = false;
  }

  isOpened() {
    this.opened = true;
  }

  displayFn(subject: IBatchQuanityUnit | null): string {
    return subject?.name ?? '';
  }

  filterItem(event: Event | KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.batchQuantityUnits = this.filtredBatchQuantityUnits;
    } else {
      this.batchQuantityUnits = this.filtredBatchQuantityUnits.filter(el =>
        el.name.toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  getLanguages(): void {
    this.languagesFacade.getLanguages$().subscribe({
      next: this._setLanguagesResponse.bind(this),
      error: this._error.bind(this),
    });
  }

  _setLanguagesResponse(languageResponse: ILanguagesResponse) {
    this.languages = languageResponse.results;
  }

  openEmailDialog(): void {
    // A supplier of a batch step can’t be invited if “Quantity” or “Unit” or “at least 1 proof” are not set
    if (
      this.form.get('quantity')?.value == '' ||
      this.form.get('quantity_unit')?.value === '' ||
      this.stepData.proofs?.length == 0
    ) {
      this._dialog.open(InfoDialogComponent, {
        width: infoDialogWidth,
        height: infoDialogHeight,
        data: {
          infoText: `Please note that the minimum requirements for inviting this step's supplier are not met`,
          confirmationText: 'To proceed with the invite please first enter quantity, unit and at least one proof.',
          btnText: 'Ok, got it',
          type: 'forbidden-info',
          text: 'forbidden-text',
          reactivate: true,
        },
      });

      return;
    }
    // Save cheanged data dialog should appier in order to proceed with invite processif form is dirty
    if (this.form.dirty) {
      const dialog = this._dialog.open(InfoDialogComponent, {
        width: infoDialogWidth,
        height: infoDialogHeight,
        data: {
          infoText: `Your step is not updated with the added quantity and proof documents.`,
          confirmationText: 'In order to proceed with the invitation, please update the step.',
          btnText: 'Update step',
          type: 'info-actions',
          text: 'forbidden-text',
          reactivate: true,
        },
      });
      dialog.afterClosed().subscribe(res => {
        if (res) this.updateStepAndOpenIvite();
      });

      return;
    }
    const supplierNameValue = this.getConnectedSupplierNames();
    const invDialog = this._dialog.open(InviteUsersDialogComponent, {
      width: '1156px',
      height: 'calc(100% - 100px)',
      autoFocus: false,
      panelClass: ['email-dialog-container', 'padding-0'],
      data: {
        languages: this.languages,
        uuid: this.stepData.supplier?.uuid,
        variables: { ...this.variables, 'Supplier name': supplierNameValue, 'Step name': this.stepData.name },
        supplier: this.stepData.supplier,
        step: this.selectedStepUuid,
      },
    });
    invDialog.afterClosed().subscribe(res => {
      if (res) {
        this.getStep(this.selectedStepUuid ?? '');
      }
    });
  }

  getConnectedSupplierNames(): string | null {
    const connectedStepUuids = this.productChain?.links_steps
      .filter((step: IProductChainLink) => step.step_from === this.selectedStepUuid)
      .map((step: IProductChainLink) => step.step_to);

    if (!connectedStepUuids || connectedStepUuids.length === 0) {
      return null;
    }
    const supplierNames = this.productChain?.steps
      .filter((step: IProductChainStep) => {
        return step.supplier !== undefined && connectedStepUuids.includes(step.uuid);
      })
      .map((step: IProductChainStep) => step.supplier && step.supplier.name);

    if (!supplierNames || supplierNames.length === 0) {
      return null;
    }
    if (supplierNames[0] && supplierNames.length === 1) {
      return supplierNames[0];
    }
    const lastSupplierName = supplierNames.pop();
    return `${supplierNames.join(', ')} & ${lastSupplierName}`;
  }

  onSupplierChange(uuid: string | undefined): void {
    this.selectedSupplier = this.suppliers?.find(supplier => supplier.uuid === uuid);
    this.manufactories = this.selectedSupplier?.manufactories ? this.selectedSupplier?.manufactories : [];

    this.ingredients = this.selectedSupplier?.ingredients ? this.selectedSupplier?.ingredients : [];

    this.form.controls['manufactories'].reset('', false);
    this.form.controls['ingredient'].reset('', false);
    this.form.controls['manufactories'].markAsPristine();
    this.form.controls['ingredient'].markAsPristine();
  }

  onSupplierSiteChange(): void {
    this.ingredients = this.selectedSupplier?.ingredients
      ? this.selectedSupplier?.ingredients.filter(
          ingredient => ingredient.manufactory?.uuid === this.form.controls['manufactories'].value
        )
      : [];
    this.form.controls['ingredient'].reset('', false);
    this.form.controls['ingredient'].markAsPristine();
  }

  private _filter(value: string): ISupplier[] | undefined {
    const filterValue = value.toLowerCase();

    return this.suppliers?.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const file: File | null = inputElement.files && inputElement.files[0];
    this.fileName = this.docURL = this.docURLTransformed = '';

    if (file) {
      this.fileName = file.name;
      if (file.size > uploadFileSize) {
        this.warn = true;
      } else {
        this.setView(this.fileName);
        file.arrayBuffer().then(arrayBuffer => {
          const blob = new Blob([new Uint8Array(arrayBuffer)], {
            type: file.type,
          });
          this.docURL = URL.createObjectURL(blob);
          this.docURLTransformed = this.transformTrustURL(this.docURL);
        });
        this.saveDocumentData(file);
      }
    } else {
      this.saveDocumentData(undefined);
    }
  }

  saveDocumentData(file?: File): void {
    if (file) {
      const proofRequest = {
        file: file,
        name: this.fileName,
      };

      this._stepsFacade.addBatchStepProof(this.stepData.uuid, proofRequest).subscribe({
        next: this.addProofData.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  addProofData(data: IMedia) {
    this.proofs?.push(data);
  }

  transformTrustURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  setView(filename: string) {
    const extIndex = filename.split('.').length - 1;
    const ext = filename.split('.')[extIndex];
    if (OFFICE_FILENAME_EXTENSIONS.includes(ext)) {
      this.viewer = 'office';
    } else {
      this.viewer = 'url';
    }
  }

  openDocument(file: IMedia) {
    let mimetype;
    const extIndex = file.name.split('.').length - 1;
    const ext = file.name.split('.')[extIndex];
    if (OFFICE_FILENAME_EXTENSIONS.includes(ext)) {
      mimetype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else {
      mimetype = 'application/pdf';
    }

    this._dialog.open(FileViewComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'padding-0',
      data: {
        url: file.file,
        name: file.name,
        mimetype: mimetype,
        created_at: file.created_at,
        isBatch: true,
        backTitle: 'Step Details',
      },
    });
  }

  editDocument(doc: IMedia) {
    const dialogRef = this._dialog.open(AddDocumentsAndMediasComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'padding-0',
      data: {
        screen: 'proofs',
        type: 'batchsteps',
        typeUuid: this.stepData.uuid,
        doc: doc,
        edit: true,
      },
    });

    dialogRef.afterClosed().subscribe((doc: IMedia) => {
      if (doc) {
        const index = this.proofs?.findIndex(obj => obj.uuid === doc.uuid);

        if (index !== null && index !== undefined) {
          this.proofs?.splice(index, 1);
          this.proofs?.push(doc);
        }
      }
    });
  }

  removeDocument(document: IMedia) {
    if (document.uuid != null) {
      this._stepsFacade.removeBatchStepProof(this.stepData.uuid, document.uuid).subscribe(() => {
        this._removeSuccess(document.uuid);
      });

      return;
    }
  }

  _removeSuccess(docId: string) {
    const index = this.proofs?.findIndex(obj => obj.uuid === docId);
    if (index !== null && index !== undefined) {
      this.proofs?.splice(index, 1);
      this._snackbarService.openTypeSnackbar(`Proof was deleted successfully`, NotificationType.success);
    }
  }

  removeProofDialog(document: IMedia): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to delete this proof. Please note that if you delete the selected proof, the changes cannot be undone.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result && document.uuid != null) {
        this.removeDocument(document);
      }
    });
  }

  toggleText() {
    this.showReadMore = !this.showReadMore;
  }
}
