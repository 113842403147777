import { Component, OnInit } from '@angular/core';
import { NotificationType, USER } from '@app/core/constants';

import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { ICompleteProfileResponse } from '@interface/register.interface';
import { ISuppCompanyResponse } from '@interface/supp.interface';
import { IUser } from '@app/core/interface/login.interface';
import { RegisterFacade } from '@facade/register.facade';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { Storage } from '@app/core/constants';
import { SuppSuppliersFacade } from '@facade/supp-suppliers.facade';

@Component({
  selector: 'app-supplier-landing',
  templateUrl: './supplier-landing.component.html',
  styleUrls: ['./supplier-landing.component.scss'],
  providers: [RegisterFacade],
})
export class SupplierLandingComponent implements OnInit {
  storedUser: IUser = {
    first_name: '',
    last_name: '',
    role: '',
    phone: '',
    avatar: '',
    username: '',
    uuid: '',
    user_permissions: [],
  };

  account!: ICompleteProfileResponse;

  constructor(
    private _dataStorageService: DataStorageService,
    private _snackbarService: SnackbarService,
    private _registerFacade: RegisterFacade,
    private _supplierFacade: SuppSuppliersFacade,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.storedUser = this._dataStorageService.get(USER, Storage.local)
      ? JSON.parse(this._dataStorageService.get(USER, Storage.local))
      : JSON.parse(this._dataStorageService.get(USER, Storage.session));

    this.storedUser.uuid &&
      this._supplierFacade.getCompany().subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
  }
  private _success(response: ISuppCompanyResponse): void {
    if (!response.info_validated && response.info_validated_on == null) {
      this.router.navigateByUrl('/company-info');
    }
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
  supplierUpdated($event: ICompleteProfileResponse) {
    this.storedUser = $event;
  }
}
