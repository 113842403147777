import { Component, OnInit, ViewChild, inject } from '@angular/core';

import { ISupplyChain } from '@app/core/interface/language-management.interface';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import { LanguageManagementService } from '@app/core/service/language-management.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-language-management',
  templateUrl: './language-management.component.html',
  styleUrl: './language-management.component.scss',
  providers: [LanguageManagementFacade],
})
export class LanguageManagementComponent implements OnInit {
  opened = false;
  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;
  @ViewChild('tabs') tabs!: MatTabGroup;

  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _languageManagementService = inject(LanguageManagementService);

  supplyChains: ISupplyChain[] = [];
  selectedSupplyChain: ISupplyChain = { name: '', uuid: '' };

  ngOnInit(): void {
    this._languageManagementFacade.getLanguageManagementSupplyChains$().subscribe(response => {
      this.supplyChains = response.results;
    });
  }

  onSupplyChainChange(supplyChain: ISupplyChain | null): void {
    this.selectedSupplyChain = supplyChain ?? { name: '', uuid: '' };
    this._languageManagementService.supplyChainUuidFilter.set(this.selectedSupplyChain.uuid);
    this._languageManagementService.supplyChainParamsFilter.set(`?supplychain_uuid=`);
    this.tabs.selectedIndex = 0;
  }

  displayFn(chain: ISupplyChain | null): string {
    return chain?.name ?? '';
  }

  onOpenOrClosePanel(evt: Event, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
      this.opened = false;
    } else {
      trigger.openPanel();
      this.opened = true;
    }
  }

  onClearSelection(trigger: MatAutocompleteTrigger): void {
    this.selectedSupplyChain = { name: '', uuid: '' };
    this._languageManagementService.supplyChainUuidFilter.set('');
    this._languageManagementService.supplyChainParamsFilter.set('');
    this.tabs.selectedIndex = 0;
    this.ngOnInit();
    trigger.closePanel();
  }
}
