import { Component, inject } from '@angular/core';

import { ILanguageManagementBrandsResponse } from '@app/core/interface/language-management.interface';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-publication-management',
  templateUrl: './publication-management.component.html',
  styleUrl: './publication-management.component.scss',
  providers: [LanguageManagementFacade],
})
export class PublicationManagementComponent {
  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _snackbarService = inject(SnackbarService);
  isAdditionalLanguages = true;

  ngOnInit(): void {
    this.getLanguageManagementBrands();
  }

  getLanguageManagementBrands(): void {
    this._languageManagementFacade.getLanguageManagementBrands$().subscribe({
      next: this._getLanguageManagementBrandsListSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _getLanguageManagementBrandsListSuccess(data: ILanguageManagementBrandsResponse): void {
    if (data && data.results.length) {
      this.isAdditionalLanguages = data.results[0].is_additional_languages;
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}
