import { Component, Input, SimpleChanges } from '@angular/core';
import { ICertificationProduct, IProduct } from '@app/core/interface/products.interface';
import { ICertificationSupplier, ISupplier } from '@app/core/interface/suppliers.interface';
import { IProductChainErrorResponse, IProductChainResult } from '@app/core/interface/productchain.interface';
import { IProductChainStep, IStepCertification, IStepSiteBatchCertificate } from '@app/core/interface/steps.interface';

import { FileViewComponent } from '@app/shared/components/file-view/file-view.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { OnChanges } from '@angular/core';
import { ProductChainFacade } from '@app/core/facade/productchain.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-certificates-tab',
  templateUrl: './certificates-tab.component.html',
  styleUrls: ['./certificates-tab.component.scss'],
  providers: [ProductChainFacade],
})
export class CertificatesTabComponent implements OnChanges {
  @Input() suppliers?: ISupplier[];
  @Input() product?: IProduct;
  @Input() product_chain?: string;
  @Input() selectedStep?: IProductChainStep;
  supplierCertificates?: ICertificationSupplier[] = [];
  stepCertificates?: IStepCertification[] = [];

  constructor(
    private _dialog: MatDialog,
    private _productChainFacade: ProductChainFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['suppliers']) {
      this.suppliers?.map(supplier => {
        if (supplier.certification_supplier?.length) {
          supplier.certification_supplier.filter(certificate =>
            this.supplierCertificates?.push(this._matchSupplierName(certificate))
          );
        }
      });
    }
    if (changes['product']) {
      this.product = changes['product'].currentValue;
    }
    if (changes['product_chain'] && this.product_chain) {
      this._productChainFacade.getProductChain$(this.product_chain).subscribe({
        next: this._getProductChainSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
    if (changes['selectedStep']) {
      this._formatCertificates();
    }
  }

  viewFile(certificate: ICertificationProduct | ICertificationSupplier | IStepCertification) {
    this._dialog.open(FileViewComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'padding-0',
      data: {
        url: certificate.document,
        name: certificate.certificate.name,
        mimetype: certificate.document?.split('/').at(-1),
        created_at: certificate.validity_start,
        isBatch: true,
        backTitle: this.selectedStep ? 'Certificates & Documents' : 'Creating Batch',
      },
    });
  }

  private _matchSupplierName(certificate: ICertificationSupplier): ICertificationSupplier {
    const findSupplier = this.suppliers?.find(supplier => supplier.uuid === certificate.supplier);
    return {
      ...certificate,
      supplier: findSupplier ? findSupplier.name : '',
    };
  }

  private _getProductChainSuccess(data: IProductChainResult): void {
    this._formatCertificates(data);
  }

  private _formatCertificates(data?: IProductChainResult): void {
    const stepSites: IStepSiteBatchCertificate[] = [];
    this.stepCertificates = [];

    if (!data) {
      const formatedSite: IStepSiteBatchCertificate = {
        ...this.selectedStep?.manufactories,
        step: this.selectedStep?.name ?? '',
        supplier: this.selectedStep?.supplier?.name ?? '',
        logo: '',
        country: '',
        name: '',
        uuid: '',
      };
      stepSites.push(formatedSite);
    } else {
      data.steps.map(step => {
        if (step.manufactories) {
          const formatedSite: IStepSiteBatchCertificate = {
            ...step.manufactories,
            step: step.name,
            supplier: step.supplier ? step.supplier?.name : '',
            logo: '',
          };
          stepSites.push(formatedSite);
        }
      });
    }

    stepSites.filter(site => {
      if (site.certification_manufactory?.length) {
        site.certification_manufactory.filter((certificate: IStepCertification) => {
          const formatedCertificate = {
            ...certificate,
            step: site.step,
            manufactory: `${site.name}, ${site.country}${site.city ? ', ' + site.city : ''}`,
            supplier: site.supplier,
          };
          this.stepCertificates?.push(formatedCertificate);
        });
      }
    });
  }

  private _error(error: IProductChainErrorResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
