import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IQuestionnaireErrorResponse,
  IQuestionnaireSection,
  IQuestionnaireSectionQuestion,
} from '@interface/questionnaire.interface';

import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { QuestionnaireFacade } from '@facade/questionnaire.facade';
import { QuestionnaireNameEditModalComponent } from '../questionnaire-name-edit-modal/questionnaire-name-edit-modal.component';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-questionnaire-question',
  templateUrl: './questionnaire-question.component.html',
  styleUrls: ['./questionnaire-question.component.scss'],
})
export class QuestionnaireQuestionComponent {
  sectionExpanded = false;
  @Input() numOfQuestion = 0;
  @Input() question!: IQuestionnaireSectionQuestion;
  @Input() section!: IQuestionnaireSection;
  @Output() questionDeleted = new EventEmitter<string>();
  @Input() isCreate = false;
  @Input() questionnaireUid!: string;
  selectedOption!: string;
  showFollowUpQuestion: { [key: string]: boolean } = {
    yes: false,
    no: false,
    maybe: false,
  };

  constructor(
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private questionnaireFacade: QuestionnaireFacade
  ) {}

  expandSection() {
    this.sectionExpanded = !this.sectionExpanded;
  }

  private _error(error: IQuestionnaireErrorResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  editName(name: string) {
    const dialogRef = this._dialog.open(QuestionnaireNameEditModalComponent, {
      data: { title: 'Edit Question', data: name },
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        !this.isCreate
          ? this.questionnaireFacade
              .updateQuestionnaireSectionQuestionDetails$(this.question.uuid, this.section.uuid, result)
              .subscribe(
                result => {
                  this.question.name = result.name;
                },
                error => this._error(error)
              )
          : this.questionnaireFacade
              .createQuestionnaireSectionQuestion$(this.section.uuid, this.questionnaireUid, result)
              .subscribe(
                result => {
                  this.question.name = result.name;
                  this.question.uuid = result.uuid;
                },
                error => this._error(error)
              );
      }
    });
  }

  deleteQuestion() {
    if (this.isUUID(this.question.uuid)) {
      this.questionnaireFacade.deleteQuestionnaireSectionQuestion$(this.question.uuid).subscribe(
        () => {
          this.questionDeleted.emit(this.question.uuid);
        },
        error => this._error(error)
      );
    } else {
      this.questionDeleted.emit(this.question.uuid);
    }
  }

  isUUID(str: string): boolean {
    const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return uuidPattern.test(str);
  }

  toggleFollowUpQuestion(option: string) {
    this.showFollowUpQuestion[option] = !this.showFollowUpQuestion[option];
  }
}
