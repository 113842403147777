import { AgecPackagingType, IAgecPackaging, PackageRecyclabitity } from '@app/core/interface/regulations.interface';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { CreatePackagingDialogComponent } from '../create-packaging-dialog/create-packaging-dialog.component';
import { INameID } from '@app/core/interface/projects.interface';
import { IPackagingDisplay } from '@app/core/interface/digital-product-sheet.interface';
import { IProduct } from '@app/core/interface/products.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RegulationsFacade } from '@app/core/facade/regulations.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-packaging-view',
  templateUrl: './packaging-view.component.html',
  styleUrls: ['./packaging-view.component.scss'],
})
export class PackagingViewComponent implements OnChanges {
  @Input() packaging!: Partial<IAgecPackaging> | IPackagingDisplay;
  @Input() product?: IProduct;
  @Output() packageDeleted = new EventEmitter<string>();
  @Output() refresh = new EventEmitter<boolean>();
  displayPac!: IPackagingDisplay;
  indicatorImg = '../../../../../../../../assets/images/indicator-progress-bar.svg';
  expand = false;
  selectedItems = 0;
  constructor(
    private _facade: RegulationsFacade,
    private _snackbarService: SnackbarService,
    public dialog: MatDialog
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['packaging'].currentValue)
      this.displayPac = {
        agec: changes['packaging'].currentValue.agec,
        hazardous_substances:
          changes['packaging'].currentValue.hazardous_substances?.map((sub: INameID) => {
            return sub.name;
          }) ?? [],
        is_recyclable: changes['packaging'].currentValue.is_recyclable,
        is_recyclable_display: changes['packaging'].currentValue.is_recyclable_display,
        is_recycled_materials: changes['packaging'].currentValue.is_recycled_materials,
        is_recycled_materials_display: changes['packaging'].currentValue.is_recycled_materials_display,
        name: changes['packaging'].currentValue.name,
        packageType: AgecPackagingType[changes['packaging'].currentValue.package_type],
        packageRecyclability:
          this._facade.recycleTypeMsg[PackageRecyclabitity[+changes['packaging'].currentValue.package_recyclability]],
        uuid: changes['packaging'].currentValue.uuid,
      };
  }
  editPac(): void {
    const data = { edit: true, agec: this.displayPac.agec, pac: this.packaging, product: this.product };
    const dialog = this.dialog.open(CreatePackagingDialogComponent, {
      width: '1160px',
      height: '726px',
      panelClass: ['top-padding-0', 'overflow-y-hidden-dialog'],
      data,
    });
    dialog.afterClosed().subscribe((res: Partial<IAgecPackaging>) => {
      if (res) {
        this.packaging = res;
        this.displayPac = {
          agec: res.agec ?? '',
          hazardous_substances:
            res.hazardous_substances?.map((sub: INameID) => {
              return sub.name;
            }) ?? [],
          is_recyclable: !!res.is_recyclable,
          is_recyclable_display: res.is_recyclable_display,
          is_recycled_materials: res.is_recycled_materials,
          is_recycled_materials_display: res.is_recycled_materials_display,
          name: res.name ?? '',
          packageType: AgecPackagingType[res.package_type ?? -1],
          packageRecyclability:
            this._facade.recycleTypeMsg[
              PackageRecyclabitity[typeof res.package_recyclability === 'number' ? +res.package_recyclability : -1]
            ],
          uuid: res.uuid ?? '',
        };
        this.refresh.emit(true);
      }
    });
  }

  onDeletePackaging(): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        confirmationText: 'Do you wish to continue?',
        infoText: `You are about to delete this packaging form the digital product sheet.
        Please note that if you delete it all the progress for this packaging will be lost.`,
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.detetePackage();
      }
    });
  }
  detetePackage(): void {
    this._facade.deletePackaging$(this.packaging?.uuid ?? '', 'agec').subscribe({
      next: this._succcessDelete.bind(this),
      error: this._error.bind(this),
    });
  }
  private _succcessDelete(): void {
    this.packageDeleted.emit(this.packaging.uuid);
  }
  private _error(error: IAgecPackaging): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
