import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DUPLICATE, NotificationType } from '@app/core/constants';
import { IProduct, IProductResponse } from '@app/core/interface/products.interface';
import { RegisterGroup } from '@app/module/register/register.group';
import { CreateProductFormComponent } from '../create-product-form/create-product-form.component';
import { ICreateProductForm, ICreateProductResponse } from '@app/core/interface/register.interface';
import { ICustomField, ICustomFieldsListResponse, IUserCustomField } from '@app/core/interface/add-fields.interface';
import { UntypedFormGroup } from '@angular/forms';
import { AddFieldsFacade } from '@app/core/facade/add-fields.facade';
import { RegisterFacade } from '@app/core/facade/register.facade';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { Router } from '@angular/router';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-create-product-dialog',
  templateUrl: './create-product-dialog.component.html',
  styleUrls: ['./create-product-dialog.component.scss'],
  providers: [RegisterGroup, AddFieldsFacade, RegisterFacade, ProductsFacade],
})
export class CreateProductDialogComponent implements OnInit {
  @ViewChild(CreateProductFormComponent)
  createProductFormComponent!: CreateProductFormComponent;
  product!: IProduct;
  title = 'Create Product';
  btn = 'Create Product';
  customFields: ICustomField[] = [];
  customFieldsData: Array<IUserCustomField> = [];
  form!: UntypedFormGroup;
  isRegisterPage!: boolean;
  selectedIndex = 0;
  showRedCircle = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { product: IProduct; mode: 'duplicate' | 'edit'; productId: string },
    public dialogRef: MatDialogRef<CreateProductDialogComponent>,
    private _snackbarService: SnackbarService,
    private _addFieldsFacade: AddFieldsFacade,
    private _regGrup: RegisterGroup,
    private _facade: RegisterFacade,
    private _router: Router,
    private _productsFacade: ProductsFacade
  ) {
    this.form = this._regGrup.createProductForm;
    this.isRegisterPage = this._router.url === '/register';
  }

  /**
   * On OnInit depending on mode we are setting title and btn text
   */
  ngOnInit(): void {
    if (this.data) {
      // this.product = this.data.product;
      if (this.data.mode === DUPLICATE) {
        this.title = 'Duplicate a product';
        this.btn = 'Clone Product';
      }
      if (this.data.mode === 'edit') {
        this.title = 'Edit product';
        this.btn = 'Update Product';
      }

      this._productsFacade.getProduct$(this.data.productId).subscribe({
        next: this._setProduct.bind(this),
        error: this._error.bind(this),
      });
    } else {
      this.getCustomFields();
    }
  }

  tabChanged(event: MatTabChangeEvent) {
    this.selectedIndex = event.index;

    // Hide red-circle when custom fields tab is selected
    if (this.selectedIndex === 1) {
      this.showRedCircle = false;
    }
  }
  /**
   *
   * @param event revices event trigered from the form and closes the dialog passing it
   */
  closeDialog(event: ICreateProductResponse) {
    event && this.dialogRef.close(event);
  }

  // Get available project custom fields from api;
  // If there are custom fields available, show tabs group
  // else show just the standard fields
  getCustomFields(): void {
    this._addFieldsFacade.getCustomFields$('products').subscribe({
      next: this._getFieldsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  /**
   * Sends API with form to create product
   */
  createProduct(): void {
    const changedFormValues: Partial<ICreateProductForm> = GetDirtyValues(this.form);

    if (this.form.valid) {
      this._facade
        .createProduct$({
          ...changedFormValues,
        })
        .subscribe({
          next: this._success.bind(this),
          error: this._error.bind(this),
        });
    }
  }

  /**
   * Send API to update existing product
   */
  updateExistingProduct(): void {
    const changedFormValues: Partial<ICreateProductForm> = GetDirtyValues(this.form);
    if (this.form.valid) {
      this._productsFacade.updateProduct$({ ...changedFormValues }, this.product.uuid).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  /**
   * Sends API with form and product uuid to clone product
   */
  cloneExistingProduct(): void {
    const changedFormValues: Partial<ICreateProductForm> = GetDirtyValues(this.form);
    if (this.form.valid) {
      this._productsFacade.cloneAndUpdateProduct$({ ...changedFormValues }, this.product.uuid).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _getFieldsSuccess(data: ICustomFieldsListResponse): void {
    this.customFields = data.results;
  }

  private _success(response: ICreateProductResponse): void {
    if (this.isRegisterPage) {
      this._router.navigate(['/dashboard']);
    } else {
      const action = !this.data ? 'created' : this.data.mode === 'edit' ? 'updated' : 'duplicated';
      this._snackbarService.openTypeSnackbar(`The product was successfully ${action}.`, NotificationType.success);
      this.closeDialog(response);
    }
  }

  private _setProduct(response: IProductResponse): void {
    this.product = response;
    this.createProductFormComponent?.setFormData(this.product);
    this.customFieldsData = this.product.custom_fields;
  }

  /**
   * Hadles HTTP error desplaying message from backend
   * @param error
   */
  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}
