import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import { ILanguageManagement, ILanguageManagementPutRequest } from '@app/core/interface/language-management.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { LanguageManagementTableComponent } from '@app/shared/components/language-management-table/language-management-table.component';

@Component({
  selector: 'app-brand-translation-dialog',
  standalone: false,
  templateUrl: './brand-translation-dialog.component.html',
  styleUrl: './brand-translation-dialog.component.scss',
  providers: [LanguageManagementFacade],
})
export class BrandTranslationDialogComponent implements OnInit {
  public modelName: string = '';
  public brandDataForTranslate: ILanguageManagement[] = [];
  @ViewChild('childComponent') childComponent!: LanguageManagementTableComponent;
  brandUuid: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: { brandUuid: string; brandDataForTranslate: ILanguageManagement[]; modelName: string },
    private _dialogRef: MatDialogRef<BrandTranslationDialogComponent>,
    private _snackbarService: SnackbarService,
    private _languageManagementFacade: LanguageManagementFacade
  ) {}

  ngOnInit(): void {
    this.modelName = this._data.modelName;
    this.brandUuid = this._data.brandUuid;
    this.brandDataForTranslate = this._data.brandDataForTranslate;
  }

  onCancel(): void {
    this._dialogRef.close();
  }

  onSaveTranslations(): void {
    this.childComponent.sendToParent();
    this._dialogRef.close();
  }

  handleLanguageManagementArrayChange(arrayForPut: ILanguageManagementPutRequest[]) {
    this._languageManagementFacade.putBrandForTranslation$(this.brandUuid, arrayForPut).subscribe({
      next: () => {
        this._snackbarService.openTypeSnackbar('Translations saved successfully ', NotificationType.success);
      },
      error: error => {
        this._snackbarService.openTypeSnackbar(error.error.detail[0], NotificationType.error);
      },
    });
  }
}
