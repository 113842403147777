import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { base64ToFile } from '@app/core/utils/blob';
import { ImageEditDialogData } from '@app/core/interface/confirmation-dialog.interface';
import { IFilesMeadiasLinks } from '@app/core/interface/medias.interface';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { UntypedFormGroup } from '@angular/forms';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import { DocumentsAndMediasGroup } from '../add-documents-and-medias/add-documents-and-medias.group';
import { infoDialogHeight, infoDialogWidth, NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@Component({
  selector: 'app-image-edit-preview',
  templateUrl: './image-edit-preview.component.html',
  styleUrls: ['./image-edit-preview.component.scss'],
  providers: [DocumentsAndMediasGroup, FilesMeadiasLinksFacade],
})
export class ImageEditPreviewComponent implements OnInit {
  imageChangedEvent!: Event;
  croppedImage: string | null | undefined = '';
  rotation = 0;
  scale = 1;
  transform: ImageTransform = {};
  canvasRotation = 0;
  selectedVal = 'fitView';
  is_fitView = true;
  has_image!: boolean;
  file_name!: string;
  isNewUpload!: boolean;
  form: UntypedFormGroup;
  currentbase64String!: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImageEditDialogData,
    private dialogRef: MatDialogRef<ImageEditPreviewComponent>,
    private _dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private _group: DocumentsAndMediasGroup,
    private _facade: FilesMeadiasLinksFacade,
    private _snackbarService: SnackbarService
  ) {
    this.form = this._group.filesForm;

    this.form.controls['name'].patchValue(this.data.name);
    this.form.controls['file'].patchValue(this.data.docUrl);
    this.currentbase64String = this.data.docUrl;
  }

  ngOnInit(): void {
    this.onValChange('fitView');
    if (this.data) {
      const lastIndex = this.data.docUrl.lastIndexOf('/');
      this.file_name = this.data.docUrl.substring(lastIndex + 1);
      this.fileChangeEvent(this.data.event);
      this.has_image = true;
      this.isNewUpload = !this.data.docUrl || this.data.newUpload === true;
    } else {
      this.has_image = false;
      this.isNewUpload = true;
    }
  }
  /**
   * On save there are two flows of editing the media:
   *
   * 1. Set File and Save. Editing media on the Steps/Batchsteps requires
   * updating the existing media of the step direct after the modal is closed.
   * Endpoint: @PATCH step/{stepID}/medias/{mediaID/
   *
   * 2. On save we are just passing the cropped picture as File.
   * No endpoint is called on close.
   */
  dialogClose(): void {
    if (this.croppedImage) {
      // 1. Set the File and save.
      if (this.data.type) this.setFileAndSave(base64ToFile(this.croppedImage, this.file_name));
      // 2. Just passing the File on save.
      else
        this.dialogRef.close({
          file: base64ToFile(this.croppedImage, this.file_name),
          event: this.imageChangedEvent, // Pass the updated imageChangedEvent
        });
    } else {
      this.dialogRef.close({ file: null });
    }
  }

  dialogCancel(): void {
    if (!this.data.alredyUpladed) {
      this.dialogRef.close({ file: null });
    } else if (this.currentbase64String.includes('data:image/png;base64')) {
      this.dialogRef.close({
        file: base64ToFile(this.currentbase64String, this.data.name),
      });
    } else {
      this.dialogRef.close({ file: this.currentbase64String });
    }
  }

  onSave() {
    const saveForm: Partial<IFilesMeadiasLinks> = GetDirtyValues(this.form);

    if (this.data && this.data.doc?.uuid) {
      this._facade
        .updateMedia$(
          saveForm,
          this.data.type ?? 'steps',
          this.data?.typeUuid ?? '',
          'medias',
          this.data.doc?.uuid ?? ''
        )
        .subscribe({
          next: this._successUpdate.bind(this),
          error: this._error.bind(this),
        });
    } else {
      this._facade.createMedia$(saveForm, this.data.type ?? 'steps', this.data?.typeUuid ?? '', 'medias').subscribe({
        next: this._successUpdate.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  _successUpdate() {
    this.dialogRef.close({
      file: base64ToFile(this.croppedImage ?? '', this.file_name),
    });
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }

  /**
   *
   * @param event on change image we are setting imageChangeEvent
   */
  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
    this.has_image = true;
    this.isNewUpload = true;
    const file_name = (event.target as HTMLInputElement).files?.[0].name;
    if (file_name) {
      this.file_name = file_name;
    }

    this.form.patchValue({ name: file_name });
  }

  /**
   *
   * @param event On cropped image from the event we are setting base64 from cropped picture
   */
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  public onValChange(val: string) {
    this.selectedVal = val;
    if (this.selectedVal == 'fitView') {
      this.is_fitView = true;
    } else {
      this.is_fitView = false;
    }

    this.cdRef.detectChanges();
  }
  deletePhoto() {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete this photo ?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.imageChangedEvent = {} as Event;
        this.croppedImage = '';
        this.has_image = false;
      }
    });
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
    this.cdRef.detectChanges();
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
    this.cdRef.detectChanges();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  setFileAndSave(file?: File): void {
    if (file && this.form) {
      this.form.patchValue({ file: file });
      this.form.updateValueAndValidity();
      this.form.controls['name'].markAsDirty();
      this.form.controls['file'].markAsDirty();
      this.onSave();
    }
  }
}
