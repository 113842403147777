import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { EmailFacade } from '@app/core/facade/email.facade';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { NavigationService } from '@app/core/service/navigation.service';

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss'],
})
export class SupportDialogComponent implements OnInit {
  requestForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SupportDialogComponent>,
    private _emailFacade: EmailFacade,
    private _snackbarService: SnackbarService,
    private _translateService: TranslateService,
    public navigationService: NavigationService,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}

  ngOnInit(): void {
    const currentLanguage = this._translateService.currentLang;
    let languageText: string;

    switch (currentLanguage) {
      case 'en':
        languageText = 'English';
        break;
      case 'fr':
        languageText = 'French';
        break;
      default:
        languageText = currentLanguage;
        break;
    }

    this.requestForm = this.data
      ? this.formBuilder.group({
          request_reason: ['', [Validators.required, Validators.minLength(5)]],
        })
      : this.formBuilder.group({
          content: ['', [Validators.required]],
          language: [languageText],
        });
  }

  sendRequestText(): void {
    if (this.requestForm.valid) {
      this._emailFacade.sendEmail$(this.requestForm.value).subscribe({
        next: () => this._success(),
        error: this._error.bind(this),
      });
    } else {
      this.requestForm.markAllAsTouched();
    }
  }

  sendEditStepRequestText(): void {
    const stepUuid = this.data;
    const formData = this.requestForm.value;

    if (this.requestForm.valid) {
      this._emailFacade.sendEmailRequestStepEdit$(stepUuid, formData).subscribe({
        next: () => this._success(),
        error: this._error.bind(this),
      });
    } else {
      this.requestForm.markAllAsTouched();
    }
  }

  ///////////////////////////////////// PRIVATE METHODS //////////////////////////////////////////

  private _success(): void {
    this.dialogRef.close(this.requestForm.value);
    this._snackbarService.openTypeSnackbar(`Email sent successfully`, NotificationType.success);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
