import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IAddressListResponse, ICreateAddressResponse } from '@app/core/interface/address.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { AddressFacade } from '@app/core/facade/address.facade';
import { AddressViewDialogComponent } from './address-view-dialog/address-view-dialog.component';
import { CreateAddressComponent } from './create-address/create-address.component';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-address-management',
  templateUrl: './address-management.component.html',
  styleUrls: ['./address-management.component.scss'],
  providers: [AddressFacade],
})
export class AddressManagementComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  displayedColumns: string[] = ['name', 'street_line_1', 'city', 'country', 'actions'];
  addressList: ICreateAddressResponse[] = [];
  cardDataSource = new MatTableDataSource<ICreateAddressResponse>();
  tableDataSource = new MatTableDataSource<ICreateAddressResponse>();
  addressObservable$!: Observable<ICreateAddressResponse[]>;
  deleteUuid: string;
  address!: ICreateAddressResponse;
  addressUuid!: string;
  constructor(
    private _addressFacade: AddressFacade,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private _dialog: MatDialog
  ) {
    this.deleteUuid = '';
  }

  ngOnInit(): void {
    this.getAddresses();
  }

  getAddresses(): void {
    this._addressFacade.getAddresses$().subscribe({
      next: this._getAddressesSuccess.bind(this),
      error: this._error.bind(this),
    });
  }
  ngAfterViewInit() {
    this.cardDataSource.paginator = this.paginator;
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;
    this.paginator.pageSize = 12;
  }

  ngOnDestroy() {
    if (this.cardDataSource) {
      this.cardDataSource.disconnect();
    }
  }

  private _getAddressesSuccess(data: IAddressListResponse): void {
    if (data && data.results.length) {
      this.addressList = data.results;
      this.cardDataSource.data = data.results;
      this.tableDataSource.data = data.results;
      this.addressObservable$ = this.cardDataSource.connect();
    } else {
      this._router.navigate(['settings/address-management']);
    }
  }

  viewChange(value: string): void {
    if (value === 'tableView') {
      this._changeDetectorRef.detectChanges();
      this.tableDataSource.paginator = this.paginator;
      this.tableDataSource.sort = this.sort;
    } else {
      this.cardDataSource.paginator = this.paginator;
    }
  }

  applyFilter(event: Event): void {
    applyFilter(event, this.cardDataSource, this.tableDataSource);
  }

  getAddress(): void {
    this._addressFacade.getAddress$(this.address.uuid).subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
  }
  private _success(): void {
    this._snackbarService.openTypeSnackbar(
      `Address ${this.addressUuid ? 'updated' : 'created'} successfully`,
      NotificationType.success
    );
    this._router.navigate(['/address-management']);
  }
  deleteAddressDialog(uuid: string): void {
    this.deleteUuid = uuid;
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to delete this Brand Address. Please note that if you delete the address, the changes cannot be undone.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._addressFacade.deleteAddress$(uuid).subscribe({
          next: this._deleteAddress.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  private _deleteAddress(): void {
    this.cardDataSource.data = this.cardDataSource.data.filter(address => address.uuid !== this.deleteUuid);
    this.tableDataSource.data = this.tableDataSource.data.filter(address => address.uuid !== this.deleteUuid);

    this._snackbarService.openTypeSnackbar(`Address deleted`, NotificationType.success);

    this.deleteUuid = '';
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  createAddressDialog(): void {
    const dialogRef = this._dialog.open(CreateAddressComponent, {
      width: '1160px',
      height: '780px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.refresh(true);
      }
    });
  }

  addressViewDialog(element: ICreateAddressResponse): void {
    const dialogRef = this._dialog.open(AddressViewDialogComponent, {
      width: '700px',
      height: '540px',
      autoFocus: false,
      data: element,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        ///this.element
      }
    });
  }

  editAddressDialog(element: ICreateAddressResponse): void {
    const dialogRef = this._dialog.open(CreateAddressComponent, {
      data: element,
      width: '1160px',
      height: '780px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.refresh(true);
      }
    });
  }

  refresh(event: boolean): void {
    event && this.getAddresses();
  }
}
