import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ILoginErrorResponse, ILoginResponse } from '@app/core/interface/login.interface';
import { NotificationType, REDIRECT, Storage } from '@app/core/constants';

import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { LoginFacade } from '@app/core/facade/login.facade';
import { LoginGroup } from './login.group';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/service/translation.service';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginGroup, LoginFacade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  loginForm: UntypedFormGroup;
  hide = true;
  autocomplete = false;
  supplierEmail?: string;
  lang: string | null = '';
  environment = environment;

  constructor(
    private _group: LoginGroup,
    private _facade: LoginFacade,
    private _snackbarService: SnackbarService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef: ChangeDetectorRef,
    public translate: TranslateService,
    public translationService: TranslationService,
    public _dataStorageService: DataStorageService
  ) {
    this.loginForm = this._group.loginForm;
    this.translate.setDefaultLang('fr');
    const queryParams = this._route.snapshot.queryParams;
    if (queryParams['supplier_email']) {
      this.supplierEmail = queryParams['supplier_email'];
      this.supplierEmail = this.supplierEmail?.replace(' ', '+');
      this.loginForm.patchValue({
        username: this.supplierEmail,
      });
    }
    if (queryParams['lang']) {
      this.lang = this._route.snapshot.queryParamMap.get('lang');
    }
    if (this.lang) {
      this.lang = this.lang === 'fr' ? 'fr' : 'en';
      this.translationService.language.next(this.lang);
    }
    if (queryParams['returnUrl']) {
      const returnUrl = this._route.snapshot.queryParamMap.get('returnUrl');
      if (
        returnUrl &&
        returnUrl !== '/login' &&
        !returnUrl.includes('brand-management/switch-brand') &&
        !returnUrl.includes('/select-brand')
      ) {
        this._dataStorageService.set(REDIRECT, returnUrl, Storage.local);
      }
    }
  }

  onSubmit(): void {
    this._facade
      .login$(this.loginForm.value)
      .pipe(take(1))
      .subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
  }

  onInput(): void {
    this.autocomplete = true;
    this._cdRef.detectChanges();
  }

  private _success(data: ILoginResponse): void {
    this._snackbarService.openTypeSnackbar(`Welcome ${data.user.username}`, NotificationType.success);
    if (data?.user?.brands && data.user.brands.length > 1) {
      this._router.navigate([data.user.is_supplier ? '/select-brand' : '/settings/brand-management/switch-brand']);
    } else {
      const url = this._dataStorageService.get(REDIRECT, Storage.local);
      this._router.navigate([url && url !== '/landing' && url !== '/' ? url : '/dashboard']);
      this._dataStorageService.removeKey(REDIRECT, Storage.local);
    }
  }

  private _error(error: ILoginErrorResponse): void {
    Object.values(error).forEach((err: Array<string>) =>
      this._snackbarService.openTypeSnackbar(err[0], NotificationType.error)
    );
  }
}
