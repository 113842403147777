import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import { ILanguage } from '@app/core/interface/languages.interface';
import {
  IMediaDialogData,
  IFilesMeadiasLinks,
  IMediaResponse,
  IMediaScreens,
} from '@app/core/interface/medias.interface';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { DocumentsAndMediasGroup } from './add-documents-and-medias.group';
import { ADD_DOCUMENT_ALLOWED_TYPES, NotificationType } from '@app/core/constants';
import { TranslateService } from '@ngx-translate/core';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { uploadFileSize, uploadMediaSize } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-add-documents-and-medias',
  templateUrl: './add-documents-and-medias.component.html',
  styleUrls: ['./add-documents-and-medias.component.scss'],
  providers: [DocumentsAndMediasGroup, FilesMeadiasLinksFacade],
})
export class AddDocumentsAndMediasComponent {
  languages: ILanguage[] = [];
  selectedLanguages: ILanguage[] = [];
  form: UntypedFormGroup;
  format: 'video' | 'image' = 'image';
  itemType: 'product' | 'supplier' | 'step' = 'product';
  type: 'document' | 'media' | 'photo' | 'video' | 'certificate' | 'proofs' = 'document';
  title = 'Add';
  infoMsg = '';
  infoMsg2 = '';
  warn = false;
  typeWarn = false;
  is_image = false;
  is_video = false;
  video_uploaded = false;
  is_video_uploaded = false;
  is_image_uploaded = false;
  uploadedUrl!: string | null | ArrayBuffer;
  image_uploaded = false;
  urlWarn = false;
  document_uploaded = false;
  manufactories!: Array<IManufactory> | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IMediaDialogData,
    private _dialogRef: MatDialogRef<AddDocumentsAndMediasComponent>,
    private _group: DocumentsAndMediasGroup,
    private _facade: FilesMeadiasLinksFacade,
    private _snackbarService: SnackbarService,
    private _translate: TranslateService
  ) {
    this.form = this._group.filesForm;
    if (data.type === 'products') this.itemType = 'product';

    if (data.type === 'steps') this.itemType = 'step';
    if (data.type === 'suppliers') {
      this.itemType = 'supplier';
      this.manufactories = this.data.manufactories;
    }
    this.setTexts(data.screen);
    if (data.screen === 'links') {
      this.form.removeControl('file');
    } else {
      this.form.removeControl('url');
    }
    if (data.doc) {
      if (data.edit && data.doc.mimetype?.includes('video')) {
        this.uploadedUrl = data.doc.file;
        this.video_uploaded = true;
      }

      this.title = this._translate.instant('Edit ' + this.data.screen);
      this.form.controls['name'].patchValue(this.data.doc?.name);
      if (data.screen !== 'links') {
        this.form.controls['file'].patchValue(this.data.doc?.file);
      } else {
        this.infoMsg = 'Here you can add link of website or ' + this.itemType + ' link.';
        this.form.controls['url'].patchValue(this.data.doc?.url);
      }
    } else {
      this.title = 'Upload ' + this.data.screen;
    }
    this.form.updateValueAndValidity();
  }

  save(): void {
    if (this.data.supplierAccount) {
      this._dialogRef.close();
      return;
    }

    const saveForm: Partial<IFilesMeadiasLinks> = GetDirtyValues(this.form);

    let documentType = this.data.screen;
    if (this.data.screen == 'photos' || this.data.screen == 'videos') {
      documentType = 'medias';
    }

    if (this.data.doc) {
      this._facade
        .updateMedia$(saveForm, this.data.type, this.data.typeUuid, documentType, this.data.doc.uuid)
        .subscribe({
          next: this._successUpdate.bind(this),
          error: this._error.bind(this),
        });
    } else {
      this._facade.createMedia$(saveForm, this.data.type, this.data.typeUuid, documentType).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  setFile(event: Event, type?: 'document' | 'media' | 'photo' | 'video' | 'certificate' | 'proofs'): void {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file) {
      this.typeWarn = !this._facade.isValidUploadType(file.type, ADD_DOCUMENT_ALLOWED_TYPES);
      if (this.typeWarn) return;
      this.type = type || 'document';
      if ((type === 'certificate' || type === 'document' || type === 'proofs') && file.size > uploadFileSize) {
        this.warn = true;
        this.is_image = false;
        this.is_video = false;
        return;
      }
      if ((type === 'photo' || (type === 'media' && file.type.indexOf('image') > -1)) && file.size > uploadFileSize) {
        this.is_image = true;
        this.is_video = false;
        this.warn = true;
        return;
      }
      if ((type === 'video' || (type === 'media' && file.type.indexOf('video') > -1)) && file.size > uploadMediaSize) {
        this.is_video = true;
        this.is_image = false;
        this.warn = true;
        return;
      }
      if (type === 'certificate' || type === 'document') {
        this.document_uploaded = true;
      }
      const name = file.name.split('.').slice(0, -1).join('-');
      this.form.patchValue({ file: file, name: name });
      this.form.updateValueAndValidity();
      this.form.controls['name'].markAsDirty();
      this.form.controls['file'].markAsDirty();
    }
  }

  setTexts(screen: IMediaScreens): void {
    if (screen === 'documents' || screen === 'proofs') {
      this.infoMsg = this._translate.instant(
        'Upload a document which you would like to add to this ' + this.itemType + '.'
      );
      this.infoMsg2 = this._translate.instant(
        'Upload a document which you would like to add to this ' + this.itemType + '.'
      );
    }
    if (screen === 'medias') {
      this.infoMsg = this._translate.instant('Upload the media you would like to add to this ' + this.itemType + '.');
      this.infoMsg2 = '';
    }
    if (screen === 'links') {
      this.infoMsg = this._translate.instant('Add a website or social link you would like to add to this Supplier');
      this.infoMsg2 = '';
    }
    if (screen === 'photos') {
      this.infoMsg = this._translate.instant('Upload the photo you would like to add to this ' + this.itemType + '.');
      this.infoMsg2 = '';
    }
    if (screen === 'videos') {
      this.infoMsg = this._translate.instant('Upload the video you would like to add to this ' + this.itemType + '.');
      this.infoMsg2 = '';
    }
    if (screen === 'certificates') {
      this.infoMsg = this._translate.instant(
        'Upload the certificate you would like to add to this ' + this.itemType + '.'
      );
      this.infoMsg2 = '';
    }
  }

  clearFileControl(): void {
    this.form.controls['file'].patchValue('');
    this.form.controls['name'].patchValue('');
    this.form.controls['file'].markAsDirty();
    this.form.controls['name'].markAsDirty();
    this.form.updateValueAndValidity();
    this.image_uploaded = this.video_uploaded = this.warn = this.typeWarn = this.document_uploaded = false;
  }

  onSelectFile(event: Event): void {
    const selectedFile = event.target as HTMLInputElement;
    if (selectedFile.files && selectedFile.files[0]) {
      this.is_image_uploaded = selectedFile?.files[0].type.includes('image');
      this.is_video_uploaded = selectedFile?.files[0].type.includes('video');
      const reader = new FileReader();

      reader.readAsDataURL(selectedFile.files[0]);

      reader.onload = event => {
        if (event.target) {
          this.uploadedUrl = event.target?.result;
          if (this.is_image_uploaded && !this.warn) {
            this.image_uploaded = true;
          } else if (this.is_video_uploaded && !this.warn) {
            this.video_uploaded = true;
          }
        }
      };
    }
  }
  tryAgain(): void {
    this.warn = this.typeWarn = false;
    if (this.form.controls['file'] && this.form.controls['name']) {
      this.form.controls['file'].patchValue('');
      this.form.controls['name'].patchValue('');
      this.form.controls['file'].markAsDirty();
      this.form.controls['name'].markAsDirty();
      this.form.updateValueAndValidity();
      this.uploadedUrl = null;
      this.image_uploaded = this.video_uploaded = this.warn = this.typeWarn = this.document_uploaded = false;
    }
  }

  private _success(): void {
    this._dialogRef.close(true);
    this._snackbarService.openTypeSnackbar('Successfully added', NotificationType.success);
  }

  private _successUpdate(res: IMediaResponse): void {
    this._dialogRef.close(res);
    this._snackbarService.openTypeSnackbar('Successfully updated', NotificationType.success);
  }
  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
    if (error['file']) {
      this.typeWarn = error['file'][0].toLowerCase().includes('allowed extensions');
      this.warn = error['file'][0].toLowerCase().includes('max size');
    }
    if (error['url']) this.urlWarn = error['url'][0].toLowerCase().includes('enter a valid url');
  }
}
