<div class="holder d-flex flex-column justify-content-between">
  <div class="header">
    <h3 class="m-0">{{ 'Manage language translations' | translate }}</h3>
  </div>
  <div class="content d-flex flex-column">
    <div class="title d-flex align-items-center">
      <p class="m-0 label">{{ 'Supplier translated:' | translate }}</p>
      <img class="logo" [src]="supplier.logo ? supplier.logo : imgPlaceholder" [alt]="" alt="" />
      <p class="m-0 cert-name">{{ supplier.name }}</p>
    </div>
    <app-language-management-table
      [modelName]="modelName"
      [languageManagementData]="supplierDataForTranslate"
      #childComponent
      (languageManagementArrayChange)="handleLanguageManagementArrayChange($event)"
    />
  </div>
  <div class="footer d-flex justify-content-end align-items-center">
    <app-rounded-button
      class="underline-button"
      [button_text]="'Cancel'"
      [size]="'sm'"
      color="tetriary-link underlined"
      (click)="onCancel()"
    ></app-rounded-button>
    <app-rounded-button
      [button_text]="'Save translation'"
      [icon_category]="'essential'"
      [icon_name]="'translate'"
      [icon_right]="true"
      [size]="'md'"
      color="turquoise"
      (click)="onSaveTranslations()"
    ></app-rounded-button>
  </div>
</div>
