<div class="wrapper">
  <div class="info">
    <div class="image">
      <img class="avatar" [src]="supplier?.logo ?? imgPlaceholder" alt="" />
    </div>

    <div class="text">
      <p class="p-strong">{{ supplier?.name }}</p>

      <p class="p-normal" [ngClass]="supplier?.related_publicated_chain ? 'p-green' : 'p-neutral'">
        {{
          supplier?.related_publicated_chain
            ? ('related to published chain' | translate)
            : ('unrelated to published chain' | translate)
        }}
      </p>

      <!-- Additioanl Languages -->
      <!-- <p class="p-normal" [ngClass]="supplier?.additional_languages ? 'p-blue' : 'p-neutral'">
        {{
          supplier?.additional_languages
            ? supplier?.language_number + (' additional languages' | translate)
            : ('no additional languages' | translate)
        }}
      </p> -->
      <p class="p-normal" [ngClass]="supplier?.is_default_configuration ? 'p-neutral' : 'p-orange'">
        {{
          supplier?.is_default_configuration
            ? ('default configuration' | translate)
            : ('custom configuration' | translate)
        }}
      </p>
    </div>
  </div>
  <div class="actions">
    <app-custom-icon
      id="settings"
      [icon_category]="'settings'"
      [icon_name]="'setting-4'"
      class="cursor-pointer"
      matTooltip="{{ 'Supplier visibility setup' | translate }}"
      matTooltipClass="custom-tooltip-center"
      matTooltipPosition="above"
      (click)="openSetupSupplier()"
    ></app-custom-icon>
  </div>
</div>
