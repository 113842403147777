import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UnitManagementGroup } from '../unit-management.group';
import { ICreateUnitData } from '@app/core/interface/unit.interface';
import { IBatch, IBatchQuanityUnit } from '@app/core/interface/batch.interface';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { BatchFacade } from '@app/core/facade/batch.facade';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-create-unit',
  templateUrl: './create-unit.component.html',
  styleUrls: ['./create-unit.component.scss'],
  providers: [UnitManagementGroup, BatchFacade],
})
export class CreateUnitComponent implements OnInit {
  form: UntypedFormGroup;
  is_editing!: boolean;
  title!: string;
  button_text!: string;
  inputValue!: string;
  unitList!: Array<IBatchQuanityUnit>;
  is_existing!: boolean;
  active_units!: boolean;
  needHelpTooltip!: boolean;
  categoryName: UntypedFormControl = new UntypedFormControl(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICreateUnitData,
    private translate: TranslateService,
    private _group: UnitManagementGroup,
    private _batchFacade: BatchFacade,
    public dialogRef: MatDialogRef<CreateUnitComponent>,
    private _snackbarService: SnackbarService
  ) {
    this.form = this._group.unitForm;

    this.form.get('is_active')?.markAsDirty();
    if (this.data.is_editing) {
      this.form.patchValue({
        name: this.data.unit.name,
        symbol: this.data.unit.symbol,
      });

      this.form.get('symbol')?.disable();
      this.form.markAsPristine();
    }
  }

  ngOnInit(): void {
    this.setData();
  }

  setData(): void {
    if (this.data) {
      this.unitList = this.data.unitList;
      this.is_editing = this.data.is_editing;
      this.title = this.is_editing ? this.translate.instant('Edit Unit') : this.translate.instant('Create Unit');
      this.button_text = this.is_editing
        ? this.translate.instant('Update Unit')
        : this.translate.instant('Create Unit');
    }
  }

  createQuantityUnit(): void {
    const changedFormValues: Partial<IBatchQuanityUnit> = GetDirtyValues(this.form);
    if (this.form.valid) {
      if (this.is_editing) {
        this._batchFacade.updateQuantityUnit$(this.data.unit.uuid, { ...changedFormValues }).subscribe({
          next: this._createUnitSuccess.bind(this),
        });
      } else {
        this._batchFacade.createQuantityUnit$(changedFormValues).subscribe({
          next: this._createUnitSuccess.bind(this),
        });
      }
    }
  }

  checkIsExistingUnitName(event: KeyboardEvent): void {
    const value = (<HTMLInputElement>event.target).value;
    const unitName = this.form.controls['symbol'];
    const searchResult = this.unitList.find(item => item.symbol === value);
    if (searchResult) {
      unitName.setErrors({ valueExists: true });
      unitName.markAsTouched();
    }
  }

  mouseEvHandler(status: boolean) {
    status ? (this.needHelpTooltip = true) : (this.needHelpTooltip = false);
  }

  private _createUnitSuccess(): void {
    this.dialogRef.close(true);
  }

  private _error(error: IBatch): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
