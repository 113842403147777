import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DEFAULT_TAB_INDEX, NotificationType, SELECTED_TAB_INDEX_KEY, Storage } from '@app/core/constants';
import { IBrandListResult, IBrandUserResult, IBrandUsersResponse } from '@app/core/interface/brands.interface';
import { Observable, map, startWith } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { DataAuthService } from '@app/core/service/data-auth.service';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { ICompleteProfileErrorResponse } from '@app/core/interface/register.interface';
import { IUser } from '@app/core/interface/login.interface';
import { InviteBrandUserComponent } from '@module/brand-management/invite-brand-user/invite-brand-user.component';
import { ManageUserDialogComponent } from './manage-user-dialog/manage-user-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RegisterFacade } from '@app/core/facade/register.facade';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UserSettingsGroup } from './user-settings.group';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  providers: [RegisterFacade, UserSettingsGroup],
})
export class UserSettingsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(ManageUserDialogComponent)
  form!: UntypedFormGroup;
  user: IUser | null = this._dataAuthService.user;
  brandUsers: IBrandUserResult[] = [];
  cardDataSource = new MatTableDataSource<IBrandUserResult>();
  dataSource = new MatTableDataSource<IBrandUserResult>();
  usersObservable$!: Observable<IBrandUserResult[]>;
  selectedTabIndex = 0;
  newInvitedUser!: IBrandUserResult;

  @ViewChild('inputFilter', { static: false }) inputFilter!: ElementRef;

  filteredOptions!: Observable<IBrandUserResult[]>;
  myControl = new UntypedFormControl('');
  userCount!: number;
  selectedBrand!: IBrandListResult | null;

  constructor(
    private _userSettingsGroup: UserSettingsGroup,
    private _snackbarService: SnackbarService,
    private _dataAuthService: DataAuthService,
    private _dialog: MatDialog,
    private _brandsFacade: BrandsFacade,
    private _router: Router,
    private _dataStorageService: DataStorageService
  ) {}

  ngOnInit(): void {
    const savedTabIndex = this._dataStorageService.get(SELECTED_TAB_INDEX_KEY, Storage.local);
    this.selectedTabIndex = savedTabIndex !== null ? parseInt(savedTabIndex, 10) : DEFAULT_TAB_INDEX;
    this.form = this._userSettingsGroup.userSettingsForm;
    this.user = typeof this.user === 'string' ? JSON.parse(this.user) : this.user;
    if (this.user) {
      this._setFormValues();
    }
    this.getBrandUsers();
    this.user?.brand &&
      this._brandsFacade.getBrand$(this.user.brand).subscribe({
        next: this._getBrandSuccess.bind(this),
        error: this._error.bind(this),
      });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
  }

  private _getBrandSuccess(data: IBrandListResult): void {
    if (data) {
      this.selectedBrand = data;
    } else {
      this._router.navigate(['brand-management/create-brand']);
    }
  }

  openInviteBrandUserDialog() {
    const dialogRef = this._dialog.open(InviteBrandUserComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'padding-0',
      data: this.selectedBrand,
    });

    dialogRef.afterClosed().subscribe((result: IBrandUserResult) => {
      if (result) {
        this.newInvitedUser = result;
        this.selectedTabIndex = 0;
      }
    });
  }

  ngAfterViewInit() {
    this.cardDataSource.paginator = this.paginator;
    this.cardDataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private _filter(value: string): IBrandUserResult[] {
    const filterValue = value.toLowerCase();

    const filteredUsers = this.brandUsers.filter(option => {
      const fullName = `${option.first_name} ${option.last_name}`.toLowerCase();
      const email = option.email?.toLowerCase() || '';
      const role = option.role?.toLowerCase() || '';
      const entreprise = option.entreprise?.toLowerCase() || '';
      const functionValue = option.function?.toLowerCase() || '';

      return (
        fullName.includes(filterValue) ||
        email.includes(filterValue) ||
        role.includes(filterValue) ||
        entreprise.includes(filterValue) ||
        functionValue.includes(filterValue)
      );
    });

    this.cardDataSource.data = filteredUsers;
    this.usersObservable$ = this.cardDataSource.connect();

    this.userCount = filteredUsers.length;

    this.dataSource.data = filteredUsers;

    return filteredUsers;
  }

  saveImageData(file: File): void {
    this.form.get('avatar')?.setValue(file);
    this.form.get('avatar')?.markAsDirty();
  }

  private _setFormValues(): void {
    if (this.user) {
      this.form.patchValue({
        email: this.user.email,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        role: this.user.role !== 'null' ? this.user.role : '',
        function: this.user.function,
        entreprise: this.user.enterprise,
        address: this.user.address,
        phone: this.user.phone !== 'null' ? this.user.phone : '',
        phone_mobile: this.user.phone_mobile,
        avatar: this.user.avatar,
        is_brand_admin: [false],
      });
    }
  }

  private _error(error: ICompleteProfileErrorResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  manageUserDialog(user: IBrandUserResult): void {
    const dialogRef = this._dialog.open(ManageUserDialogComponent, {
      width: '1160px',
      height: '768px',
      panelClass: ['overflow-hidden-dialog'],
      autoFocus: false,
      data: user,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getBrandUsers();
      this._dataAuthService.updateUserAvatar.next(true);
    });
  }

  getBrandUsers() {
    this._brandsFacade.getBrandUsers$().subscribe({
      next: this._setBrandUsers.bind(this),
      error: this._error.bind(this),
    });
  }

  private _setBrandUsers(data: IBrandUsersResponse): void {
    this.brandUsers = data.results;
    this.dataSource.data = data.results;
    this.cardDataSource.data = data.results;
    this.paginator.pageSize = 12;
    this.usersObservable$ = this.cardDataSource.connect();

    this.brandUsers.forEach(el => {
      if (!el.first_name && !el.last_name) {
        el.first_name = 'Unknown contact';
      }
      if (!el.avatar) {
        el.avatar = '../../../assets/images/avatar-empty.png';
      }
    });
    this.myControl.setValue('');
  }

  onTabChange(index: number): void {
    this.selectedTabIndex = index;
    this._dataStorageService.set(SELECTED_TAB_INDEX_KEY, index.toString(), Storage.local);
  }

  ngOnDestroy() {
    // Optionally clear selected tab index from local storage
    this._dataStorageService.removeKey(SELECTED_TAB_INDEX_KEY, Storage.local);
  }
}
