import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CertificatesFacade } from '@app/core/facade/certificates.facade';
import {
  ICertDomainsAndCategories,
  ICertificate,
  ICertificateCategory,
  ICertificateDomain,
  ICertificatesResponse,
} from '@app/core/interface/certificates.interface';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { Observable, forkJoin } from 'rxjs';
import { CertificatesGroup } from '../certificates.group';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-certificate-dashboard',
  templateUrl: './certificate-dashboard.component.html',
  styleUrls: ['./certificate-dashboard.component.scss'],
  providers: [CertificatesGroup],
})
export class CertificateDashboardComponent {
  domains!: Array<ICertificateDomain>;
  categories!: Array<ICertificateCategory>;
  certificate!: ICertificate;
  form!: UntypedFormGroup;
  id!: string;
  mode = 'Create';
  readonly = false;
  domainName = '';
  categoryName = '';
  cancelBtn = 'Cancel';
  certificates: Array<ICertificate> = [];
  certificatesFullList: Array<ICertificate> = [];
  certificatesObservable$!: Observable<ICertificate[]>;
  dataSource = new MatTableDataSource<ICertificate>();
  cardDataSource = new MatTableDataSource<ICertificate>();

  constructor(
    private _group: CertificatesGroup,
    private _facade: CertificatesFacade,
    private _snackbarService: SnackbarService,
    public router: Router,
    public dialogRef: MatDialogRef<CertificateDashboardComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: string
  ) {
    this.form = this._group.certificateForm;
  }

  /**
   * On NgOnInit we are getting domains and categories
   */
  ngOnInit(): void {
    this.id = this.data;
    this.mode = this.id ? 'Edit' : 'Create';
    this.getDomainsAndCategories();
    if (this.id) {
      this.getCertificate(this.id);
    }
  }

  /**
   *
   * @param file sets selected image as form value fopr image control
   */
  saveImageData(file: File): void {
    this.form.get('image')?.setValue(file);
    this.form.get('image')?.markAsDirty();
  }

  /**
   * Depending on id on submiting the form we fire updateCertificate if id is present and
   * createCertificate api if id is not present in URL
   */
  submit(): void {
    this.form.controls['name'].markAsDirty();
    this.form.controls['category'].markAsDirty();
    this.form.controls['domain'].markAsDirty();
    if (this.form.controls['domain'].value === null) this.form.controls['domain'].setValue('');
    if (!this.form.controls['image'].value) this.form.controls['image'].setValue('');
    const submitForm = GetDirtyValues(this.form);
    this.id
      ? this._facade.updateCertificate$(this.id, submitForm).subscribe({
          next: this._navigateToCertificates.bind(this),
          error: this._error.bind(this),
        })
      : this._facade.createCertificate$(submitForm).subscribe({
          next: this._navigateToCertificates.bind(this),
          error: this._error.bind(this),
        });
  }

  /**
   * Gets the Domains and Categories and sets as options for selection
   */
  getDomainsAndCategories(): void {
    const domains = this._facade.getCertificatesDomains$();
    const categories = this._facade.getCertificatesCategories$();
    forkJoin({
      domains,
      categories,
    }).subscribe({
      next: this._setDomainsAndCategories.bind(this),
      error: this._error.bind(this),
    });
  }

  /**
   * Gets Certificate using id
   */
  getCertificate(id: string): void {
    this._facade.getCertificate$(id).subscribe({
      next: this._success.bind(this),
      error: this._navigateToCertificates.bind(this),
    });
  }

  /**
   *
   * @param res on successfuly getting domains and ctegories we are checking if there is id present in the url params
   * if so we are getting certificate using it's id from URL
   */
  private _setDomainsAndCategories(res: ICertDomainsAndCategories) {
    this.domains = res.domains.results;
    this.categories = res.categories.results;
  }

  /**
   *
   * @param response on succesful getting the Certificate we are setting certificate values to the form
   */
  private _success(response: ICertificate): void {
    this.certificate = response;
    this.form.controls['name'].setValue(response.name);
    this.form.controls['category'].setValue(response.category.uuid);
    if (response.domain) this.form.controls['domain'].setValue(response.domain.uuid);
    if (response.description) this.form.controls['description'].setValue(response.description);
    if (response.summary) this.form.controls['summary'].setValue(response.summary);
    if (response.external_url) this.form.controls['external_url'].setValue(response.external_url);
    if (response.image) this.form.controls['image'].setValue(response.image);
    if (this.readonly) {
      this.domainName = response?.domain?.name ?? '';
      this.categoryName = response?.category?.name ?? '';
    }
    this.form.updateValueAndValidity();
  }

  /**
   * Navigates to cetificate management (certificates type list)
   */
  private _navigateToCertificates(): void {
    this.router.navigate(['/settings/certificate-management']);
    this.dialogRef.close(true);
    this.getCertificates();
  }

  /**
   *
   * @param error Handles HTTP error desplaying message from backend
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  getCertificates() {
    this._facade.getCertificates$().subscribe({
      next: this._successD.bind(this),
      error: this._error.bind(this),
    });
  }

  private _successD(data: ICertificatesResponse) {
    this.certificates = data.results;
    this.certificatesFullList = [...this.certificates];
    this.dataSource.data = this.certificates;
    this.cardDataSource.data = this.certificates;
    this.certificatesObservable$ = this.cardDataSource.connect();
  }
}
