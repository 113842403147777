import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChainOverviewGroup } from '../../chain-overview.group';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IPublicationType } from '@app/core/interface/publication.interface';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { CustomPublicationsFacade } from '@app/module/publication/custom-publications/custom-publications.facade';
import { ICustomPublicationType } from '@app/module/publication/custom-publications/custom-publications.models';

@Component({
  selector: 'app-edit-classification',
  templateUrl: './edit-classification.component.html',
  styleUrls: ['./edit-classification.component.scss'],
  providers: [ChainOverviewGroup],
})
export class EditClassificationComponent {
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { classification: IPublicationType; product_chain: string },
    public dialogRef: MatDialogRef<EditClassificationComponent>,
    private _group: ChainOverviewGroup,
    private _snackbarService: SnackbarService,
    private _customPublicationFacade: CustomPublicationsFacade
  ) {
    this.form = this._group.editClassificationForm;
    this.form.patchValue({
      name: this.data.classification?.name,
      color: this.data.classification?.color ?? '#000000',
      product_chain: this.data.product_chain,
    });

    this.form.get('product_chain')?.markAsDirty();
  }

  updateClassification(): void {
    const changedFormValues: Partial<IPublicationType> = GetDirtyValues(this.form);
    this._customPublicationFacade
      .updateClassification({ ...changedFormValues }, this.data.product_chain, this.data.classification.uuid)
      .subscribe(
        () => {
          this.dialogRef.close({ data: changedFormValues, mode: 'update' });
        },
        error => this._error(error)
      );
  }

  cancel(): void {
    this.dialogRef.close();
  }

  saveChanges(): void {
    const changedFormValues: Partial<IPublicationType> = GetDirtyValues(this.form);
    this._customPublicationFacade.createClassification({ ...changedFormValues }, this.data.product_chain).subscribe({
      next: this.createClassificationSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  createClassificationSuccess(data: ICustomPublicationType) {
    this.dialogRef.close({ data, mode: 'create' });
  }

  updateClassificationSuccess(data: Partial<IPublicationType>) {
    this.dialogRef.close({ data, mode: 'update' });
  }
  colorPickerChange(color: string): void {
    this.form.get('color')?.setValue(color);
    this.form.get('color')?.markAsDirty();
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
