import { Component, ElementRef, Inject, QueryList, ViewChildren } from '@angular/core';
import { FormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { AddFieldsGroup } from '../add-fields.group';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { ICustomField, ICustomFieldContentType } from '@app/core/interface/add-fields.interface';
import { MatSelectChange } from '@angular/material/select';
import { AddFieldsFacade } from '@app/core/facade/add-fields.facade';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OptionRemoveErrorComponent } from './option-remove-error/option-remove-error.component';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

interface ICustomFieldType {
  name: string;
  value: ICustomFieldContentType;
  description: string;
  selected_description: string;
}
@Component({
  selector: 'app-create-custom-field',
  templateUrl: './create-custom-field.component.html',
  styleUrls: ['./create-custom-field.component.scss'],
  providers: [AddFieldsGroup, AddFieldsFacade],
})
export class CreateCustomFieldComponent {
  form!: UntypedFormGroup;
  fieldTypes: ICustomFieldType[] = [
    {
      name: 'Simple text',
      value: 'TEXT',
      description: 'For text up to 255 characters',
      selected_description: 'Up to 255 characters',
    },
    {
      name: 'Long text',
      value: 'TEXT AREA',
      description: 'For text up to 1000 characters',
      selected_description: 'Up to 1000 characters',
    },
    {
      name: 'Multiple Options',
      value: 'RADIO MULTIPLES',
      description: 'for field of predefined options, where\n one or many, can be selected',
      selected_description: '',
    },
  ];

  selectedFieldType!: ICustomFieldType;
  @ViewChildren('fieldOption') fieldOptions!: QueryList<ElementRef>;

  constructor(
    private _group: AddFieldsGroup,
    private _addFieldsFacade: AddFieldsFacade,
    private _snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<CreateCustomFieldComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { object_type: string; field: ICustomField },
    public dialog: MatDialog
  ) {
    this.form = this._group.createCustomFieldForm;
    dialogRef.disableClose = true;

    this.form.get('field_type')?.valueChanges.subscribe(value => {
      if (!data.field) {
        // if create mode
        if (value === 'RADIO MULTIPLES') {
          this.optionsFormArray.setValidators(Validators.required);
          this.optionsFormArray.updateValueAndValidity();
          this.onAddOption(); // add one empty option at start
        } else {
          if (this.optionsFormArray.hasValidator(Validators.required)) {
            this.optionsFormArray.clearValidators();
            this.optionsFormArray.clear();
            this.optionsFormArray.updateValueAndValidity();
          }
        }
      }
    });

    if (data.field) {
      this.setData();
    }
  }

  get optionsFormArray(): FormArray {
    return this.form.get('field_options') as FormArray;
  }

  setData() {
    this.form.patchValue({
      name: this.data.field.name,
      field_type: this.data.field.field_type,
    });

    const typeObj = this.fieldTypes.find(x => x.value === this.data.field.field_type);
    if (typeObj) this.selectedFieldType = typeObj;

    if (this.data.field.field_options.length) {
      this.data.field.field_options.forEach(option => {
        const optionForm = this._group.editOptionForm();
        optionForm.patchValue({
          option: option.option,
          uuid: option.uuid,
        });
        this.optionsFormArray.push(optionForm);
      });
    }
  }

  onFieldTypeChange(event: MatSelectChange) {
    const typeObj = this.fieldTypes.find(x => x.value === event.value);
    if (typeObj) this.selectedFieldType = typeObj;
  }

  onAddOption() {
    const optionForm = this._group.createOptionForm();
    this.optionsFormArray.push(optionForm);

    // focus on newly created input
    setTimeout(() => {
      if (this.fieldOptions && this.fieldOptions.last) {
        this.fieldOptions.last.nativeElement.focus();
      }
    });
  }

  onRemoveOption(index: number) {
    const optionUuid: string = this.optionsFormArray.at(index).get('uuid')?.value;

    if (optionUuid) {
      this._addFieldsFacade.deleteCustomFieldOption$(this.data.object_type, optionUuid).subscribe({
        next: () => this._removeOptionSuccess(index),
        error: this._removeOptionError.bind(this),
      });
    } else {
      this.optionsFormArray.removeAt(index);
    }
  }

  onAddEditField() {
    const changedFormValues: Partial<ICustomField> = GetDirtyValues(this.form);
    if (this.data.field) {
      this._addFieldsFacade
        .editCustomFieldWithType$(this.data.object_type, this.data.field.uuid, this.form.value)
        .subscribe({
          next: this._editFieldSuccess.bind(this),
          error: this._error.bind(this),
        });
    } else {
      this._addFieldsFacade.createCustomFieldWithType$(this.data.object_type, changedFormValues).subscribe({
        next: this._createFieldSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _createFieldSuccess(data: ICustomField): void {
    this._snackbarService.openTypeSnackbar(`Custom field created successfully`, NotificationType.success);
    this.dialogRef.close(data);
  }

  private _editFieldSuccess(data: ICustomField): void {
    this._snackbarService.openTypeSnackbar(`Custom field updated.`, NotificationType.success);
    this.dialogRef.close(data);
  }

  private _removeOptionSuccess(index: number): void {
    this.optionsFormArray.removeAt(index);
    this.data.field.field_options.splice(index, 1);
    this._snackbarService.openTypeSnackbar(`Field option deleted.`, NotificationType.success);
  }

  private _removeOptionError(): void {
    this.dialog.open(OptionRemoveErrorComponent, {
      width: '800px',
      height: '780px',
      panelClass: 'top-padding-0',
    });
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }
}
