import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ICertificationSupplier, IFactory, ISupplier } from '@interface/suppliers.interface';
import {
  ICustomPublicationStep,
  ISupplierCertificatesResponse,
} from '@module/publication/custom-publications/custom-publications.models';
import { NotificationType, Storage } from '@app/core/constants';
import { Observable, map, startWith } from 'rxjs';

import { CustomPublicationsFacade } from '@module/publication/custom-publications/custom-publications.facade';
import { DataStorageService } from '@service/data-localstorage.service';
import { IMapBoxFullAddress } from '@interface/publication.interface';
import { IMedia } from '@interface/medias.interface';
import { LngLatLike } from 'mapbox-gl';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PublicationFacade } from '@facade/publication.facade';
import { SnackbarService } from '@service/snackbar.service';
import { SuppliersFacade } from '@facade/suppliers.facade';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-amway-supplier-details',
  templateUrl: './amway-supplier-details.component.html',
  styleUrl: './amway-supplier-details.component.scss',
})
export class AmwaySupplierDetailsComponent implements OnInit {
  @Input() supplierForm!: UntypedFormGroup;
  @Input() manufactoryForm!: UntypedFormGroup;
  @Input() step?: ICustomPublicationStep;
  @Input() supplier?: ISupplier;
  @Output() refreshSupplier = new EventEmitter<boolean>();
  photos?: IMedia[];
  videos?: IMedia[];
  mapCenter?: LngLatLike = [0, 0];
  isSupplierConfidential? = false;
  isSupplierPrivate? = true;
  supplierList!: ISupplier[];
  filteredSuppliers!: Observable<ISupplier[]>;
  filteredManufactories?: Observable<IFactory[] | undefined>;
  selectedSupplier?: ISupplier;
  isAmway = false;
  certificates!: ICertificationSupplier[];

  constructor(
    private _facade: PublicationFacade,
    private _suppliersFacade: SuppliersFacade,
    private _dataStorageService: DataStorageService,
    private _customPublicationFacade: CustomPublicationsFacade,
    private _snackBarService: SnackbarService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['supplier'] && this.supplier) {
      this.photos = this.supplier.medias?.filter((media: IMedia) => media.mimetype.indexOf('image') > -1);
      this.videos = this.supplier.medias?.filter((media: IMedia) => media.mimetype.indexOf('video') > -1);
      this.getSupplierCertificates(this.supplier.uuid);
      this.isSupplierConfidential = this.supplier.is_hidden;
      this.supplierForm.patchValue({
        is_address_private: this.supplier.is_address_private,
        is_description_private: this.supplier.is_description_private,
        is_tags_private: this.supplier.is_tags_private,
        is_country_private: this.supplier.is_country_private,
        is_name_private: this.supplier.is_name_private,
        is_logo_private: this.supplier.is_logo_private,
      });
    }
  }

  ngOnInit(): void {
    const brandName = this._dataStorageService.get('brand_name', Storage.local);
    this.isAmway = brandName?.toLowerCase() === 'amway';

    this._suppliersFacade.getSupplierList$().subscribe(data => {
      this.supplierList = data.results;
      const supplier = data.results.find(x => x.uuid === this.step?.supplier?.uuid);
      if (supplier) {
        this.supplier = supplier;
        this.getSupplierCertificates(this.supplier.uuid);
        const manufactory = this.supplier?.manufactories[this.supplier?.manufactories.length - 1];
        if (manufactory) {
          this.manufactoryForm.patchValue({
            uuid: manufactory?.uuid,
            country: manufactory?.country,
            supplier: supplier.uuid,
            address: manufactory?.address,
            city: manufactory?.city,
            name: manufactory?.name,
            region: manufactory.region,
            latitude: manufactory.latitude,
            longitude: manufactory.longitude,
          });
          this.mapCenter = [manufactory.longitude ?? 0, manufactory.latitude ?? 0];
        }
      }
      this.filteredSuppliers = this.supplierForm.controls['name'].valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value.name ? value.name : value || ''))
      );
    });
  }

  getSupplierCertificates(uuid: string) {
    this._customPublicationFacade.getSupplierCertificates$(uuid).subscribe({
      next: this.getSupplierCertificatesSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  getSupplierCertificatesSuccess(data: ISupplierCertificatesResponse) {
    this.certificates = data.results;
  }

  saveImageData(file: File): void {
    this.supplierForm.get('logo')?.setValue(file ? file : '');
    this.supplierForm.get('logo')?.markAsDirty();
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackBarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  generatePin(): void {
    const address = this.manufactoryForm.get('address')?.value;
    const city = this.manufactoryForm.get('city')?.value;
    const country = this.manufactoryForm.get('country')?.value;
    const region = this.manufactoryForm.get('region')?.value;

    const searchQuery = `${address ? address + ', ' : ''}${city ? city + ', ' : ''}${region ? region + ', ' : ''}${country ? country : ''}`;

    if (searchQuery) {
      this._facade.getFullAddress$(searchQuery).subscribe((data: IMapBoxFullAddress) => {
        if (data.features.length > 0 && data.features[0].center) {
          this.mapCenter = data.features[0].center;
          const cordinates = this.getCoordinates(data.features[0].center);
          this.manufactoryForm.patchValue({
            latitude: cordinates.lat,
            longitude: cordinates.lng,
          });
          this.manufactoryForm.controls['latitude'].markAsDirty();
          this.manufactoryForm.controls['longitude'].markAsDirty();
        }
      });
    }
  }

  validateMapFields(): boolean {
    const address = this.manufactoryForm.get('address')?.value;
    const city = this.manufactoryForm.get('city')?.value;
    const country = this.manufactoryForm.get('country')?.value;
    const region = this.manufactoryForm.get('region')?.value;
    return !(!address && !city && !country && !region);
  }

  onSupplierChange(selection: MatAutocompleteSelectedEvent): void {
    this.supplier = selection.option.value;
    this.supplierForm.patchValue({
      uuid: selection.option.value.uuid,
      name: selection.option.value.name,
      description: selection.option.value.name,
    });

    if (selection.option.value.manufactories.length > 0) {
      const manufactory = selection.option.value.manufactories[selection.option.value.manufactories.length - 1];

      this.manufactoryForm.patchValue({
        city: manufactory.city,
        country: manufactory.country,
        region: manufactory.region,
        address: manufactory.address,
        supplier: this.supplier?.uuid,
      });
    } else {
      this.manufactoryForm.patchValue({
        city: selection.option.value.city,
        country: selection.option.value.country,
        region: selection.option.value.region,
        address: selection.option.value.address,
        supplier: this.supplier?.uuid,
      });
    }

    this.filteredManufactories = this.manufactoryForm.controls['address'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterManufactory(value.name ? value.name : value || ''))
    );
  }

  onAddressChange(selection: MatAutocompleteSelectedEvent): void {
    this.manufactoryForm.patchValue({
      city: selection.option.value.city,
      country: selection.option.value.country,
      region: selection.option.value.region,
      address: selection.option.value.address,
    });
  }

  displayFn(supplier: ISupplier | null): string {
    return supplier?.name ?? '';
  }

  private _filter(value: string): ISupplier[] {
    const filterValue = value.toLowerCase();

    return this.supplierList?.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterManufactory(value: string): IFactory[] | undefined {
    const filterValue = value.toLowerCase();

    return this.selectedSupplier?.manufactories?.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  makeSupplierConfidential($event: MatCheckboxChange) {
    this.supplierForm.patchValue({
      is_address_private: $event.checked,
      is_description_private: $event.checked,
      is_tags_private: $event.checked,
      is_country_private: $event.checked,
      is_name_private: $event.checked,
      is_logo_private: $event.checked,
    });
    this.supplierForm.controls['is_address_private'].markAsDirty();
    this.supplierForm.controls['is_description_private'].markAsDirty();
    this.supplierForm.controls['is_tags_private'].markAsDirty();
    this.supplierForm.controls['is_country_private'].markAsDirty();
    this.supplierForm.controls['is_name_private'].markAsDirty();
    this.supplierForm.controls['is_logo_private'].markAsDirty();
  }

  getCoordinates(lngLatLike: LngLatLike): { lng: number; lat: number } {
    let lng: number;
    let lat: number;

    if (Array.isArray(lngLatLike)) {
      // Case 1: Array of numbers
      lng = lngLatLike[0];
      lat = lngLatLike[1];
    } else if ('lng' in lngLatLike) {
      // Case 2: Object with lng and lat
      lng = lngLatLike.lng;
      lat = lngLatLike.lat;
    } else if ('lon' in lngLatLike) {
      // Case 3: Object with lon and lat
      lng = lngLatLike.lon;
      lat = lngLatLike.lat;
    } else {
      throw new Error('Invalid LngLatLike format');
    }

    return { lng, lat };
  }
}
