import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { CompanyInfoGroup } from '../company-info.group';
import { ICountry } from '@app/core/interface/suppliers.interface';
import { Observable, startWith, map, takeWhile } from 'rxjs';
import { CountryList } from '@app/core/utils/country-list';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-edit-info',
  templateUrl: './edit-info.component.html',
  styleUrls: ['./edit-info.component.scss'],
  providers: [CompanyInfoGroup],
})
export class EditInfoComponent implements OnInit, OnDestroy {
  fieldControl!: FormControl;
  form!: UntypedFormGroup;
  countryList = CountryList.isoCountries;
  filteredCountries!: Observable<ICountry[]>;
  title!: string;
  label!: string;
  alive = true;
  constructor(
    public dialogRef: MatDialogRef<EditInfoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fieldName: string;
      fieldValue: string;
      required: boolean;
      max?: number;
      supplier_unique_identifier?: string;
      unique_identifier_type?: string;
      site?: IManufactory;
    },
    private _facade: SuppSuppliersFacade,
    private _snackbarService: SnackbarService,
    private _group: CompanyInfoGroup,
    public translate: TranslateService
  ) {
    // this.translate.setDefaultLang('en');
    this.setForm();
  }

  ngOnInit(): void {
    this.setData();
    if (this.form?.controls['country'])
      this.filteredCountries = this.form.controls['country'].valueChanges.pipe(
        takeWhile(() => this.alive),
        startWith(''),
        map(value => {
          const countryObject = CountryList.isoCountries.find(
            country => country.name.toLocaleLowerCase().trim() === value.toLocaleLowerCase().trim()
          );
          if (countryObject) {
            this.form.controls['country'].setValue(countryObject, { emitEvent: false });
          }
          return this._filter((typeof value === 'string' && value) || '');
        })
      );
  }
  ngOnDestroy(): void {
    this.alive = false;
  }

  setData(): void {
    if (this.data.fieldName === 'supplier_unique_identifier') {
      this.title = this.translate.instant('company-information.Edit identifier number & type');
    }
    if (this.data.fieldName === 'logo') {
      this.title = this.translate.instant('company-information.Edit logo');
    }
    if (this.data.fieldName === 'name') {
      this.title = this.translate.instant('company-information.Edit name');
      this.label = this.translate.instant('company-information.Enter name');
    }
    if (this.data.fieldName === 'description') {
      this.title = this.translate.instant('company-information.Edit description');
      this.label = this.translate.instant('company-information.Enter description');
    }
    if (this.data.fieldName === 'address') {
      this.title = this.translate.instant('company-information.Edit main address');
    }
  }
  setForm(): void {
    if (this.data.fieldName === 'supplier_unique_identifier') {
      this.form = this._group.identifier_group;
      this.form.controls['supplier_unique_identifier'].setValue(this.data.supplier_unique_identifier);
      this.form.controls['unique_identifier_type'].setValue(this.data.unique_identifier_type);
    } else if (this.data.fieldName === 'address') {
      this.form = this._group.address_group;
      if (this.data.site) {
        this.form.controls['country'].setValue(CountryList.getCountryByName(this.data.site.country));
        this.form.controls['region'].setValue(this.data.site.region);
        this.form.controls['city'].setValue(this.data.site.city);
        this.form.controls['address'].setValue(this.data.site.address);
      }
    } else if (this.data.fieldName === 'description') {
      this.form = this._group.description_group;
      if (this.data.fieldValue) {
        this.form.controls['description'].setValue(this.data.fieldValue);
      }
    } else {
      this.fieldControl = new FormControl(this.data.fieldValue, this.data.required ? Validators.required : null);
      if (this.data.max) this.fieldControl.addValidators(Validators.maxLength(this.data.max));
      this.fieldControl.updateValueAndValidity();
    }
  }
  onSave(): void {
    if (this.data.fieldName !== 'address') {
      const payload =
        this.data.fieldName !== 'supplier_unique_identifier' && this.data.fieldName !== 'description'
          ? { [this.data.fieldName]: this.fieldControl ? this.fieldControl.value : [this.form.value] }
          : this.form.value;
      this._facade.updateCompanyInfo$(payload).subscribe({
        next: this._onSaveSuccess.bind(this),
        error: this._error.bind(this),
      });
    } else {
      const payload = {
        name: this.data.site?.name,
        country: this.form.controls['country'].value.code,
        region: this.form.controls['region'].value,
        city: this.form.controls['city'].value,
        address: this.form.controls['address'].value,
        is_main_location: true,
      };
      if (this.data.site?.uuid) {
        this._facade.updateSupplierSite$(payload, this.data.site?.uuid ?? '').subscribe({
          next: this._onSaveSuccess.bind(this),
          error: this._error.bind(this),
        });
      } else {
        this._facade
          .createSupplierSite$({
            ...payload,
            name: 'Site 1',
            country: payload.country ?? '',
            region: payload.region ?? '',
            city: payload.city ?? '',
            address: payload.address ?? '',
          })
          .subscribe({
            next: this._onSaveSuccess.bind(this),
            error: this._error.bind(this),
          });
      }
    }
  }
  saveImageData(file: File): void {
    this.fieldControl?.setValue(file ? file : '');
    this.fieldControl?.markAsDirty();
  }
  /**
   * Shows only name as display value and keeps hole obj as actual value (code is used for requiest and name for display)
   * @param subject ICountry from country dropdown
   * @returns country name for display
   */
  displayFn(subject: ICountry | null): string {
    return subject?.name ?? '';
  }

  private _filter(value: string): ICountry[] {
    const filterValue = value.toString().toLowerCase();

    return this.countryList.filter(country => country.name.toLowerCase().includes(filterValue));
  }
  private _onSaveSuccess(): void {
    if (this.data.fieldName === 'supplier_unique_identifier') {
      this.dialogRef.close([
        { fieldName: 'supplier_unique_identifier', fieldValue: this.form.controls['supplier_unique_identifier'].value },
        { fieldName: 'unique_identifier_type', fieldValue: this.form.controls['unique_identifier_type'].value },
      ]);
      return;
    }
    this.dialogRef.close([
      {
        fieldName: this.data.fieldName,
        fieldValue: this.fieldControl
          ? this.fieldControl.value
          : this.data.fieldName === 'description'
            ? this.form.value.description
            : this.form.value,
      },
    ]);
  }
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
