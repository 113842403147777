import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { CertificateViewComponent } from '@app/shared/components/certificate-view/certificate-view.component';
import { CertificatesFacade } from '@app/core/facade/certificates.facade';
import { CertificationDashboardComponent } from '@app/module/dashboard/certificates/certification-dashboard/certification-dashboard.component';
import { ICertificationSupplier } from '@app/core/interface/suppliers.interface';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-supplier-certificates-grid',
  templateUrl: './supplier-certificates-grid.component.html',
  styleUrls: ['./supplier-certificates-grid.component.scss'],
})
export class SupplierCertificatesGridComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @Input() uuid!: string;
  @Input() certification_supplier!: ICertificationSupplier[];
  @Input() sites!: Partial<IManufactory>[];
  @Output() getSupplier = new EventEmitter<boolean>(false);
  cardDataSource: MatTableDataSource<ICertificationSupplier> = new MatTableDataSource<ICertificationSupplier>();
  certificatesObservable$!: Observable<ICertificationSupplier[]>;

  constructor(
    private _dialog: MatDialog,
    private _facade: CertificatesFacade,
    private _snackbarService: SnackbarService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['certification_supplier']) this.cardDataSource.data = changes['certification_supplier'].currentValue;
    this.certificatesObservable$ = this.cardDataSource.connect();
  }

  ngAfterViewInit() {
    this.paginator.pageSize = 6;
    this.cardDataSource.sort = this.sort;
    this.cardDataSource.paginator = this.paginator;
    this.cdRef.detectChanges();
  }

  /**
   *
   * @param element elements detail from element selected for cheange (we pass tahat detail to the dialog component)
   */
  editCertification(element: ICertificationSupplier) {
    const dialogRef = this._dialog.open(CertificationDashboardComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      autoFocus: false,
      data: {
        content_type: 'Supplier',
        uuid: this.uuid,
        element: element,
        manufactories: this.sites,
        alreadySelected: element.manufactories,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getSupplier.emit(true);
      }
    });
  }

  deleteCertificate(certificate_uuid: string, name: string): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete certificate ${name}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._facade.deleteCertification$(this.uuid, certificate_uuid).subscribe({
          next: this.getSuppliers.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  getSuppliers() {
    this.getSupplier.emit(true);
  }
  /**
   *
   * @param error Handles HTTP error desplaying message from backend
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  openViewFile(certificate: ICertificationSupplier) {
    const name = certificate?.document?.split('/').at(-1);
    this._dialog.open(CertificateViewComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: { url: certificate.document, name, mimetype: name, certificate },
    });
  }
}
