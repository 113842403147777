import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  ICertificationProduct,
  IProduct,
  IProductCertificatesRespone,
  IProductResponse,
} from '@app/core/interface/products.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { CertificateViewDetailsComponent } from '@app/shared/components/certificate-view-details/certificate-view-details.component';
import { CertificationDashboardComponent } from '../../certificates/certification-dashboard/certification-dashboard.component';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-product-certificates',
  templateUrl: './product-certificates.component.html',
  styleUrls: ['./product-certificates.component.scss'],
  providers: [ProductsFacade],
})
export class ProductCertificatesComponent implements OnInit, AfterViewInit {
  @Input() id!: string;
  @Input() product!: IProduct | IProductResponse;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource: MatTableDataSource<ICertificationProduct> = new MatTableDataSource<ICertificationProduct>();
  document: string | undefined;
  displayedColumns: string[] = ['name', 'certificator', 'start-date', 'end-date', 'status', 'actions'];
  @Input() certification_product!: ICertificationProduct[];
  constructor(
    private _productsFacade: ProductsFacade,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  /**
   * On OnInit lifecycle we are getting the data
   */
  ngOnInit(): void {
    this.getProductCertificates();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['certification_product']) this.dataSource.data = changes['certification_product'].currentValue;
  }
  /**
   * Angular lifecycle hook after view checked we are setting data sort
   */
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageSize = 6;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item['certificate'].name.toUpperCase();
        case 'certificator':
          return item['certificate']['domain'].name.toUpperCase();
        case 'start-date': {
          if (item['validity_start']) {
            return item['validity_start'];
          }
          return 0;
        }
        case 'end-date': {
          if (item['validity_end']) {
            return item['validity_end'];
          }
          return 0;
        }

        case 'status': {
          if (item['expired']) {
            return 1;
          }
          return 0;
        }

        default:
          return item[property as keyof ICertificationProduct] as string;
      }
    };
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Get all products certificates
   */
  getProductCertificates() {
    this._productsFacade.getProductCertificates$(this.id).subscribe({
      next: this._getcertificatesSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  /**
   *
   * @param element elements detail from element selected for cheange (we pass tahat detail to the dialog component)
   */
  editCertification(element: ICertificationProduct) {
    const dialogRef = this._dialog.open(CertificationDashboardComponent, {
      width: '1160px',
      minHeight: '680px',
      panelClass: 'top-padding-0',
      data: {
        content_type: 'Product',
        uuid: element.product,
        element: element,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getProductCertificates();
      }
    });
  }

  /**
   * In order to view file we are oppening common dialog passing the URL and name as params
   * @param url file url
   * @param name file name
   */
  openViewFile(certificate: ICertificationProduct, url: string, name: string) {
    this._dialog.open(CertificateViewDetailsComponent, {
      width: '1160px',
      maxWidth: '1160px',
      maxHeight: '90%',
      data: {
        url,
        name,
        mimetype: name,
        certificate,
        productName: this.product.name,
        logo: this.product.image,
      },
    });
  }

  /**
   *
   * @param date validity_end
   * @returns boolean comparating validity_end with date now
   */
  expired(date: string) {
    return new Date(date) < new Date();
  }

  /**
   *
   * @param list Table data
   * @returns list of product certification mapped with nnew field expired
   */
  mapResponse(list: ICertificationProduct[]): ICertificationProduct[] {
    return list.map(res => {
      return {
        ...res,
        expired: res.validity_end ? this.expired(res.validity_end) : false,
      };
    });
  }

  /**
   *
   * @param params after successful  fetching product certificates we are setting them in the table
   */
  private _getcertificatesSuccess(params: IProductCertificatesRespone) {
    this.dataSource.data = params.results?.length ? this.mapResponse(params.results) : [];
  }

  /**
   *
   * @param error handles error form API using error message
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  /**
   *
   * Delete certificate
   */
  deleteCertificate(certificate_uuid: string, name: string): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete certificate ${name}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._productsFacade.deleteCertification$(this.id, certificate_uuid).subscribe({
          next: this.getProductCertificates.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }
}
