import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IMedia, IMediaContentType, IMediaScreens } from '@app/core/interface/medias.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { AddDocumentsAndMediasComponent } from '../add-documents-and-medias/add-documents-and-medias.component';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { getViewerType } from '@app/core/utils/ngx-doc-type';
import { viewerType } from 'ngx-doc-viewer';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss'],
})
export class FilesListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() dialogData!: {
    type: IMediaContentType;
    typeUUID: string;
    data: IMedia[];
    screen: IMediaScreens;
  };
  @Output() onFileSelected = new EventEmitter<IMedia>();
  @Output() refresh = new EventEmitter<boolean>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  cardDataSource = new MatTableDataSource<IMedia>();
  files$!: Observable<IMedia[]>;
  constructor(
    private _dialog: MatDialog,
    private _facade: FilesMeadiasLinksFacade,
    private _snackbarService: SnackbarService
  ) {}
  ngOnInit(): void {
    setTimeout(() => {
      this.cardDataSource.paginator = this.paginator;
      this.cardDataSource.data = this.dialogData.data;
      this.files$ = this.cardDataSource.connect();
    }, 0);
  }

  ngAfterViewInit() {
    this.cardDataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.cardDataSource) {
      this.cardDataSource.disconnect();
    }
  }

  openSingleFile(selectedFile: IMedia) {
    this.onFileSelected.emit(selectedFile);
  }

  openConfirmationDialog(name: string, uuid: string): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to remove ${name}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.delete(this.dialogData.screen, uuid);
      }
    });
  }

  delete(screen: IMediaScreens, screenUuid: string) {
    this._facade.deleteMedia$(this.dialogData.type, this.dialogData.typeUUID, screen, screenUuid).subscribe({
      next: this._deleteSuccess.bind(this, screenUuid),
      error: this._error.bind(this),
    });
  }

  editFile(doc: IMedia) {
    const dialog = this._dialog.open(AddDocumentsAndMediasComponent, {
      width: '1160px',
      height: '768px',
      panelClass: ['padding-0'],
      data: {
        screen: this.dialogData.screen,
        type: this.dialogData.type,
        typeUuid: this.dialogData.typeUUID,
        doc: doc,
        edit: true,
      },
    });

    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.dialogData.data[this.dialogData.data.indexOf(doc)] = res;
        this.cardDataSource.data = this.dialogData.data;
        this.refresh.emit(true);
      }
    });
  }
  getViewerType(mimetype: string): viewerType {
    return getViewerType(mimetype);
  }

  onChange(file: IMedia) {
    const originalValue = file.is_entity_private;
    file.is_entity_private = !file.is_entity_private;
    this._facade
      .updateMedia$(
        { is_entity_private: file.is_entity_private },
        this.dialogData.type,
        this.dialogData.typeUUID,
        this.dialogData.screen,
        file.uuid
      )
      .subscribe({
        next: () => {
          this.refresh.emit(true);
        },
        error: err => {
          // Revert to the original value if there was an error
          file.is_entity_private = originalValue;
          this._error(err);
        },
      });
  }

  private _deleteSuccess(uuid: string) {
    this.dialogData.data = this.dialogData.data.filter((media: IMedia) => media.uuid !== uuid);
    this.cardDataSource.data = this.dialogData.data;

    this.refresh.emit(true);
  }
  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }
}
