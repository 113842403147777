import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IBrandUserResult, IBrandUsersResponse, IDeleteBrandUser } from '@app/core/interface/brands.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-paid-users',
  templateUrl: './paid-users.component.html',
  styleUrls: ['./paid-users.component.scss'],
  providers: [BrandsFacade],
})
export class PaidUsersComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  displayedColumns: string[] = ['name', 'email', 'actions'];
  tableDataSource = new MatTableDataSource<IBrandUserResult>();
  brandUsers: IBrandUserResult[] = [];
  deleteUuid: string;

  constructor(
    private _brandsFacade: BrandsFacade,
    private _snackbarService: SnackbarService,
    public dialog: MatDialog
  ) {
    this.deleteUuid = '';
  }

  ngOnInit(): void {
    this._brandsFacade.getBrandUsers$().subscribe({
      next: this._setBrandUsers.bind(this),
      error: this._error.bind(this),
    });
  }

  ngAfterViewInit() {
    this.tableDataSource.sort = this.sort;
    // Set the paginator after the data is fetched
    this._brandsFacade.getBrandUsers$().subscribe({
      next: (data: IBrandUsersResponse) => {
        this.brandUsers = data.results;
        this.tableDataSource.data = data.results;
        this.paginator.pageSize = 12;
        this.tableDataSource.paginator = this.paginator;
      },

      error: this._error.bind(this),
    });
  }

  ngOnDestroy() {
    if (this.tableDataSource) {
      this.tableDataSource.disconnect();
    }
  }

  deleteBrandUser(uuid: string, email: string): void {
    this.deleteUuid = uuid;

    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete brand user: ${email}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._brandsFacade.deleteBrandUser$(uuid).subscribe({
          next: this._deleteUser.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  private _setBrandUsers(data: IBrandUsersResponse): void {
    this.brandUsers = data.results;
    this.tableDataSource.data = data.results;
    this.paginator.pageSize = 12;
  }

  private _deleteUser(data: IDeleteBrandUser): void {
    this.brandUsers = this.brandUsers.filter(user => user.uuid !== this.deleteUuid);
    this.tableDataSource.data = this.brandUsers;

    this._snackbarService.openTypeSnackbar(data.detail, NotificationType.success);
    this.deleteUuid = '';
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
