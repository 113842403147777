<div class="holder-wrapper d-flex flex-column justify-content-between">
  <div class="content-wrap">
    <div class="header d-flex justify-content-between">
      <div class="title">
        <p class="title-text">
          {{
            'Here you can configure the geopositioning and which fields and media will be visible for your suppliers when published'
              | translate
          }}
        </p>
      </div>
      <div class="filter">
        <mat-form-field class="input-primary mat-form-field-10-px-brd-rd custom-filter-header" appearance="outline">
          <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Product" #input />
          <app-custom-icon
            class="search-filter"
            [icon_category]="'search'"
            [icon_name]="'search-normal-2'"
          ></app-custom-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="holder">
      <div class="card-holder" *ngFor="let supplier of publicationSuppliersObservable$ | async">
        <app-supplier-publication-card
          [supplier]="supplier"
          (refresh)="getPublicationSuppliersList()"
        ></app-supplier-publication-card>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of suppliers"></mat-paginator>
  </div>
</div>
