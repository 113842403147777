import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { IMedia, IMediaScreens } from '@app/core/interface/medias.interface';
import { IProductChainStep, IProductChainStepResponse } from '@app/core/interface/steps.interface';

import { AddDocumentsAndMediasComponent } from '@app/shared/components/add-documents-and-medias/add-documents-and-medias.component';
import { BatchFacade } from '@app/core/facade/batch.facade';
import { FileViewComponent } from '@app/shared/components/file-view/file-view.component';
import { IProduct } from '@app/core/interface/products.interface';
import { ISupplier } from '@app/core/interface/suppliers.interface';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { OnChanges } from '@angular/core';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { ProductsService } from '@app/core/service/products.service';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { StepsService } from '@app/core/service/steps.service';

@Component({
  selector: 'app-documents-tab',
  templateUrl: './documents-tab.component.html',
  styleUrls: ['./documents-tab.component.scss'],
  providers: [BatchFacade, ProductsFacade],
})
export class DocumentsTabComponent implements OnInit, OnChanges {
  @Input() suppliers?: ISupplier[];
  @Input() product?: IProduct;
  @Input() stepLevelView?: boolean;
  @Input() selectedStep?: IProductChainStep;
  supplierDocuments?: ISupplier[] = [];
  selectedSupplier?: ISupplier;
  batchSteps?: IProductChainStep[];

  constructor(
    private _dialog: MatDialog,
    private _batchFacade: BatchFacade,
    private _snackbarService: SnackbarService,
    private _productService: ProductsService,
    private _stepService: StepsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['suppliers']) {
      this.supplierDocuments = this.suppliers?.filter(
        supplier => supplier.medias?.length || supplier.documents?.length
      );
      this.selectedSupplier = this.selectedSupplier
        ? this.supplierDocuments?.find(supplier => supplier.uuid === this.selectedSupplier?.uuid)
        : this.supplierDocuments?.[0];
    }
    if (changes['product']) {
      this.product = changes['product'].currentValue;
    }
  }

  ngOnInit() {
    if (!this.stepLevelView) {
      this._batchFacade.getBatchSteps$().subscribe({
        next: this._getBatchStepsSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  changeSupplier(supplier: ISupplier): void {
    this.selectedSupplier = supplier;
  }

  changeStep(step: IProductChainStep): void {
    this.selectedStep = step;
  }

  viewFile(file: IMedia) {
    this._dialog.open(FileViewComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'padding-0',
      data: {
        url: file.file,
        name: file.name,
        mimetype: file.mimetype,
        created_at: file.created_at,
        isBatch: true,
        backTitle: this.stepLevelView ? 'Certificates & Documents' : 'Creating Batch',
      },
    });
  }

  addFile(screen: IMediaScreens, type: string, uuid?: string, doc?: Partial<IMedia>) {
    const dialog = this._dialog.open(AddDocumentsAndMediasComponent, {
      width: '1160px',
      height: '768px',
      panelClass: ['padding-0'],
      data: {
        screen: screen,
        type: type,
        typeUuid: uuid,
        doc: doc,
        isBatch: true,
        backTitle: this.stepLevelView ? 'Certificates & Documents' : 'Creating Batch',
      },
    });
    dialog.afterClosed().subscribe(res => {
      if (res) {
        if (type !== 'batchsteps') {
          this._productService.refetchProductDetails.next(true);
        } else {
          this.ngOnInit();
          this._stepService.refetchStepDetails.next(true);
        }
      }
    });
  }

  private _getBatchStepsSuccess(data: IProductChainStepResponse): void {
    this.batchSteps = data.results.filter(step => step.medias?.length || step.documents?.length);
    this.selectedStep = this.selectedStep
      ? this.batchSteps.find(step => step.uuid === this.selectedStep?.uuid)
      : this.batchSteps[0];
  }

  private _error(error: IProductChainStepResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
