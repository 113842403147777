import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  DIRECT_SUPPLIER,
  FIRST_LOGIN,
  MAIN,
  NotificationType,
  PROJECT_MANAGEMENT,
  USER,
  chainStepsTutorialTooltip,
  infoDialogHeight,
  infoDialogWidth,
} from '@app/core/constants';
import {
  IBatchChainStep,
  ISuppBatchDirectSuppResponse,
  ISuppChainStep,
  ISuppManufactory,
  ISuppManufactoryResponse,
  ISuppStepBatchDirectSupplier,
  ISuppStepDirectSuppResponse,
  ISuppStepDirectSupplier,
} from '@app/core/interface/supp.interface';
import { IStepSection, SupplierStatus } from '@app/core/interface/steps.interface';
import { Observable, Subject, of, takeUntil } from 'rxjs';

import { AccountsService } from '@app/core/service/accounts.service';
import { BatchMainInfoComponent } from './batch-main-info/batch-main-info.component';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { IBrandFeaturesResponse } from '@app/core/interface/brands.interface';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { LoginFacade } from '@app/core/facade/login.facade';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { STEP_SECTIONS } from '../chain-steps-info.config';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { StepDirectSupplierComponent } from './step-direct-supplier/step-direct-supplier.component';
import { StepMainInfoComponent } from './step-main-info/step-main-info.component';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { UpdateStepAlertComponent } from './update-step-alert/update-step-alert.component';
import { UrlStorageService } from '@app/core/service/url-storage.service';

interface ITutorialTooltip {
  id?: number;
  text: string;
  buttonText: string;
  x?: string;
  y?: string;
  isLast?: boolean;
}

@Component({
  selector: 'app-edit-step',
  templateUrl: './edit-step.component.html',
  styleUrls: ['./edit-step.component.scss'],
})
export class EditStepComponent implements OnInit, OnDestroy {
  @ViewChild(StepMainInfoComponent) stepMainInfoComponent!: StepMainInfoComponent;
  @ViewChild(StepDirectSupplierComponent) stepDirectSupplierComponent!: StepDirectSupplierComponent;
  @ViewChildren('sectionsList') sectionsList!: QueryList<ElementRef>;
  @ViewChild(BatchMainInfoComponent) batchMainInfoComponent!: BatchMainInfoComponent;

  supplier_sites!: ISuppManufactory[];
  dataSource = new MatTableDataSource<ISuppStepBatchDirectSupplier | ISuppStepDirectSupplier>();
  dataSourceStepBatch = new MatTableDataSource<ISuppStepBatchDirectSupplier>();
  directSuppliers!: ISuppStepBatchDirectSupplier[];
  stepBatchUpdated!: IBatchChainStep;

  icon = { category: 'essential', name: 'info-circle' };
  showTooltip = true;
  tutorialTooltip: ITutorialTooltip = chainStepsTutorialTooltip;

  firstLogin = false;
  isSaving = false;

  timeoutId1?: NodeJS.Timeout;
  timeoutId2?: NodeJS.Timeout;

  selected_site!: UntypedFormControl;
  screen = MAIN;
  step!: ISuppChainStep;
  stepBatch!: IBatchChainStep;
  is_view = false;

  warningConfirmation = false;
  warningAnyField = false;
  warningSite = false;

  is_batch = false;
  inactiveDirectSupp = false;
  showInactiveTooltip = false;
  is_enabled!: boolean;
  // Unsubscribe cleaner
  private _unsubscribe$: Subject<void> = new Subject();
  sections: IStepSection[] = STEP_SECTIONS;

  constructor(
    public dialog: MatDialog,
    private _facade: SuppSuppliersFacade,
    private _snackbarService: SnackbarService,
    private _route: ActivatedRoute,
    private _router: Router,
    public translate: TranslateService,
    private _accountsService: AccountsService,
    private _loginFacade: LoginFacade,
    private _ulrService: UrlStorageService,
    private _facadeBrands: BrandsFacade
  ) {
    this.selected_site = new UntypedFormControl();
    const queryParams = this._route.snapshot.queryParams;
    const url = this._route.snapshot.url;
    this.is_view = url[0].path === 'view-step';
    if (queryParams['is_batch']) {
      this.is_batch = queryParams['is_batch'] === 'true';
    }
    if (queryParams['is_view']) {
      this.is_view = queryParams['is_view'] === 'true';
    }
    // Check if the user logs for the first time in order to show him the tutorial tooltips
    const storageUser = localStorage.getItem(USER);
    const user = storageUser ? JSON.parse(storageUser) : '';
    const alreadyLoged = JSON.parse(localStorage.getItem(FIRST_LOGIN) ?? '[]');
    if (!alreadyLoged.includes(user.uuid)) {
      alreadyLoged.push(user.uuid);
      localStorage.setItem(FIRST_LOGIN, JSON.stringify(alreadyLoged));
      this.firstLogin = true;
    } else {
      this.firstLogin = false;
    }
  }

  ///////////////////// Lifecycle hooks /////////////////////
  ngOnInit(): void {
    const uuid = this._route.snapshot.paramMap.get('id');

    if (uuid) {
      if (this.is_batch) {
        this.getSuppStepBatch(uuid);
      } else {
        this._facade.getSupplierStep$(uuid).subscribe({
          next: this._getSupplierStepSuccess.bind(this),
          error: this._error.bind(this),
        });
      }
    }

    // Subscribe to value change on supplier site for autosaving
    this.selected_site.valueChanges.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
      this._facade.changingFlagSignal.set(true);
      this.updateSite();
    });
    this._getBrandFeatures();
  }

  private _getBrandFeatures() {
    this._facadeBrands.getSuppBrandFeatures$().subscribe({
      next: this._getBrandFeaturesSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _getBrandFeaturesSuccess(data: IBrandFeaturesResponse): void {
    if (data.results) {
      const projectManagement = data.results.find(project => project.name === PROJECT_MANAGEMENT);
      if (projectManagement) {
        this.is_enabled = projectManagement.is_enabled;
      }
    }
  }
  getSuppStepBatch(uuid: string): void {
    this._facade.getSupplierStepBatch$(uuid).subscribe({
      next: this._getSupplierStepBatchSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();

    this.timeoutId1 && clearTimeout(this.timeoutId1);
    this.timeoutId2 && clearTimeout(this.timeoutId2);
  }

  // Method for confirm guard when leaving this route
  canDeactivate(): Observable<boolean> {
    const isChangedFlag = this._facade.changingFlagSignal();
    const isChangeUnsaved = this._accountsService.getIsSaved();
    if (this.is_batch) {
      this._checkWarningMandatoryFields();
      if (!this.warningAnyField && (isChangedFlag || isChangeUnsaved)) {
        this.openExitPageAlert();
        return of(false);
      } else {
        return of(true);
      }
    } else {
      if (isChangedFlag) {
        this.openExitPageAlert();
        return of(false);
      } else {
        return of(true);
      }
    }
  }

  // when clicking on right sections panel
  onSectionClicked(section: IStepSection) {
    if (!section.locked) {
      this.screen = section.section;
    }

    if (section.section === DIRECT_SUPPLIER) {
      this.tutorialTooltip.id = 3;
      this.onTutorialTooltipClick();
    } else {
      this.showTooltip = false;
    }
  }

  initTutorialTooltip() {
    const dropdownEl = this.stepMainInfoComponent?.supplierSiteDropdown?.nativeElement;

    this.tutorialTooltip.x = (dropdownEl.offsetLeft + 143).toString() + 'px';
    this.tutorialTooltip.y = (dropdownEl.offsetTop - 190).toString() + 'px';
  }

  onTutorialTooltipClick() {
    if (!this.is_batch) {
      if (this.tutorialTooltip.id == 1) {
        const directSuppliersEl = this.sectionsList.get(1)?.nativeElement;
        this.tutorialTooltip = {
          id: 2,
          text: 'supply-chain-step.Next, identify here the direct suppliers you have worked with in this step, in order to complete it',
          buttonText: 'supply-chain-step.Ok, got it',
          x: (directSuppliersEl.offsetLeft - 436).toString() + 'px',
          y: (directSuppliersEl.offsetTop - 12).toString() + 'px',
        };
      } else if (this.tutorialTooltip.id == 2) this.showTooltip = false;
      else {
        if (!this.tutorialTooltip.isLast) {
          this.timeoutId1 && clearTimeout(this.timeoutId1);
          this.timeoutId1 = setTimeout(() => {
            const addDirSupBtnEl = this.stepDirectSupplierComponent?.btnAddDirectSupplier.nativeElement;

            this.tutorialTooltip.x = (addDirSupBtnEl.offsetLeft - 302).toString() + 'px';
            this.tutorialTooltip.y = (addDirSupBtnEl.offsetTop + 65).toString() + 'px';
            this.tutorialTooltip.text = `supply-chain-step.A direct supplier is the company that provided you goods and with whom you had a direct commercial relationship. You might enter several suppliers by clicking on the button 'Add direct supplier'`;
            this.tutorialTooltip.isLast = true;
            this.tutorialTooltip.buttonText = 'supply-chain-step.Ok, got it';
            this.showTooltip = true;
          }, 0);
        } else this.showTooltip = false;
      }
    }
  }

  getDirectSuppliers() {
    const uuid = this.is_batch ? this.stepBatch.uuid : this.step.uuid;
    this._facade.getDirectSuppliers$(uuid).subscribe({
      next: this._getDirectSuppliersSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  getStepBatchDirectSuppliers() {
    this._facade.getStepDirectSuppliers$(this.stepBatch.uuid).subscribe({
      next: this._getStepBatchDirectSuppliersSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  getSupplierSites() {
    this._facade.getSupplierSites$().subscribe({
      next: this._getSupplierSitesSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  // update supplier site
  updateSite(): void {
    this.isSaving = true;
    const stepObj: Partial<ISuppChainStep> = {
      manufactories: (this.selected_site.value as ISuppManufactory).uuid,
    };

    this._facade.updateSupplierStep$(this.step.uuid, stepObj).subscribe({
      next: this._updateSiteSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  // open this dialog alert when no direct suppliers present or no reason specified about it
  openDirectSuppliersAlert() {
    const dialogRef = this.dialog.open(UpdateStepAlertComponent, {
      width: '1160px',
      height: '660px',
      panelClass: 'top-padding-0',
      data: this.step,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result === true) {
          this.screen = DIRECT_SUPPLIER;
        } else {
          const stepObj: Partial<ISuppChainStep> = {
            reason_no_direct_supplier: result,
            supplier_status: SupplierStatus.updated,
            manufactories: (this.step.manufactories as IManufactory)?.uuid ?? '',
          };

          this._facade.updateSupplierStep$(this.step.uuid, stepObj).subscribe({
            next: () => this._completedStepSuccess(),
            error: this._error.bind(this),
          });
        }
      }
    });
  }

  // open alert if leaving page without marking step as 'Updated' if changes made and saved
  openExitPageAlert() {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        btnText: this.translate.instant('supply-chain-step.Yes, Confirm'),
        infoText: this.translate.instant('supply-chain-step.You are about to exit the screen.'),
        type: 'info-actions',
        confirmationText: this.translate.instant(
          'supply-chain-step.Do you confirm that you have completed updating the step?'
        ),
        text: 'forbidden-text',
        reactivate: true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._facade.changingFlagSignal.set(false);
        this.warningAnyField = true;
        this.is_batch ? this.batchMainInfoComponent.childMethodMarkAsComplete() : this.onMarkStepAsCompleted();
        // this._router.navigate(['chain-steps-info']);
      }
    });
  }

  // on 'Mark step as completed' button click if is SuppChainStep
  onMarkStepAsCompleted() {
    this._facade.changingFlagSignal.set(false);
    if (!this.dataSource.data.length && !this.step.reason_no_direct_supplier) {
      this.openDirectSuppliersAlert();
    } else {
      this.markStepAsCompleted();
    }
  }

  // on 'Mark batch step as completed' button click if is BatchChainStep
  onMarkBatchStepAsCompleted(): void {
    this._checkWarningMandatoryFields();
    if (!this.warningAnyField) {
      this.batchMainInfoComponent.childMethodMarkAsComplete();
    }
  }

  // Method for setting step status to 'Updated'
  markStepAsCompleted() {
    const stepObj: Partial<ISuppChainStep> = {
      supplier_status: SupplierStatus.updated,
      manufactories: (this.step.manufactories as IManufactory)?.uuid ?? '',
    };

    this._facade.updateSupplierStep$(this.step.uuid, stepObj).subscribe({
      next: () => this._completedStepSuccess(),
      error: this._error.bind(this),
    });
  }

  refreshDataSource() {
    this.getDirectSuppliers();
    this._accountsService.setIsSaved(true);
  }

  setDisableDirectSupp(is_disable: boolean): void {
    this.inactiveDirectSupp = is_disable;
  }

  getBatchStep(stepBatch: IBatchChainStep): void {
    this.stepBatchUpdated = stepBatch;
    const warningDirect = this.directSuppliers?.some(supplier => supplier.is_active === null);
    if (this.stepBatchUpdated.step_confirmed_by_supplier && (!this.stepBatchUpdated.manufactory || warningDirect)) {
      this._accountsService.setIsSaved(false);
    }
  }

  ///////////////////////////////////// PRIVATE METHODS //////////////////////////////////////////

  private _getSupplierStepSuccess(data: ISuppChainStep): void {
    this.step = data;
    this.getDirectSuppliers();
    this.getSupplierSites();

    if (this.firstLogin) {
      this.timeoutId2 && clearTimeout(this.timeoutId2);
      this.timeoutId2 = setTimeout(() => {
        this.initTutorialTooltip();
      }, 0);
    }
  }

  private _getSupplierStepBatchSuccess(data: IBatchChainStep): void {
    this.stepBatch = data;
    this.getSupplierSites();
    this.getStepBatchDirectSuppliers();
  }

  private _getSupplierSitesSuccess(data: ISuppManufactoryResponse) {
    this.supplier_sites = data.results;
    if (this.selected_site.value) {
      const siteObj = this.supplier_sites.find(x => x.uuid === (this.selected_site.value as IManufactory).uuid);
      this.selected_site.setValue(siteObj, { emitEvent: false });
      return;
    }
    if (this.step?.manufactories) {
      const siteObj = this.supplier_sites.find(x => x.uuid === (this.step.manufactories as IManufactory).uuid);
      this.selected_site.setValue(siteObj, { emitEvent: false });
    }
  }

  private _getDirectSuppliersSuccess(data: ISuppStepDirectSuppResponse) {
    this.dataSource.data = data.direct_suppliers;
  }

  private _getStepBatchDirectSuppliersSuccess(data: ISuppBatchDirectSuppResponse) {
    this.dataSourceStepBatch.data = data.direct_suppliers;
    this.directSuppliers = data.direct_suppliers;
    const hasInactiveSuppliers = this.directSuppliers?.some(supplier => supplier.is_active === null);
    const isChangedFlag = this._facade.changingFlagSignal();
    if (!hasInactiveSuppliers && isChangedFlag) {
      this._accountsService.setIsSaved(true);
    }
  }

  private _updateSiteSuccess(step: ISuppChainStep): void {
    this.step = step;
    this.isSaving = false;
    this._accountsService.setIsSaved(true);
  }

  private _completedStepSuccess(): void {
    this._snackbarService.openTypeSnackbar('The step is updated.', NotificationType.success);

    this.warningAnyField = false;
    this._accountsService.setIsSaved(false);
    if (this._ulrService.getRedirectUrl() === '/login') {
      this._loginFacade.logout$().subscribe({
        next: () => this._router.navigate(['/login']),
        error: this._error.bind(this),
      });
    } else {
      this._router.navigate(['chain-steps-info']);
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  mouseEvHandler(status: boolean) {
    this.showInactiveTooltip = status;
  }

  private _setWarningFlags(): void {
    const warningDirect = this.directSuppliers.some(supplier => supplier.is_active === null);
    const warningSite = !!this.stepBatch?.manufactory;
    //const warningSite = false;
    const warningConfirmation = this.stepBatchUpdated?.step_confirmed_by_supplier === null;
    //const warningConfirmation = true;
    this.warningConfirmation = warningConfirmation;
    const isConfirmed = this.isConfirmedOrNotEntered();
    // const isWarning = !warningSite && !warningConfirmation;
    this.warningSite = isConfirmed ? !warningSite && !warningConfirmation : false;
    this._facade.warningDirectSuppliersSignal.set(
      isConfirmed ? warningDirect && !warningConfirmation && warningSite : false
    );
    this.warningAnyField = this.warningSite || warningConfirmation || (isConfirmed ? warningDirect : false);
  }

  isConfirmedOrNotEntered(): boolean {
    return (
      this.stepBatchUpdated?.step_confirmed_by_supplier === true ||
      this.stepBatchUpdated?.step_confirmed_by_supplier === null
    );
  }

  private _checkWarningMandatoryFields(): void {
    this._setWarningFlags();
    this._setWarningScreen();
  }

  private _setWarningScreen(): void {
    if (this._facade.warningDirectSuppliersSignal() && this.stepBatch.step_confirmed_by_supplier === true) {
      this.screen = DIRECT_SUPPLIER;
    } else if (this.warningConfirmation || this.warningSite) {
      this.screen = MAIN;
    }
  }
}
