import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IFactory, ISupplierResponse } from '@app/core/interface/suppliers.interface';
import { IMedia, IMediaContentType, IMediaScreens } from '@app/core/interface/medias.interface';
import { IProduct, IProductResponse } from '@app/core/interface/products.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { AddDocumentsAndMediasComponent } from '../add-documents-and-medias/add-documents-and-medias.component';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { Subscription } from 'rxjs';
import { ViewUsefullDataDialogComponent } from './view-usefull-data-dialog/view-usefull-data-dialog.component';

@Component({
  selector: 'app-useful-data',
  templateUrl: './useful-data.component.html',
  styleUrls: ['./useful-data.component.scss'],
  providers: [FilesMeadiasLinksFacade],
})
export class UsefulDataComponent implements OnInit, OnChanges {
  @Input() typeUuid = '';
  @Input() product!: IProduct | IProductResponse | ISupplierResponse;
  @Output() refresh = new EventEmitter<boolean>();
  @Input() type!: IMediaContentType;
  @Input() manufactoryList!: Partial<IManufactory>[] | Partial<IFactory>[];
  sub!: Subscription;
  docLength = 3;
  documents!: Array<IMedia>;
  medias!: Array<IMedia>;
  links!: Array<IMedia>;
  listName!: string;
  disableViewImages = false;
  diableViewVideos = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setDocLength();
  }
  constructor(
    private _dialog: MatDialog,
    private _facade: FilesMeadiasLinksFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.setDocLength();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['product']) {
      this.documents = [...changes['product'].currentValue.documents].reverse().splice(0, this.docLength);
      this.medias = [...changes['product'].currentValue.medias].reverse().splice(0, this.docLength);
      this.disableViewImages = ![...changes['product'].currentValue.medias].find(
        media => media.mimetype.indexOf('image') > -1
      );
      this.diableViewVideos = ![...changes['product'].currentValue.medias].find(
        media => media.mimetype.indexOf('video') > -1
      );
      this.links = [...changes['product'].currentValue.links].reverse().splice(0, this.docLength);
    }
  }

  setDocLength(): void {
    if (window.innerWidth > 1919) {
      this.docLength = 4;
    } else {
      this.docLength = 3;
    }
    if (this.product.documents) this.documents = [...this.product.documents].reverse().splice(0, this.docLength);
    if (this.product.medias) this.medias = [...this.product.medias].reverse().splice(0, this.docLength);
    if (this.product.links) this.links = [...this.product.links].reverse().splice(0, this.docLength);
  }

  openConfirmationDialog(name: string, screen: IMediaScreens, uuid: string): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to remove ${name}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.delete(screen, uuid);
      }
    });
  }
  addFile(screen: IMediaScreens, edit: boolean, doc?: Partial<IMedia>): void {
    const dialog = this._dialog.open(AddDocumentsAndMediasComponent, {
      width: '1160px',
      height: '768px',
      data: {
        screen: screen,
        type: this.type,
        typeUuid: this.typeUuid,
        doc: doc,
        manufactories: this.manufactoryList,
        edit,
      },
      panelClass: 'padding-0',
    });
    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.refresh.emit(true);
      }
    });
  }

  delete(screen: IMediaScreens, screenUuid: string): void {
    this._facade.deleteMedia$(this.type, this.typeUuid, screen, screenUuid).subscribe({
      next: this._deleteSuccess.bind(this),
      error: this._error.bind(this),
    });
  }
  openFileList(listName: string, screen: IMediaScreens, media?: IMedia): void {
    let list: IMedia[] = [];
    if (listName === 'documents' && this.product.documents) list = this.product.documents;
    this.listName = listName;
    if (listName === 'medias')
      list = this.product.medias.filter(
        media => media.mimetype.indexOf('video') > -1 || media.mimetype.indexOf('image') > -1
      );
    if (listName === 'images') list = this.product.medias.filter(media => media.mimetype.indexOf('image') > -1);
    this.listName = listName;
    if (listName === 'videos') list = this.product.medias.filter(media => media.mimetype.indexOf('video') > -1);

    if (this.product.links?.length && listName === 'links') list = this.product.links;
    this.listName = listName;
    if (!list.length) return;
    const dialogRef = this._dialog.open(ViewUsefullDataDialogComponent, {
      width: '1160px',
      height: '768px',
      panelClass: ['overflow-hidden-dialog', 'padding-0'],
      data: {
        type: this.type,
        typeUUID: this.typeUuid,
        data: list,
        screen: screen,
        image: this.product.image ?? this.product.logo,
        name: this.product.name,
        listName: this.listName,
        selectedFile: media,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.refresh.emit(true);
      }
    });
  }
  goToLink(url: string): void {
    window.open(url, '_blank');
  }

  ////////////////////////// PRIVATE METHODS //////////////////////////

  private _deleteSuccess(): void {
    this.refresh.emit(true);
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }
}
