import { Component, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import {
  ILanguageManagement,
  ILanguageManagementPutRequest,
  ILanguageManagementSupplier,
} from '@app/core/interface/language-management.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { LanguageManagementTableComponent } from '@app/shared/components/language-management-table/language-management-table.component';

@Component({
  selector: 'app-supplier-translation-dialog',
  templateUrl: './supplier-translation-dialog.component.html',
  styleUrl: './supplier-translation-dialog.component.scss',
  providers: [LanguageManagementFacade],
})
export class SupplierTranslationDialogComponent implements OnInit {
  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _snackbarService = inject(SnackbarService);
  readonly _dialogRef = inject(MatDialogRef<SupplierTranslationDialogComponent>);
  @ViewChild('childComponent') childComponent!: LanguageManagementTableComponent;
  public supplierDataForTranslate: ILanguageManagement[] = [];
  supplier!: ILanguageManagementSupplier;
  public modelName: string = '';
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: {
      supplier: ILanguageManagementSupplier;
      supplierDataForTranslate: ILanguageManagement[];
      modelName: string;
    }
  ) {}

  ngOnInit(): void {
    this.modelName = this._data.modelName;
    this.supplier = this._data.supplier;
    this.supplierDataForTranslate = this._data.supplierDataForTranslate;
  }
  handleLanguageManagementArrayChange(arrayForPut: ILanguageManagementPutRequest[]) {
    this._languageManagementFacade.putSupplierForTranslation$(this.supplier.uuid, arrayForPut).subscribe({
      next: () => {
        this._snackbarService.openTypeSnackbar('Translations saved successfully ', NotificationType.success);
      },
      error: error => {
        this._snackbarService.openTypeSnackbar(error.error.detail[0], NotificationType.error);
      },
    });
  }

  onCancel(): void {
    this._dialogRef.close();
  }

  onSaveTranslations(): void {
    this.childComponent.sendToParent();
    this._dialogRef.close();
  }
}
