import { ICluster } from '@app/core/interface/cluster.interface';
import { ProductChainGroup } from '@module/product-chain/product-chain.group';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ClusterFacade } from '@app/core/facade/cluster.facade';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IProductChainResult } from '@app/core/interface/productchain.interface';
import { infoDialogHeight, infoDialogWidth, NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';

@Component({
  selector: 'app-create-cluster',
  templateUrl: './create-cluster.component.html',
  styleUrls: ['./create-cluster.component.scss'],
  providers: [ProductChainGroup, ClusterFacade],
})
export class CreateClusterComponent implements OnInit {
  form: UntypedFormGroup;
  constructor(
    private _group: ProductChainGroup,
    private _facade: ClusterFacade,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public data: { product_chain: IProductChainResult; selectedSteps: string[]; cluster?: ICluster },
    public dialogRef: MatDialogRef<CreateClusterComponent>,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog
  ) {
    this.form = this._group.createCluster;
    this.form.patchValue({
      chain: this.data.product_chain.uuid,
      name: this.data.cluster?.name,
      color: this.data.cluster?.color ? this.data.cluster?.color : '#000000',
    });
    this.data.product_chain.steps.sort((a, b) => a.name.localeCompare(b.name));
  }

  ngOnInit() {
    if (this.data.cluster) {
      this._facade.getCluster$(this.data.cluster?.uuid).subscribe({
        next: this._getClusterSuccess.bind(this),
        error: this._createClusterError.bind(this),
      });
    }
    this.cdRef.detectChanges();
  }

  createCluster(): void {
    if (this.form.valid) {
      this._facade.createCluster$(this.form.value).subscribe({
        next: this._createClusterSuccess.bind(this),
        error: this._createClusterError.bind(this),
      });
    }
  }

  updateCluster(): void {
    if (this.form.valid && this.data.cluster?.uuid) {
      this._facade.updateCluster$(this.data.cluster?.uuid, this.form.value).subscribe({
        next: this._createClusterSuccess.bind(this),
        error: this._createClusterError.bind(this),
      });
    }
  }

  deleteCluster(uuid: string): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          "Please note that this action will just remove the group, and the steps in the group won't be deleted.",
        confirmationText: 'Are you sure you want to continue?',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._facade.deleteCluster$(uuid).subscribe({
          next: () => this.dialogRef.close(true),
          error: this._error.bind(this),
        });
      }
    });
  }

  updateStepList(stepUuid: string): void {
    const findStep = this.data.selectedSteps.find(step => step === stepUuid);
    if (findStep) {
      this.data.selectedSteps = this.data.selectedSteps.filter(step => step !== findStep);
    } else {
      this.data.selectedSteps.push(stepUuid);
    }

    this.form.patchValue({
      steps: this.data.selectedSteps,
    });
  }

  private _createClusterSuccess(): void {
    this.dialogRef.close(true);
  }

  private _getClusterSuccess(data: ICluster): void {
    this.data.selectedSteps = data.steps.map(step => step.uuid);
    this.form.patchValue({
      steps: this.data.selectedSteps,
    });
  }

  private _createClusterError(error: ICluster): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  colorPickerChange(color: string): void {
    this.form.get('color')?.setValue(color);
    this.form.get('color')?.markAsDirty();
  }
}
