<div class="language-content">
  <div class="filter">
    <p class="p-strong">{{ 'Manage the language translations for your publications' | translate }}</p>
    <div class="input-holder">
      <mat-form-field
        class="dropdown-primary mat-form-field-10-px-brd-rd-dd"
        appearance="outline"
        [ngClass]="{ 'blue-background': selectedSupplyChain.uuid !== '' }"
      >
        <input
          id="supply-chain"
          type="text"
          [(ngModel)]="selectedSupplyChain"
          (ngModelChange)="onSupplyChainChange($event)"
          placeholder="{{ opened ? ('Choose Supply Chain' | translate) : ('Search for supply chain' | translate) }}"
          matInput
          [matAutocomplete]="auto"
          #inputFilter
          #trigger="matAutocompleteTrigger"
          matAutocompletePosition="below"
        />
        <mat-autocomplete
          [displayWith]="displayFn"
          class="autocomplete-primary cert-list-autocomplete"
          #auto="matAutocomplete"
        >
          <mat-option *ngFor="let supply_chain of supplyChains" [value]="supply_chain">
            <div class="option-holder">
              <p class="chain-name m-0">
                <strong>{{ supply_chain?.name }}</strong>
              </p>
            </div>
          </mat-option>
        </mat-autocomplete>

        @if (selectedSupplyChain.uuid === '') {
          <app-custom-icon
            class="cursor-pointer"
            (click)="onOpenOrClosePanel($event, trigger)"
            matSuffix
            [icon_category]="'arrows'"
            [icon_name]="opened ? 'arrow-up-2' : 'arrow-down-2'"
          ></app-custom-icon>
        } @else {
          <app-custom-icon
            class="cursor-pointer"
            matSuffix
            [icon_category]="'essential'"
            [icon_name]="'close-circle'"
            (click)="onClearSelection(trigger)"
          >
          </app-custom-icon>
        }
      </mat-form-field>
      <div class="text-icon d-flex">
        <p class="m-0">{{ 'Filter by chain' | translate }}</p>
        <app-custom-icon
          [icon_category]="'essential'"
          [icon_name]="'info-circle'"
          class="cursor-pointer info-circle"
          matTooltip="{{
            'When you select one of the supply chains we’ll check it’s translation progress, and if all of the fields are filled, you will be able to publish the chain with all of the additional languages.'
              | translate
          }}"
          matTooltipClass="custom-tooltip-center"
          matTooltipPosition="above"
        ></app-custom-icon>
      </div>
    </div>
  </div>

  <div class="tabs-holder d-flex justify-content-between flex-column">
    <mat-tab-group #tabs>
      <mat-tab>
        <ng-template mat-tab-label class="ps-0">
          {{ 'Brand' | translate }}
        </ng-template>
        <div class="content-section">
          <ng-template matTabContent>
            <app-brand-translations></app-brand-translations>
          </ng-template>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'Products' | translate }}
        </ng-template>
        <div class="content-section">
          <ng-template matTabContent>
            <app-product-translations></app-product-translations>
          </ng-template>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'Suppliers' | translate }}
        </ng-template>
        <div class="content-section">
          <ng-template matTabContent>
            <app-supplier-translations></app-supplier-translations>
          </ng-template>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'Supply chains' | translate }}
        </ng-template>
        <div class="content-section">
          <ng-template matTabContent>
            <app-supply-chain-translations></app-supply-chain-translations>
          </ng-template>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'Certificates' | translate }}
        </ng-template>
        <div class="content-section">
          <ng-template matTabContent>
            <app-certificate-translations></app-certificate-translations>
          </ng-template>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
