import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { IBrandRegularionsResponse } from '@app/core/interface/regulations.interface';
import { IProduct } from '@app/core/interface/products.interface';
import { NotificationType } from '@app/core/constants';
import { RegulationsFacade } from '@app/core/facade/regulations.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-product-regulations',
  templateUrl: './product-regulations.component.html',
  styleUrls: ['./product-regulations.component.scss'],
})
export class ProductRegulationsComponent implements OnInit, OnChanges {
  @Input() product?: IProduct;
  @Output() refresh = new EventEmitter<boolean>();
  brand_regulation = '';
  productRegulations = [{ name: 'AGEC Law regulation', steps: [], value: 'agec', uuid: '' }];
  selectedIndex = 0;
  steps = [{ completed: true }, { completed: true }, { completed: true }, { completed: false }, { completed: false }];
  constructor(
    private _facade: RegulationsFacade,
    private _snackbarService: SnackbarService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['product']?.currentValue)
      this.steps = this.steps.map((step, index: number) => {
        step.completed = index + 1 <= changes['product'].currentValue.regulations[0]?.datas[0]?.steps_completed;
        return step;
      });
  }

  ngOnInit(): void {
    this._facade.getBrandRegulations$().subscribe({
      next: this._setBrandRegulation.bind(this),
      error: this._error.bind(this),
    });
  }

  _setBrandRegulation(response: IBrandRegularionsResponse) {
    if (response.results.length) this.brand_regulation = response.results[0].uuid;
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }
}
