import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { ISubproject } from '@app/core/interface/projects.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectComponent } from '@app/module/projects/project/project.component';
import { ProjectDetailsDialogComponent } from '@app/module/projects/project-details-dialog/project-details-dialog.component';
import { ProjectsFacade } from '@app/core/facade/projects.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-project-subprojects',
  templateUrl: './project-subprojects.component.html',
  styleUrls: ['./project-subprojects.component.scss'],
})
export class ProjectSubprojectsComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @Input() dataSource = new MatTableDataSource<ISubproject>();
  @Input() projectId!: string;
  @Input() allSubprojects!: ISubproject[];
  @Output() onUpdateSubproject = new EventEmitter<void>();
  displayedColumns = ['name', 'status', 'product_traced_num', 'actions'];
  subprojects: Array<ISubproject> = [];
  show_deactivated = false;
  has_products!: boolean;

  constructor(
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _projectFacade: ProjectsFacade,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setTableData();
  }

  ngAfterViewInit(): void {
    this.checkPagination();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allSubprojects']) {
      this.setTableData();
      this.checkPagination();
    }
  }

  checkPagination(): void {
    this._changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.paginator) {
      this.paginator.pageSize = 6;
    }
  }

  openCreateDialog(subproject: ISubproject | null, is_editing: boolean) {
    const dialogRef = this._dialog.open(ProjectComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        is_editing: is_editing,
        projectId: this.projectId,
        subprojectId: subproject ? subproject.uuid : '',
        is_project: false,
        project_view: true,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: ISubproject) => {
      if (result) {
        this.onUpdateSubproject.emit();
      }
    });
  }
  openViewDialog(project: ISubproject) {
    this._dialog.open(ProjectDetailsDialogComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'padding-0',
      data: {
        project: project,
        is_project: false,
        project_view: true,
      },
      disableClose: true,
    });
  }

  setTableData(): void {
    this.showDeactivatedSubprojects();
    if (this.paginator) {
      this.paginator.length = this.dataSource.data.length;
      this.paginator.pageSize = 6;
    }
  }

  showDeactivatedSubprojects(event?: MatCheckboxChange): void {
    this.show_deactivated = event?.checked ?? this.show_deactivated;

    if (this.show_deactivated) {
      // Show inactive sub-projects first, then active sub-projects
      const inactiveSubprojects = this.allSubprojects.filter(subproject => !subproject.is_active);
      const activeSubprojects = this.allSubprojects.filter(subproject => subproject.is_active);
      this.dataSource.data = [...inactiveSubprojects, ...activeSubprojects];
    } else {
      // Show only active sub-projects
      this.dataSource.data = this.allSubprojects.filter(subproject => subproject.is_active);
    }

    this.checkPagination();
  }

  reactivateSubproject(subproject: ISubproject) {
    this._projectFacade.activateDeactivateSubproject$(subproject.uuid, { is_active: true }).subscribe({
      next: () => {
        this._successActivated(subproject);
      },

      error: this._error.bind(this),
    });
  }

  private _successActivated(subproject: ISubproject) {
    this._snackbarService.openTypeSnackbar(
      `Sub-project ${subproject.name} reactivated successfully`,
      NotificationType.success
    );
    this.onUpdateSubproject.emit();
  }
  private _success(): void {
    this.onUpdateSubproject.emit();
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  deactivateSubproject(subproject: ISubproject) {
    this.has_products = subproject.subproject_products?.length ? true : false;
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: this.has_products
          ? 'The selected sub-project has active products under it.'
          : 'Note that once you deactivate this sub-project, it can be reactivated  at anytime.',

        confirmationText: this.has_products
          ? 'The sub-project cannot be deactivated!'
          : 'Are you sure you want to deactivate this sub-project?',
        btnText: this.has_products ? 'Ok, Got It' : 'Deactivate Sub-Project',
        type: this.has_products ? 'forbidden' : 'warning',
        text: this.has_products ? 'forbidden-text' : 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._projectFacade.activateDeactivateSubproject$(subproject.uuid, { is_active: false }).subscribe({
          next: () => {
            this._success();
          },
          error: this._error.bind(this),
        });
      }
    });
  }
}
