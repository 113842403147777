import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { IProductChainStep, ITypeStepErrorResponse } from '@app/core/interface/steps.interface';
import { IFactory } from '@app/core/interface/suppliers.interface';
import { ProductChainGroup } from '../product-chain.group';
import { StepsFacade } from '@app/core/facade/steps.facade';
import { StepsService } from '@app/core/service/steps.service';
import { CountryList } from '@app/core/utils/country-list';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-supplier-site-mapping-dialog',
  templateUrl: './supplier-site-mapping-dialog.component.html',
  styleUrls: ['./supplier-site-mapping-dialog.component.scss'],
  providers: [ProductChainGroup, StepsFacade],
})
export class SupplierSiteMappingDialogComponent implements OnInit {
  sites?: IFactory[];
  form: UntypedFormGroup;
  mainSiteAddress?: string;
  getCountryName = CountryList.getCountryName;

  selectedSite?: IFactory;

  constructor(
    public _dialogRef: MatDialogRef<SupplierSiteMappingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public step: IProductChainStep,
    private _supplierFacade: SuppliersFacade,
    private _stepFacade: StepsFacade,
    private _group: ProductChainGroup,
    private _snackbarService: SnackbarService,
    private _stepService: StepsService
  ) {
    this.form = this._group.selectSiteForm;
    if (step.manufactories) {
      this.mainSiteAddress = this._concatenateFields(
        step.manufactories.address,
        step.manufactories.city,
        step.manufactories.region,
        step.manufactories.country
      );
    }
  }

  ngOnInit(): void {
    if (this.step.sip_supplier_identified) {
      this._supplierFacade.getSupplierManufactories$(this.step.sip_supplier_identified?.uuid).subscribe({
        next: this._onSuccess.bind(this),
        error: this._onError.bind(this),
      });
    }
  }

  private _onSuccess(data: IFactory[]) {
    this.sites = data;
    if (data) {
      this.form.patchValue({
        manufactory: '',
      });
    }
  }

  private _onError(error: ITypeStepErrorResponse) {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }

  onValidateSupplier() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const submitForm = { ...this.form.value };

    this._stepFacade.matchingSupplier(this.step.uuid, submitForm).subscribe({
      next: this._onSuccessMatch.bind(this),
      error: this._onError.bind(this),
    });
  }

  onValueChange() {
    this.selectedSite = this.sites?.find(site => site.uuid == this.form.value['manufactory']);
  }

  onDontMatch() {
    this.form.patchValue({
      manufactory: '',
    });

    this._stepFacade.matchingSupplier(this.step.uuid, this.form.value).subscribe({
      next: this._onSuccesNotMatch.bind(this),
      error: this._onError.bind(this),
    });
  }

  _onSuccesNotMatch() {
    this._stepService.matchingSupplierVerification?.next(false);
    this._dialogRef.close();
  }

  _onSuccessMatch() {
    this._stepService.matchingSupplierVerification?.next(true);
    this._dialogRef.close();
  }

  _concatenateFields(field1?: string, field2?: string, field3?: string, field4?: string) {
    // Create an array to store non-null and non-empty fields
    const nonEmptyFields = [];

    // Check each field and add it to the array if it's not null or empty
    if (field1 !== null && field1 !== '') {
      nonEmptyFields.push(field1);
    }

    if (field2 !== null && field2 !== '') {
      nonEmptyFields.push(field2);
    }

    if (field3 !== null && field3 !== '') {
      nonEmptyFields.push(field3);
    }

    if (field4 !== null && field4 !== '') {
      nonEmptyFields.push(this.getCountryName(field4));
    }

    // Use the join method to concatenate the non-empty fields with commas
    const resultString = nonEmptyFields.join(', ');

    return resultString;
  }
}
