import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BrandManagementGroup } from '../brand-management.group';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { IBrandListResult, IBrandUserResult } from '@app/core/interface/brands.interface';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-invite-brand-user',
  templateUrl: './invite-brand-user.component.html',
  styleUrls: ['./invite-brand-user.component.scss'],
  providers: [BrandManagementGroup],
})
export class InviteBrandUserComponent {
  form: UntypedFormGroup;
  isInvited = false;
  invitedUser!: IBrandUserResult;

  constructor(
    private _group: BrandManagementGroup,
    @Inject(MAT_DIALOG_DATA) public selectedBrand: IBrandListResult,
    public dialogRef: MatDialogRef<InviteBrandUserComponent>,
    private _brandsFacade: BrandsFacade,
    private _snackbarService: SnackbarService
  ) {
    this.form = this._group.inviteBrandUser;
    dialogRef.disableClose = true;
    this.isInvited = !selectedBrand;
  }

  inviteBrandUser(): void {
    if (this.form.valid) {
      this._brandsFacade.inviteBrandUser$(this.form.value).subscribe({
        next: this._refetchUsers.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _refetchUsers(data: IBrandUserResult): void {
    this.isInvited = true;
    this.invitedUser = data;
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }
}
