import { Component, Inject, inject, OnInit, signal } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { UntypedFormGroup, FormBuilder } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {
  IPublicationProductSetUp,
  IPublicationProduct,
  IMediaPayload,
  ICustomFieldPeyload,
  ICustomFieldPublicationSetup,
} from '@app/core/interface/publication-management.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PublicationManagementFacade } from '@app/core/facade/publication-management.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { NotificationType } from '@app/core/constants';
import { CommonModule } from '@angular/common';
import { IMedia } from '@app/core/interface/medias.interface';
import { ProductSetupDialogGroup } from './product-setup-dialog.group';

@Component({
  selector: 'app-product-setup-dialog',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './product-setup-dialog.component.html',
  styleUrl: './product-setup-dialog.component.scss',
  providers: [PublicationManagementFacade, ProductSetupDialogGroup],
})
export class ProductSetupDialogComponent implements OnInit {
  readonly _publicationManagementFacade = inject(PublicationManagementFacade);
  readonly _snackbarService = inject(SnackbarService);
  readonly _group = inject(ProductSetupDialogGroup);
  readonly dialogRef = inject(MatDialogRef<ProductSetupDialogComponent>);

  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';
  form!: UntypedFormGroup;
  product!: IPublicationProductSetUp;
  productUuid!: string;
  images!: IMedia[];
  videos!: IMedia[];
  is_published: boolean = false;

  linksPayload: IMediaPayload[] = [];
  mediaPayload: IMediaPayload[] = [];
  documentsPayload: IMediaPayload[] = [];
  customFieldPayload: ICustomFieldPeyload[] = [];

  documents = signal<IMedia[]>([]);
  links = signal<IMedia[]>([]);
  medias = signal<IMedia[]>([]);
  customFields = signal<ICustomFieldPublicationSetup[]>([]);
  isFormLoaded = signal(false);

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { product: IPublicationProduct }
  ) {
    this.productUuid = data.product.uuid;
    this.is_published = data.product.is_published;
    this.form = this._group.productPublicationSetupForm;
  }

  ngOnInit(): void {
    this.getProductSetup$(this.productUuid);
  }

  getProductSetup$(uuid: string): void {
    this._publicationManagementFacade.getProductSetup$(uuid).subscribe({
      next: this._getProductSetupSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  private _getProductSetupSuccess(data: IPublicationProductSetUp): void {
    if (data) {
      this.product = data;
      this.images = data.medias?.filter(el => el.mimetype?.includes('image'));
      this.videos = data.medias?.filter(el => el.mimetype?.includes('video'));
      this.customFields.set(this.product.custom_fields);
      this.documents.set(this.product.documents);
      this.links.set(this.product.links);
      this.medias.set(this.product.medias);

      this._patchFormFromResponseValues();
      this.isFormLoaded.set(true);
    }
  }

  onSubmit(): void {
    const payload = this.preparePayload();

    if (this.product && payload) {
      this._publicationManagementFacade.updatePublicationProduct$(this.product.uuid, payload).subscribe({
        next: () => this._successUpdatePublicationProduct(),
        error: () => this._errorUpdatePublicationProduct(),
      });
    }
  }

  private preparePayload() {
    const payload = this.form.getRawValue();

    // Transform data into backend's expected format
    this.customFieldPayload = this.customFields().map(field => ({
      uuid: field.uuid,
      is_field_private: field.is_field_private ? 'True' : 'False',
    }));
    this.documentsPayload = this.documents().map(doc => ({
      uuid: doc.uuid,
      is_entity_private: doc.is_entity_private ? 'True' : 'False',
    }));
    this.linksPayload = this.links().map(link => ({
      uuid: link.uuid,
      is_entity_private: link.is_entity_private ? 'True' : 'False',
    }));
    this.mediaPayload = this.medias().map(media => ({
      uuid: media.uuid,
      is_entity_private: media.is_entity_private ? 'True' : 'False',
    }));

    // Assign transformed data to payload
    payload.product_documents = this.documentsPayload;
    payload.product_links = this.linksPayload;
    payload.product_medias = this.mediaPayload;
    payload.product_custom_fields = this.customFieldPayload;

    return payload;
  }

  private _successUpdatePublicationProduct(): void {
    this._snackbarService.openTypeSnackbar('Publication setup updated successfully', NotificationType.success);
    this.dialogRef.close(true); // Close dialog with success result
  }

  private _errorUpdatePublicationProduct(): void {
    this._snackbarService.openTypeSnackbar(
      'Error, please contact our support for further details',
      NotificationType.error
    );
    this.dialogRef.close(false); // Close dialog with error result
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onToggleChange(field: 'is_reference_private' | 'is_description_private', event: MatSlideToggleChange): void {
    this.form.get(field)?.setValue(!event.checked);
  }

  private _patchFormFromResponseValues(): void {
    this.form.patchValue({
      is_description_private: this.product?.is_description_private,
      is_reference_private: this.product?.is_reference_private,
    });
  }

  toggleCustomField(uuid: string, custom_field: Partial<ICustomFieldPublicationSetup>, checked: boolean): void {
    const index = this.documents().findIndex(document => document.uuid === uuid);
    custom_field.is_field_private = !checked;
    if (index !== -1) {
      const newCustomField = [...this.customFields()];
      newCustomField[index] = { ...newCustomField[index], ...document };
      this.customFields.set(newCustomField);
    }
  }

  toggleDocuments(uuid: string, document: Partial<IMedia>, checked: boolean): void {
    const index = this.documents().findIndex(document => document.uuid === uuid);
    document.is_entity_private = !checked;
    if (index !== -1) {
      const newDocuments = [...this.documents()];
      newDocuments[index] = { ...newDocuments[index], ...document };
      this.documents.set(newDocuments);
    }
  }

  toggleMedias(uuid: string, media: Partial<IMedia>, checked: boolean): void {
    const index = this.medias().findIndex(media => media.uuid === uuid);
    media.is_entity_private = !checked;
    if (index !== -1) {
      const newMedias = [...this.medias()];
      newMedias[index] = { ...newMedias[index], ...media };
      this.medias.set(newMedias);
    }
  }

  toggleLink(uuid: string, link: Partial<IMedia>, checked: boolean): void {
    const index = this.links().findIndex(link => link.uuid === uuid);
    link.is_entity_private = !checked;
    if (index !== -1) {
      const newLinks = [...this.links()];
      newLinks[index] = { ...newLinks[index], ...link };
      this.links.set(newLinks);
    }
  }
}
