<div class="content">
  <div class="tab-holder d-flex justify-content-between flex-column">
    <mat-tab-group #tabs class="publication-tab">
      <mat-tab>
        <ng-template mat-tab-label class="ps-0">
          {{ 'Publications setup' | translate }}
        </ng-template>
        <div class="horizontal-line"></div>
        <div class="content-section">
          <ng-template matTabContent>
            <app-publication-setup></app-publication-setup>
          </ng-template>
        </div>
      </mat-tab>
      <mat-tab [disabled]="!isAdditionalLanguages">
        <ng-template mat-tab-label>
          {{ 'Publications language management' | translate }}
        </ng-template>
        <div class="horizontal-line"></div>
        <div class="content-section">
          <ng-template matTabContent>
            <app-language-management></app-language-management>
          </ng-template>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'Publication pending validations' | translate }}
        </ng-template>
        <div class="horizontal-line"></div>
        <div class="content-section">
          <ng-template matTabContent>
            <app-pending-validations></app-pending-validations>
          </ng-template>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
