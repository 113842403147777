import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { IPageTranslationResponse, IPageTranslationResultsResponse } from '@app/core/interface/translation.interface';

import { ILanguagesResponse } from '@app/core/interface/languages.interface';
import { LanguagesFacade } from '@app/core/facade/languages.facade';
import { MatSelectChange } from '@angular/material/select';
import { NotificationType } from '@app/core/constants';
import { Observable } from 'rxjs';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationFacade } from '@app/core/facade/translation.facade';
import { TranslationGroup } from './translation.group';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss'],
  providers: [TranslationFacade, TranslationGroup, LanguagesFacade],
})
export class TranslationComponent implements OnInit {
  default_lang!: ILanguagesResponse | null;
  translateForm!: UntypedFormGroup;
  pageTranslationList!: Observable<IPageTranslationResponse | null>;
  languageList$!: Observable<ILanguagesResponse | null>;
  languages!: ILanguagesResponse;
  selectedPage!: string;
  selectedLanguage = 'en';
  form!: FormGroup;
  translated_data: { [key: string]: string } = {};
  fields: { [key: string]: string } = {};

  constructor(
    private _group: TranslationGroup,
    private _translationFacade: TranslationFacade,
    private _snackbarService: SnackbarService,
    private _languagesFacade: LanguagesFacade,
    private _translate: TranslateService,
    private fb: FormBuilder
  ) {
    this.translateForm = this._group.translateForm;
  }

  ngOnInit(): void {
    this.pageTranslationList = this._translationFacade.getPageTranslation$();
    this._translationFacade.getPageTranslation$().subscribe((data: IPageTranslationResponse) => {
      this.translateForm.get('select_page')?.setValue(data.results[0].uuid);
    });
    this.languageList$ = this._languagesFacade.getLanguages$();
    this._languagesFacade.getLanguages$().subscribe(data => {
      this.languages = data;
      this.translateForm.get('select_language')?.setValue(this.languages.results[0].name);
    });
  }

  getTranslation(): void {
    this._translationFacade
      .getTranslation$(
        this.translateForm.get('select_language')?.value,
        this.translateForm.get('select_page')?.value.uuid
      )
      .subscribe({
        next: this._getEnTranslationSuccess.bind(this),
        error: this._error.bind(this),
      });
  }

  selectionChangePage(event: MatSelectChange): void {
    this.selectedPage = event.value;
    this.applyFilter();
  }

  selectionChangeLanguage(event: MatSelectChange): void {
    this.selectedLanguage = event.value;
    this.applyFilter();
  }

  patchTranslationData(data: IPageTranslationResultsResponse): void {
    const localData = Object.entries(data);
    const filtered = localData.filter(([, value]) => typeof value === 'object');
    const justStrings = Object.fromEntries(filtered);
    let justStringsFiltered;
    Object.keys(justStrings).forEach(function (k) {
      justStringsFiltered = justStrings[k];
    });

    this.translateForm.get('translation_data')?.patchValue(justStringsFiltered, { emitValue: false });
    this.createForm(justStringsFiltered);
    this.translateForm.markAsPristine();
  }

  applyFilter(): void {
    this.getTranslation();
  }

  createForm(data?: { [key: string]: string }): void {
    const formControls: { [key: string]: FormControl } = {};
    this.fields = {};
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        formControls[key] = new FormControl(data[key], Validators.required);
      }
    }
    this.form = this.fb.group(formControls);
    this.fields = this.form.value;
  }
  refreshControl(key: string): void {
    this.translateForm.controls['translation_data'].setValue({
      ...this.translateForm.controls['translation_data'].value,
      [key]: this.fields[key],
    });
    this.form.controls[key].setValue(this.fields[key]);
    this.form.controls[key].markAsPristine();
  }
  edit(key: string, val: string): void {
    this.translated_data = { [key]: val };
    this._translationFacade
      .putTranslation$({
        ...this.translateForm.value,
        translation_data: this.translated_data,
      })
      .subscribe({
        next: this._successPatchTranslation.bind(this),
        error: this._error.bind(this),
      });
  }

  objectKeys(obj?: object): string[] {
    if (!obj) return [];
    return Object.keys(obj);
  }

  private _successPatchTranslation(data: IPageTranslationResponse): void {
    const res = {};
    const currentLang = this._translate.getDefaultLang();
    data.results.forEach((obj: IPageTranslationResultsResponse) => {
      Object.assign(res, obj);
    });
    this._translate.setTranslation(this.selectedLanguage, res);
    this._translate.use(currentLang);
    this.translateForm.markAsPristine();
    this._setNewValue();
    this._snackbarService.openTypeSnackbar(`Successfully updated translation`, NotificationType.success);
  }
  private _setNewValue(): boolean {
    const key = this.objectKeys(this.translated_data)[0];
    this.fields[key] = this.translated_data[key];
    this.form.controls[key].markAsPristine();
    this.refreshControl(key);
    return true;
  }
  private _getEnTranslationSuccess(data: IPageTranslationResultsResponse): void {
    this.patchTranslationData(data);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
