import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { ISupplierContact, ISupplierEmailsResponse } from '@app/core/interface/suppliers.interface';
import { Component, Inject, ViewChild } from '@angular/core';

import { AddContactGroup } from './add-contact.group';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ILanguage } from '@app/core/interface/languages.interface';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { uniqueNameValidator } from '@app/shared/directives/unique-name-validator';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
  providers: [AddContactGroup, SuppliersFacade],
})
export class AddContactComponent {
  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;
  form: UntypedFormGroup;
  mode: 'Create' | 'Edit' = 'Create';
  opened = false;
  selectedOption = '';
  supplierLanguageOptions: ILanguage[] = [];
  uniqueIdentifierInfo!: boolean;
  identifierTypeInfo!: boolean;
  emails: string[] = [];

  constructor(
    private _group: AddContactGroup,
    private _facade: SuppliersFacade,
    private _snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA)
    public data: { supplierContact: ISupplierContact; supplierUuid: string; languages: ILanguage[] },
    public dialogRef: MatDialogRef<AddContactComponent>
  ) {
    this.form = this._group.addContact;
    if (this.data.supplierContact?.uuid) {
      this.mode = 'Edit';
      const selectedLanguageId = this.data.supplierContact.language_prefered;
      const selectedLanguage = this.data.languages.find(language => language.uuid === selectedLanguageId.uuid);

      if (selectedLanguage) {
        this.selectedOption = selectedLanguage.uuid;
        this.form.patchValue({ ...this.data.supplierContact, language_prefered: selectedLanguage });
      }
    }

    this._getSupplierEmails();
    setTimeout(() => {
      this._group.addContact.controls['email'].setValidators([
        Validators.required,
        Validators.email,
        uniqueNameValidator(this.emails, this.mode, this.data.supplierContact?.email),
      ]);
    }, 500);

    this.supplierLanguageOptions = data.languages;
  }

  addContact(): void {
    if (this.form.controls['language_prefered'].value) {
      this.form.patchValue({ language_prefered: this.form.controls['language_prefered'].value.uuid });
    }

    const changedFromValues = GetDirtyValues(this.form);

    if (this.form.valid && this.data.supplierUuid && !this.data.supplierContact?.uuid) {
      this._facade.createSupplierContact$(this.data.supplierUuid, { ...this.form.value }).subscribe({
        next: this._createSupplierContactSuccess.bind(this),
        error: this._error.bind(this),
      });
    } else if (this.form.valid && this.data.supplierContact.uuid && this.data.supplierUuid) {
      this._facade
        .updateSupplierContact$(this.data.supplierUuid, this.data.supplierContact?.uuid, { ...changedFromValues })
        .subscribe({
          next: this._createSupplierContactSuccess.bind(this),
          error: this._error.bind(this),
        });
    }
  }

  onClosedEvent() {
    this.opened = false;
  }

  isOpened() {
    this.opened = true;
  }

  openOrClosePanel(evt: Event, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
      this.opened = false;
    } else {
      trigger.openPanel();
      this.opened = true;
    }
  }

  mouseEvUniqueIdentifier(status: boolean) {
    status ? (this.uniqueIdentifierInfo = true) : (this.uniqueIdentifierInfo = false);
  }

  mouseEvIdentifierType(status: boolean) {
    status ? (this.identifierTypeInfo = true) : (this.identifierTypeInfo = false);
  }

  displayFn(subject: ILanguage | null): string {
    return subject?.name ?? '';
  }

  ///////////////////////////////Private Methods////////////////////////////////////////

  private _getSupplierEmails(): void {
    this._facade.getAllSupplierEmails$().subscribe({
      next: this._successGetSupplierEmails.bind(this),
      error: this._errorEmail.bind(this),
    });
  }

  private _successGetSupplierEmails(emails: ISupplierEmailsResponse[]): void {
    this.emails = emails.map(email => email.email);
  }

  private _createSupplierContactSuccess(): void {
    this._snackbarService.openTypeSnackbar(`Your data has been securely submitted`, NotificationType.success);
    this.dialogRef.close(true);
  }

  private _error(error: ISupplierContact): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  private _errorEmail(error: ISupplierEmailsResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }
}
